import React, { useState } from 'react';
import { Grid, Paper } from '@material-ui/core';
import { Table, TableBody, TableContainer } from '@material-ui/core';

import { sortDirection } from '../../../../utils/constants/sorting';
import useStyles from './styles';
import TableHead from './TableHead';
import Row from './Row';

const changeNullToEmptyString = value => (value === null ? '' : value);

const UsersTable = ({
  isSafetyCouncilUserType,
  userListData,
  updatingUserIds,
  handleResendInvitation,
  handleUpdateUser,
  handleDeleteUser,
}) => {
  const classes = useStyles();
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('firstName');

  const desc = (a, b, orderBy) => {
    a[orderBy] = changeNullToEmptyString(a[orderBy]);
    b[orderBy] = changeNullToEmptyString(b[orderBy]);

    if (b[orderBy].toLocaleLowerCase() < a[orderBy].toLocaleLowerCase()) {
      return -1;
    }
    if (b[orderBy].toLocaleLowerCase() > a[orderBy].toLocaleLowerCase()) {
      return 1;
    }
    return 0;
  };

  const stableSort = (array, compare) => {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = compare(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    return stabilizedThis.map(el => el[0]);
  };

  const getSorting = (order, orderBy) => {
    return order === sortDirection.Descending ? (a, b) => desc(a, b, orderBy) : (a, b) => -desc(a, b, orderBy);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === sortDirection.Ascending;
    setOrder(isAsc ? sortDirection.Descending : sortDirection.Ascending);
    setOrderBy(property);
  };

  return (
    <Grid item lg={12}>
      <TableContainer component={Paper} className={classes.tableWrapper}>
        <Table className={classes.table}>
          <TableHead
            order={order}
            orderBy={orderBy}
            onRequestSort={handleRequestSort}
            isSafetyCouncilUserType={isSafetyCouncilUserType}
          />
          <TableBody>
            {stableSort(userListData, getSorting(order, orderBy)).map(row => {
              return (
                <Row
                  key={row.id}
                  values={row}
                  handleUpdateUser={handleUpdateUser}
                  updatingUserIds={updatingUserIds}
                  handleResendInvitation={() => handleResendInvitation(row.id)}
                  handleDeleteUser={() => handleDeleteUser(row.id)}
                />
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </Grid>
  );
};

export default UsersTable;
