import React from 'react';
import { FastField } from 'formik';
import { Grid, Typography } from '@material-ui/core';

import FormInput from '../../../components/FormInput';
import FormDatePicker from '../../../components/FormDataPicker';
import useStyles from './styles';

const OfficersSubForm = ({ position, names, itemClasses }) => {
  const classes = useStyles();

  const { title, date, email } = names;

  return (
    <Grid item xs={3} className={itemClasses}>
      <Typography variant="h5" gutterBottom>
        {position}
      </Typography>
      <FastField className={classes.inputRow} textOnly label="Name" name={title} component={FormInput} />
      <FastField className={classes.inputRow} label="Date Hired" name={date} component={FormDatePicker} disableFuture />
      <FastField className={classes.inputRow} label="Email" name={email} type="email" component={FormInput} />
    </Grid>
  );
};

export default OfficersSubForm;
