import React from 'react';
import Pagination from 'material-ui-flat-pagination';

const PaginationComponent = ({ current = 1, itemsPerPage, total, handleClick }) => {
  return (
    total > itemsPerPage && (
      <Pagination limit={itemsPerPage} offset={(current - 1) * itemsPerPage} total={total} onClick={handleClick} />
    )
  );
};

export default PaginationComponent;
