import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  dialogModal: {
    minWidth: theme.spacing(60),
  },
  loadingContainer: {
    height: theme.spacing(10),
  },
  downloadLink: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    cursor: 'pointer',
  },
  contentWrapper: {
    padding: theme.spacing(1, 2),
  },
  titleWrapper: {
    padding: theme.spacing(2, 2, 1),
  },
  table: {
    backgroundColor: theme.palette.grey['100'],
    '& th': {
      color: theme.palette.common.black,
    },
    '& td, & th': {
      padding: theme.spacing(1),
      width: '85%',
    },
    '& td:last-child, & th:last-child': {
      width: '15%',
      textAlign: 'center',
    },
    '& thead': {
      backgroundColor: theme.palette.grey['500'],
    },
  },
}));

export default useStyles;
