import React from 'react';
import { Tooltip } from '@material-ui/core';

const TooltipWrapper = ({ children, title, tooltip }) => {
  return tooltip ? (
    <Tooltip title={title} arrow placement="bottom">
      {children}
    </Tooltip>
  ) : (
    children
  );
};

export default TooltipWrapper;
