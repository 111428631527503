import { makeStyles } from '@material-ui/core/styles';

import image from '../../images/loginBackground.png';

const useStyles = makeStyles({
  container: {
    backgroundImage: `url(${image})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
  },
});

export default useStyles;
