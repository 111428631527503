import { normalize } from 'normalizr';
import { all, call, put, takeLatest } from 'redux-saga/effects';

import { service as serviceSchema } from '../../../schema';
import { currentCompany, entity, notification } from '../../../actionTypes';
import dataProvider from '../../../store/dataProvider';

export function* get({ payload: { companyId, params } }) {
  try {
    const [services, companyServices] = yield all([
      call(dataProvider.getList, 'definitions/services', params),
      call(dataProvider.getList, `companies/${companyId}/services`, params),
    ]);

    const listCompanyServices = companyServices.data.services.map(item => item.id);

    // normalize data
    const listServices = normalize(services.data.data, [serviceSchema]);

    yield put({ type: entity.add, payload: listServices.entities });

    yield put({
      type: currentCompany.service.get.end,
      payload: {
        services: listServices.result,
        companyServices: listCompanyServices,
        others: companyServices.data.information.additionalServices,
      },
    });
  } catch (e) {
    yield put({ type: notification.add, payload: { error: e.message } });
  }
}

export default function* getserviceSaga() {
  yield takeLatest(currentCompany.service.get.start, get);
}
