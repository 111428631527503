import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  inputRow: {
    margin: theme.spacing(1, 0),
  },
  tableWrapper: {
    marginBottom: theme.spacing(4),
  },
  table: {
    tableLayout: 'fixed',
  },
}));

export default useStyles;
