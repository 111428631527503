import React from 'react';
import { AppBar, Grid } from '@material-ui/core';
import { Link } from 'react-router-dom';

import MainMenu from '../MainMenu';
import UserLabel from '../../components/UserLabel';
import logo from '../../images/logo.png';
import routes from '../../routes/path';
import useStyles from './styles';

const Header = ({ handleLogout, user, headerNotifications }) => {
  const classes = useStyles();
  return (
    <AppBar position="static" color="inherit" className={classes.paperContainer}>
      <Grid container>
        <Grid item lg={8}>
          <Grid container>
            <div className={classes.logoWrapper}>
              <Link to={routes.private.index} className={classes.link}>
                <img src={logo} alt="logo" className={classes.image} />
              </Link>
            </div>
            <MainMenu />
          </Grid>
        </Grid>
        <Grid item lg={4}>
          <Grid container justify="flex-end" alignItems="center" className={classes.linksWrapper}>
            <UserLabel
              handleLogout={handleLogout}
              firstName={user.firstName}
              lastName={user.lastName}
              headerNotifications={headerNotifications}
            />
          </Grid>
        </Grid>
      </Grid>
    </AppBar>
  );
};

export default Header;
