import { all, call, put, takeLatest } from 'redux-saga/effects';

import { settings, notification } from '../../../actionTypes';
import dataProvider from '../../../store/dataProvider';

export function* get({ payload: { companyId, fullData } }) {
  const path = fullData ? 'configuration' : 'definitions/configuration';
  try {
    const [settingsObject, users] = yield all([
      call(dataProvider.getOnly, path),
      fullData ? call(dataProvider.getList, `Companies/${companyId}/Users`, {}) : { data: [] },
    ]);

    const promptConfirm = JSON.parse(localStorage.getItem('promptConfirm'));

    yield put({
      type: settings.configuration.get.end,
      payload: {
        data: {
          ...settingsObject.data,
          promptConfirm: promptConfirm !== null ? promptConfirm : true,
        },
        users: users.data,
      },
    });
  } catch (e) {
    yield put({ type: settings.configuration.get.error });
    yield put({ type: notification.add, payload: { error: e.message } });
  }
}

export default function* getSettings() {
  yield takeLatest(settings.configuration.get.start, get);
}
