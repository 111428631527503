import { put, call, takeLatest } from 'redux-saga/effects';
import { normalize } from 'normalizr';

import dataProvider from '../../../store/dataProvider';
import headerNotification from '../../../actionTypes/headerNotification';
import { entity, notification } from '../../../actionTypes';
import { successMessages } from '../../../store/constants';
import { company as companySchema } from '../../../schema';

export function* createUnfollow({ payload: { companyId, sharedInfoCompanyId } }) {
  try {
    yield call(dataProvider.createOne, `companies/${companyId}/sharing/${sharedInfoCompanyId}/unfollow`);

    const dashboard = yield call(dataProvider.getOnly, `companies/${companyId}/dashboard`);
    const { company } = dashboard.data;

    // normalize company
    const normalizeData = normalize([company], [companySchema]);
    yield put({ type: entity.add, payload: normalizeData.entities });

    yield put({ type: headerNotification.createUnfollow.end });
    yield put({ type: notification.add, payload: { success: successMessages.companyUnfollow } });
  } catch (e) {
    yield put({ type: notification.add, payload: { error: e.message } });
    yield put({ type: headerNotification.createUnfollow.error });
  }
}

export default function* createUnfollowSaga() {
  yield takeLatest(headerNotification.createUnfollow.start, createUnfollow);
}
