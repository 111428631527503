import { put, call, takeLatest } from 'redux-saga/effects';

import { auth, notification } from '../../actionTypes';
import authProvider from '../../store/authProvider';

export function* logout() {
  try {
    yield call(authProvider.logout);
    yield put({ type: auth.logout.end });
    yield put({ type: auth.check.start });
  } catch (e) {
    yield put({ type: notification.add, payload: { error: e.message } });
    yield put({ type: auth.logout.end });
  }
}

export default function* logoutSaga() {
  yield takeLatest(auth.logout.start, logout);
}
