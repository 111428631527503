import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  fileForm: {
    border: `2px dashed ${theme.palette.grey['600']}`,
    borderRadius: theme.spacing(1),
    padding: theme.spacing(2),
  },
}));

export default useStyles;
