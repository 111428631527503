import React from 'react';
import { MenuItem } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import get from 'lodash.get';

const FormSelect = ({
  size = 'small',
  options,
    specialFilter,
  handleChange,
  field: { onChange, ...fieldProps },
  form: { errors, touched, setFieldValue },
  ...props
}) => {
  const { name } = fieldProps;
  const itemError = get(errors, name);
  const itemTouched = get(touched, name);

  return (
    <TextField
      {...props}
      {...fieldProps}
      onChange={e => {
        handleChange && handleChange(e, fieldProps);
        setFieldValue(name, e.target.value);
      }}
      fullWidth
      select
      variant="outlined"
      size={size}
      error={!!itemError && !!itemTouched}
      helperText={!!itemError && !!itemTouched && itemError}
    >
      {options.map((option, index) => {
        const date = new Date();
        const isDec = date.getMonth() === 11;

        let disabled = specialFilter && (isDec ? index > 0 : index > 1);

        return (
          <MenuItem
            key={option.label}
            value={option.value}
            disabled={disabled}>
            {option.label}
          </MenuItem>
        );
      })}
    </TextField>
  );
};

export default FormSelect;
