import { formatISO } from 'date-fns';

import refineNumberString from '../textFormaters/refineNumberString';
import { craftsmanCertificationsAnswers } from '../actionTypesHelpers';
import { enums } from '../constants/formConstats';

export const recursiveInclude = (stack, parent = null) => ({
  children: stack
    .filter(q => q.parent === parent)
    .map(item => ({ ...item, ...recursiveInclude(stack, item.key) }))
    .sort((a, b) => a.order - b.order),
});

const isObject = (obj, key) =>
  typeof obj[key] === 'object' && !Array.isArray(obj[key]) && obj[key] !== null && Object.keys(obj).length > 0;

export const changeNullToEmptyString = obj =>
  Object.keys(obj).reduce((acc, key) => {
    return isObject(obj, key)
      ? { ...acc, [key]: changeNullToEmptyString(obj[key]) }
      : { ...acc, [key]: obj[key] === null ? '' : obj[key] };
  }, {});

const dateKeys = ['expirationDate', 'dateHired', 'yearCreated', 'incorporationDate', 'anniversaryDate'];
export const formatDateForInitialValues = obj =>
  Object.keys(obj).reduce((acc, key) => {
    return isObject(obj, key)
      ? { ...acc, [key]: formatDateForInitialValues(obj[key]) }
      : {
          ...acc,
          [key]: dateKeys.includes(key)
            ? obj[key] !== ''
              ? formatISO(new Date(obj[key]), { representation: 'date' })
              : null
            : obj[key],
        };
  }, {});

export const changeEmptyStringToNull = obj =>
  Object.keys(obj).reduce((acc, key) => {
    if (obj[key] instanceof Date || obj[key] instanceof Blob || Array.isArray(obj[key]))
      return { ...acc, [key]: obj[key] };
    return isObject(obj, key)
      ? { ...acc, [key]: changeEmptyStringToNull(obj[key]) }
      : { ...acc, [key]: obj[key] === '' ? null : obj[key] };
  }, {});

export const changeZeroToEmptyString = (obj, keysArray) => {
  const merged = {};
  Object.keys(obj).forEach(key => {
    if (keysArray.includes(key) && obj[key] === 0) {
      merged[key] = '';
    } else {
      merged[key] = obj[key];
    }
  });
  return merged;
};

export const changeEmptyStringToZero = (obj, keysArray) => {
  const merged = {};
  Object.keys(obj).forEach(key => {
    if (keysArray.includes(key) && obj[key] === '') {
      merged[key] = 0;
    } else {
      merged[key] = obj[key];
    }
  });
  return merged;
};

export const removeDollarCommaSigns = (obj, keysArray) => {
  const merged = {};
  Object.keys(obj).forEach(key => {
    if (keysArray.includes(key)) {
      merged[key] = obj[key] !== null ? refineNumberString(obj[key].toString()) : null;
    } else {
      merged[key] = obj[key];
    }
  });
  return merged;
};

export const addDollarCommaSigns = value => {
  return '$' + value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

export const changeNAtoNotAvailable = obj => {
  const merged = {};
  Object.keys(obj).forEach(key => {
    if (obj[key] === craftsmanCertificationsAnswers.NA) {
      merged[key] = craftsmanCertificationsAnswers.NotAvailable;
    } else {
      merged[key] = obj[key];
    }
  });
  return merged;
};

export const changeNotAvailableToNA = obj => {
  const merged = {};
  Object.keys(obj).forEach(key => {
    if (obj[key] === craftsmanCertificationsAnswers.NotAvailable) {
      merged[key] = craftsmanCertificationsAnswers.NA;
    } else {
      merged[key] = obj[key];
    }
  });
  return merged;
};

export const addCommaSigns = value => value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');

export const clearFilterValues = values => {
  return Object.keys(values).reduce((acc, item) => ({ ...acc, [item]: '' }), {});
};

export const anyFilters = values => {
  return !!Object.keys(values).filter(keys => values[keys].length > 0).length;
};

export const isEmptyFilterForm = values => {
  return Object.keys(values).filter(key => values[key].length).length > 0;
};

const convertToYesNo = value => (value ? enums.yesno.Yes : enums.yesno.No);

export const getValueForRadioButton = value => (typeof value === 'boolean' ? convertToYesNo(value) : value);

export const formatInjuryValue = value =>
  (value > 100000 && value.toFixed(0)) ||
  (value > 10000 && value.toFixed(1)) ||
  (value > 1000 && value.toFixed(2)) ||
  value;
