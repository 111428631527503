import React from 'react';
import { Grid, TableCell, Typography } from '@material-ui/core';
import { FastField } from 'formik';

import FormInput from '../../../components/FormInput';
import useStyles from './styles';
import refineNumberString from '../../../../utils/textFormaters/refineNumberString';
import { formatInjuryValue } from '../../../../utils/containersHelpers';

const roundPlacements = 10000;
const multiple = 200000;
const calculateFormat = (val, total) =>
  parseFloat(total || 0) !== 0
    ? Math.round(((val ?? 0) * multiple * roundPlacements) / total) / roundPlacements
    : 0;

const totalDate = (dart, val) =>
  parseFloat(val || 0) !== 0
    ? Math.round(((dart ?? 0) / val) * roundPlacements) / roundPlacements
    : 0;

const totalDaysAwayFromWorkType = 'totalDaysAwayFromWork';

const FormCell = ({ year, index, name, tabIndex }) => {
  const classes = useStyles();
  const yearTotalHoursRefined = year.totalHours && refineNumberString(year.totalHours.toString());

  const getStateOfRate = () =>
    name === totalDaysAwayFromWorkType
      ? totalDate(calculateFormat(year.lostWorkdayRestricted, yearTotalHoursRefined), year.totalDaysAwayFromWork)
      : calculateFormat(year[`${name}`], yearTotalHoursRefined);

  return (
    <TableCell>
      <Grid item xs={12}>
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={6}>
            <Grid container alignItems="center" className={classes.wrapper}>
              <Grid item xs>
                {year.edit ? (
                  <FastField
                    name={`years[${index}].${name}`}
                    component={FormInput}
                    min={0}
                    number="integer"
                    tabIndex={tabIndex}
                  />
                ) : (
                  <Typography variant="body1" align="center">
                    {year[`${name}`]}
                  </Typography>
                )}
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={6} className={classes.rate}>
            <Typography variant="body1" align="center" className={classes.typographyRate}>
              {formatInjuryValue(getStateOfRate())}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </TableCell>
  );
};

export default FormCell;
