import { file } from '../../actionTypes';

export default function fileList(state = {}, action) {
  switch (action.type) {
    case file.get.end:
      const updateValue = Object.keys(action.payload).reduce(
        (acc, item) => ({
          ...acc,
          [item]: {
            list: action.payload[item],
            loading: false,
            downloadList: [],
          },
        }),
        {},
      );

      return { ...state, ...updateValue };

    case file.download.start:
      return {
        ...state,
        [action.payload.sectionKey]: {
          ...state[action.payload.sectionKey],
          downloadList: [
            ...((state[action.payload.sectionKey] && state[action.payload.sectionKey].downloadList) || []),
            action.payload.fileId,
          ],
        },
      };
    case file.download.end:
      return {
        ...state,
        [action.payload.sectionKey]: {
          ...state[action.payload.sectionKey],
          downloadList: state[action.payload.sectionKey].downloadList.filter(
            fileId => fileId !== action.payload.fileId,
          ),
        },
      };
    case file.download.error:
      return {
        ...state,
        [action.payload.sectionKey]: {
          ...state[action.payload.sectionKey],
          downloadList: state[action.payload.sectionKey].downloadList.filter(
            fileId => fileId !== action.payload.fileId,
          ),
        },
      };

    case file.remove.end:
      return {
        ...state,
        [action.payload.sectionKey]: {
          ...state[action.payload.sectionKey],
          loading: false,
          list: state[action.payload.sectionKey].list.filter(file => !action.payload.fileId.includes(file.id)),
        },
      };

    case file.add.end:
      return {
        ...state,
        [action.payload.sectionKey]: {
          ...state[action.payload.sectionKey],
          loading: false,
          list: [...state[action.payload.sectionKey].list, ...action.payload.files],
        },
      };

    default:
      return state;
  }
}
