import React, { useState } from 'react';
import { Formik, FastField, FieldArray } from 'formik';
import { Grid, Button, Table, TableRow, TableCell, TableBody, Typography, Paper } from '@material-ui/core';
import get from 'lodash.get';

import TableActions from './TableActions';
import FormCell from './FormCell';
import FormBody from '../../components/FormBody';
import LoadingButton from '../../components/LoadingButton';
import PageLoader from '../../components/PageLoader';
import TitleCell from './TitleCell';
import { getLastYearsList, getLastYearsOptions, LAST_YEARS_COUNT } from '../../../utils/constants/yearHelper';
import { injuryValidateSchema } from '../../../utils/validate/shemas';
import useStyles from './styles';
import PromptNotification from '../../components/PromptComponent';
import FileUploaderInput from '../../components/FileUploaderInput';
import FormNumberInput from '../../components/FormNumberInput';
import refineNumberString from '../../../utils/textFormaters/refineNumberString';
import formatCommaSeparator from '../../../utils/textFormaters/formatCommaSeparator';

const generateValues = (year, tabIndex) => ({
  edit: true,
  year: year,
  totalHours: '',
  fatalities: '',
  lostWorkdayRestricted: '',
  lostWorkdayAway: '',
  injuriesAndIllnessMedicalOnly: '',
  totalOsha: '',
  totalDaysAwayFromWork: '',
  tabIndex: tabIndex,
});
const sortByYear = values => values.sort((a, b) => b.year - a.year);

const InjuryIllnessForm = ({ companyId, loading, success, error, stack, handleUpdate }) => {
  const classes = useStyles();

  const [tabIndex, setTabIndex] = useState(1);

  if (loading && stack === null) return <PageLoader />;

  const { injury, ...others } = stack;
  const initialValues = {
    ...others,
    years: injury.map(year => ({ ...year, totalHours: formatCommaSeparator(year.totalHours), edit: false })),
  };

  const lastYearsList = getLastYearsList();
  const options = getLastYearsOptions();

  // add year
  const handleAddYear = (formHelper, values) => {
    const existYears = values.years.map(item => parseInt(item.year));
    const year = Math.max(...lastYearsList.filter(available => !existYears.includes(available)));
    const generate = generateValues(year, tabIndex);
    formHelper.push(generate);
    setTabIndex(tabIndex + 1);
  };

  // generate options for each column
  const generateCurrentOptions = (created, current) => {
    const exceptions = created.map(years => years.year).filter(year => year !== current);
    return options.filter(option => !exceptions.includes(option.value));
  };

  // sanitize submited values
  const filterSubmitValues = ({ years, ...others }) =>
    handleUpdate({
      ...others,
      injury: years.map(({ edit, totalHours, ...val }) => ({
        totalHours: parseFloat(refineNumberString(totalHours)),
        ...val,
      })),
    });

  return (
    <Formik
      initialValues={initialValues}
      enableReinitialize
      onSubmit={filterSubmitValues}
      validationSchema={injuryValidateSchema}
    >
      {props => {
        const yearOrdering = props.values.years.map(item => item.year);
        const sortedValues = sortByYear(props.values.years);
        return (
          <FieldArray
            name="years"
            render={arrayHelpers => (
              <>
                <PromptNotification formStatus={props.dirty} />
                <FormBody>
                  <Grid item xs={12}>
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <FastField
                          name="oshadoc"
                          component={FileUploaderInput}
                          label="Upload OSHA 300/300A Logs (Last 3 Years)"
                          expirationDate
                          defaultExpiration={new Date(new Date().getFullYear(), 11, 31)}
                          companyId={companyId}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        {props.values.years.length < LAST_YEARS_COUNT && (
                          <Grid container justify="flex-end" className={classes.buttonNew}>
                            <Button
                              onClick={() => handleAddYear(arrayHelpers, props.values)}
                              size="small"
                              variant="contained"
                              color="primary"
                            >
                              Add
                            </Button>
                          </Grid>
                        )}
                        <Grid container component={Paper}>
                          <Table>
                            <TableBody className={classes.tableBody}>
                              <TableRow>
                                <TableCell>Year</TableCell>
                                {sortedValues.map((year, index) => (
                                  <TableCell key={`${year.year}_cell_year`}>
                                    <Grid item xs={12}>
                                      <TitleCell
                                        name={`years[${index}].year`}
                                        options={generateCurrentOptions(props.values.years, year.year)}
                                        year={year.year}
                                        edit={year.edit}
                                        handleRemove={() => arrayHelpers.remove(index)}
                                        editHandle={val => props.setFieldValue(`years[${index}].edit`, val)}
                                      />
                                    </Grid>
                                  </TableCell>
                                ))}
                              </TableRow>
                              <TableRow>
                                <TableCell>Total hours</TableCell>
                                {sortedValues.map(year => (
                                  <TableCell key={`${year.year}_cell_totalHours`}>
                                    <Grid item xs={12}>
                                      {year.edit ? (
                                        <FastField
                                          name={`years[${yearOrdering.indexOf(year.year)}].totalHours`}
                                          component={FormNumberInput}
                                          maskType="commaDivide"
                                          tabIndex={year.tabIndex}
                                        />
                                      ) : (
                                        <Typography variant="body1" align="center">
                                          {year.totalHours}
                                        </Typography>
                                      )}
                                    </Grid>
                                  </TableCell>
                                ))}
                              </TableRow>
                              <TableRow>
                                <TableCell />
                                {sortedValues.map(year => (
                                  <TableCell key={`${year.year}_cell_empty`}>
                                    <Grid item xs={12}>
                                      <Grid container spacing={2} alignItems="center">
                                        <Grid item xs={6}>
                                          <Typography variant="body1" align="center">
                                            No.
                                          </Typography>
                                        </Grid>
                                        <Grid item xs={6}>
                                          <Typography variant="body1" align="center">
                                            Rate
                                          </Typography>
                                        </Grid>
                                      </Grid>
                                    </Grid>
                                  </TableCell>
                                ))}
                              </TableRow>
                              <TableRow>
                                <TableCell>Fatalities</TableCell>
                                {sortedValues.map(year => (
                                  <FormCell
                                    key={`${year.year}_cell_fatalities`}
                                    year={year}
                                    index={yearOrdering.indexOf(year.year)}
                                    name="fatalities"
                                    tabIndex={year.tabIndex}
                                  />
                                ))}
                              </TableRow>
                              <TableRow>
                                <TableCell>
                                  Total number of cases with days away from work, job transfer, or restrictions (H + I +
                                  J) [DART]
                                </TableCell>
                                {sortedValues.map(year => (
                                  <FormCell
                                    key={`${year.year}_cell_lostWorkdayRestricted`}
                                    year={year}
                                    index={yearOrdering.indexOf(year.year)}
                                    name="lostWorkdayRestricted"
                                    tabIndex={year.tabIndex}
                                  />
                                ))}
                              </TableRow>
                              <TableRow>
                                <TableCell>Total number of cases with days away from work (H) [LWDI]</TableCell>
                                {sortedValues.map(year => (
                                  <FormCell
                                    key={`${year.year}_cell_lostWorkdayAway`}
                                    year={year}
                                    index={yearOrdering.indexOf(year.year)}
                                    name="lostWorkdayAway"
                                    tabIndex={year.tabIndex}
                                  />
                                ))}
                              </TableRow>
                              <TableRow>
                                <TableCell>
                                  Total number of injuries and illnesses involving medical treatment only (I + J) [RWC]
                                </TableCell>
                                {sortedValues.map(year => (
                                  <FormCell
                                    key={`${year.year}_cell_injuriesAndIllnessMedicalOnly`}
                                    year={year}
                                    index={yearOrdering.indexOf(year.year)}
                                    name="injuriesAndIllnessMedicalOnly"
                                    tabIndex={year.tabIndex}
                                  />
                                ))}
                              </TableRow>
                              <TableRow>
                                <TableCell>Total number of injuries and illnesses (M) [TRIR]</TableCell>
                                {sortedValues.map(year => (
                                  <FormCell
                                    key={`${year.year}_cell_totalOsha`}
                                    year={year}
                                    index={yearOrdering.indexOf(year.year)}
                                    name="totalOsha"
                                    tabIndex={year.tabIndex}
                                  />
                                ))}
                              </TableRow>
                              <TableRow>
                                <TableCell>Total number of days away from work (K) [SR]</TableCell>
                                {sortedValues.map(year => (
                                  <FormCell
                                    key={`${year.year}_cell_totalDaysAwayFromWork`}
                                    year={year}
                                    index={yearOrdering.indexOf(year.year)}
                                    name="totalDaysAwayFromWork"
                                    tabIndex={year.tabIndex}
                                  />
                                ))}
                              </TableRow>
                              <TableRow>
                                <TableCell>Actions</TableCell>
                                {sortedValues.map(year => (
                                  <TableActions
                                    key={`${year.year}_cell_actions`}
                                    year={year}
                                    props={props}
                                    arrayHelpers={arrayHelpers}
                                    index={yearOrdering.indexOf(year.year)}
                                  />
                                ))}
                              </TableRow>
                            </TableBody>
                          </Table>
                        </Grid>
                        {props.submitCount > 0 && typeof get(props, 'errors.years', {}) === 'string' && (
                          <Typography variant="subtitle2" color="secondary">
                            {get(props, 'errors.years', '')}
                          </Typography>
                        )}
                      </Grid>
                    </Grid>
                  </Grid>
                  <LoadingButton
                    disabled={!props.dirty || !props.isValid}
                    loading={loading}
                    success={success}
                    error={error}
                    fixed
                    onClick={() => props.handleSubmit()}
                    text="save"
                  />
                </FormBody>
              </>
            )}
          />
        );
      }}
    </Formik>
  );
};

export default InjuryIllnessForm;
