import React from 'react';
import Paper from '@material-ui/core/Paper';

import useStyles from './styles';

const PaperWrapper = ({ children, ...restProps }) => {
  const classes = useStyles();

  return (
    <Paper className={classes.paperContent} {...restProps}>
      {children}
    </Paper>
  );
};

export default PaperWrapper;
