import { all, call, put, select, takeLatest } from 'redux-saga/effects';

import { currentCompany, notification } from '../../../actionTypes';
import dataProvider from '../../../store/dataProvider';
import { updateFiles } from '../../file/updateFiles';
import { uploadSelector } from '../../../utils/pageHelpers';

export function* update({ payload: { companyId, values, sectionKey } }) {
  try {
    const { uploadKeys, ...dirtyValues } = values;
    const formValues = Object.keys(dirtyValues).reduce(
      (acc, item) => (uploadKeys.includes(item) ? acc : { ...acc, [item]: dirtyValues[item] }),
      {},
    );

    // update files
    if (uploadKeys.length) {
      yield all(
        uploadKeys.map(
          key =>
            (values[key].createList.length || values[key].deleteList.length) &&
            call(updateFiles, {
              companyId,
              deleteList: values[key].deleteList,
              createList: values[key].createList,
              sectionKey: key,
            }),
        ),
      );
    }

    const fileObj = yield select(store => uploadSelector(store, uploadKeys));

    const { data } = yield call(dataProvider.updateList, `companies/${companyId}/injuries`, formValues.injury);

    yield put({ type: currentCompany.injuryIllness.update.end, payload: { injury: data, ...fileObj, uploadKeys } });

    // update PQF document validation
    yield put({
      type: currentCompany.companyInfo.validation.start,
      payload: { companyId: companyId, validationKey: sectionKey },
    });
  } catch (e) {
    yield put({ type: notification.add, payload: { error: e.message } });
    yield put({ type: currentCompany.info.update.end });
  }
}

export default function* updateCompanyInfoSaga() {
  yield takeLatest(currentCompany.injuryIllness.update.start, update);
}
