import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  containerPage: {
    padding: theme.spacing(1),
    minHeight: '100vh',
    display: 'grid',
    gridTemplateAreas: `"header" "content"`,
    gridTemplateColumns: '1fr',
    gridTemplateRows: 'auto 1fr',
    gridGap: theme.spacing(2),
  },
  headerGutter: {
    gridArea: 'header',
    position: 'relative',
    zIndex: theme.zIndex.drawer,
  },
  contentContainer: {
    gridArea: 'content',
  },
}));

export default useStyles;
