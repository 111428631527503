import React from 'react';
import { Grid } from '@material-ui/core';
import { FastField, Formik } from 'formik';

// import { saveCompanyAdminPanel } from '../../../../utils/validate/shemas';
import FormInput from '../../../components/FormInput';
import LoadingButton from '../../../components/LoadingButton';
import FormBody from '../../../components/FormBody';
import PageLoader from '../../../components/PageLoader';
//import PromptNotification from '../../components/PromptComponent';
import useStyles from '../../../components/Icons/styles';
import FormDatePicker from '../../../components/FormDataPicker';
import CollapseCard from '../../../components/Cards/CollapseCard';
import { useUserPermissions } from '../../../../utils/effects/userPermissions';
import { userTypes } from '../../../../utils/constants';

const AdminPanelCard = ({
  initialValues,
  loading,
  success,
  error,
  handleSubmit,
  allowUpdate,
  adminNotes,
  isPSM,
  isOwner,
}) => {
  const classes = useStyles();
  const { type } = useUserPermissions();

  if (loading) return <PageLoader />;

  const onSubmit = val => {
    handleSubmit(val);
  };

  return (
    <Formik
      // validationSchema={saveCompanyAdminPanel}
      onSubmit={onSubmit}
      enableReinitialize
      initialValues={initialValues}
    >
      {props => (
        <CollapseCard title="Admin">
          <FormBody loading={loading} disabled={!allowUpdate}>
            <Grid item xs={12} className={classes.contentOverflowWrapper}>
              {type === userTypes.SafetyCouncil && (
                <Grid container spacing={2}>
                  <Grid item md={12}>
                    <Grid container>
                      <FastField
                        label="Expiration Date"
                        name="subscriptionExpirationDate"
                        component={FormDatePicker}
                        disabled={isOwner}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              )}
              {isPSM && (
                <Grid container spacing={2}>
                  <Grid item md={12}>
                    <Grid container>
                      <FastField
                        label="Last PSM Audit"
                        name="psmCompleteDate"
                        component={FormDatePicker}
                        disabled={isOwner}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              )}
              <Grid container spacing={2}>
                <Grid item md={12}>
                  <Grid container>
                    <FastField
                      className={classes.textArea}
                      name="adminNotes"
                      rows={10}
                      multiline
                      value={adminNotes}
                      placeholder="Admin Notes"
                      component={FormInput}
                      disabled={isOwner}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            {allowUpdate && (
              <LoadingButton
                // fixed
                skipCheckPQF={true}
                disabled={!props.dirty || !props.isValid}
                loading={loading}
                success={success}
                error={error}
                onClick={props.handleSubmit}
                text="save"
              />
            )}
          </FormBody>
        </CollapseCard>
      )}
    </Formik>
  );
};

export default AdminPanelCard;
