import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  innerWrapper: ({ level }) => ({
    paddingLeft: theme.spacing(2 * level),
  }),
  downloadText: {
    cursor: 'pointer',
  },
}));

export default useStyles;
