import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  paperContent: {
    padding: theme.spacing(2),
    minHeight: '100%',
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    flexWrap: 'wrap',
  },
}));

export default useStyles;
