import { call, put, takeLatest } from 'redux-saga/effects';

import { settings, notification } from '../../../actionTypes';
import dataProvider from '../../../store/dataProvider';

export function* get() {
  try {
    const { data } = yield call(dataProvider.getOnly, 'bls', {});

    yield put({
      type: settings.BLSData.get.end,
      payload: { data: data.data },
    });
  } catch (e) {
    yield put({ type: settings.BLSData.get.error });
    yield put({ type: notification.add, payload: { error: e.message } });
  }
}

export default function* getBLSData() {
  yield takeLatest(settings.BLSData.get.start, get);
}
