import jsonServerProvider from './jsonServerHelper';
import { httpClient } from './httpHelper';

export default ({apiBaseUrl, apiAuthUrl, permissionsUrl, clearTokens, saveTokens }) => {
  const jsonDataProvider = jsonServerProvider(
    apiBaseUrl,
    httpClient({ apiAuthUrl, permissionsUrl, clearTokens, saveTokens }),
  );

  return jsonDataProvider;
};
