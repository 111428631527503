import React from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Grid } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';

import EditForm from '../EditForm';
import ModalDialog from '../../../components/ModalConfirmation';
import useStyles from './styles';

const TableDisplay = ({
  tableData,
  allowUpdate,
  onDeleteConfirm,
  setItemToDelete,
  onEditConfirm,
  setItemToEdit,
  itemToEdit,
  itemToDelete,
}) => {
  const classes = useStyles();

  return (
    <TableContainer component={Paper} className={classes.tableWrapper}>
      <Table className={classes.table}>
        <TableHead>
          <TableRow>
            <TableCell align="center">Subsidiaries</TableCell>
            <TableCell align="center">Actions</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <>
            {tableData.map((row, index) => {
              return itemToEdit && itemToEdit === row.id ? (
                <EditForm
                  key={index}
                  values={row}
                  index={index}
                  handleSubmit={onEditConfirm}
                  setItemToEdit={setItemToEdit}
                />
              ) : (
                <TableRow key={index}>
                  <TableCell scope="row" align="center">
                    {row.name}
                  </TableCell>
                  {allowUpdate && (
                    <TableCell align="center">
                      <Grid container direction="row" justify="center" alignItems="center">
                        <IconButton aria-label="edit" color="primary" onClick={() => setItemToEdit(row.id)}>
                          <EditIcon />
                        </IconButton>
                        <IconButton onClick={() => setItemToDelete(row.id)}>
                          <DeleteIcon color="error" />
                        </IconButton>
                      </Grid>
                    </TableCell>
                  )}
                </TableRow>
              );
            })}
          </>
        </TableBody>
      </Table>
      <ModalDialog
        title="Are you sure you want to delete this item? "
        isModalOpen={!!itemToDelete}
        onCancel={() => setItemToDelete(null)}
        onConfirm={onDeleteConfirm}
      />
    </TableContainer>
  );
};

export default TableDisplay;
