import React, { useState, useEffect } from 'react';
import { Grid, Typography } from '@material-ui/core';

import ReferencesSubForm from './ReferenceSubForm';
import LargestJobForm from '../LargestJobForm';
import ReferenceTable from '../../containers/ReferencesForm/Table';
import PageLoader from '../../components/PageLoader';
import LoadingButton from '../../components/LoadingButton';
import { changeEmptyStringToNull } from '../../../utils/containersHelpers';
import { setAmountOnSubmit } from '../../../utils/textFormaters/setAmountOnSubmit';

const ReferencesForm = ({
  mapedReferences,
  handleSubmit,
  handleDelete,
  handleUpdate,
  largestJobData,
  loading,
  error,
  success,
  handleUpdateLargestJob,
  allowUpdate,
  loadingOnTable,
}) => {
  const [references, setReferences] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [itemToDelete, setItemToDelete] = useState(null);
  const [toShowForm, setValueToShowForm] = useState(false);
  const [itemToEdit, setItemToEdit] = useState(null);

  useEffect(() => {
    setReferences(mapedReferences);
  }, [mapedReferences]);

  useEffect(() => {
    if (!loadingOnTable) {
      setItemToEdit(null);
    }
  }, [loadingOnTable]);

  const handleOpenFormClick = () => {
    setValueToShowForm(true);
  };

  if (loading && !references.length && !Object.keys(largestJobData).length) return <PageLoader />;

  const onConfirmDeleteReference = () => {
    handleDelete(itemToDelete);
    setIsModalOpen(false);
    setItemToDelete(null);
  };

  const onSubmitOnCreate = values => {
    const newValues = changeEmptyStringToNull(values);
    const formattedValues = {
      ...newValues,
      jobSize: setAmountOnSubmit(newValues.jobSize),
    };
    handleSubmit(formattedValues);
    setValueToShowForm(false);
  };

  const onConfirmEditReference = values => {
    if (values.id === itemToEdit) {
      const newValues = changeEmptyStringToNull(values);
      newValues.jobSize = setAmountOnSubmit(newValues.jobSize);
      handleUpdate(newValues, itemToEdit);
    }
  };

  return (
    <>
      <Grid container>
        <Grid item xs={12}>
          <LargestJobForm
            data={largestJobData}
            loading={loading}
            success={success}
            error={error}
            allowUpdate={allowUpdate}
            handleSubmit={handleUpdateLargestJob}
          />
        </Grid>
        {allowUpdate && (
          <Grid item xs={12}>
            <Typography variant="h5" gutterBottom>
              Customer references
            </Typography>
            <LoadingButton text="Add new reference" position="flex-start" onClick={handleOpenFormClick} />
          </Grid>
        )}
        {toShowForm && (
          <Grid item xs={12}>
            <ReferencesSubForm setValueToShowForm={setValueToShowForm} handleSubmit={onSubmitOnCreate} />
          </Grid>
        )}
        <Grid item xs={12}>
          <ReferenceTable
            references={references}
            loadingOnTable={loadingOnTable}
            onConfirmEditReference={onConfirmEditReference}
            setIsModalOpen={setIsModalOpen}
            setItemToDelete={setItemToDelete}
            allowUpdate={allowUpdate}
            itemToEdit={itemToEdit}
            isModalOpen={isModalOpen}
            setItemToEdit={setItemToEdit}
            onConfirmDeleteReference={onConfirmDeleteReference}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default ReferencesForm;
