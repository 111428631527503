import { createEnum, requestState } from '../utils/actionTypesHelpers';

export default createEnum(
  {
    list: createEnum(requestState, 'headerNotification.list'),
    createFollow: createEnum(requestState, 'headerNotification.create'),
    createUnfollow: createEnum(requestState, 'headerNotification.createUnfollow'),
    acceptFollow: createEnum(requestState, 'headerNotification.acceptFollow'),
    rejectFollow: createEnum(requestState, 'headerNotification.rejectFollow'),
    uploadExpiration: createEnum(requestState, 'headerNotification.uploadExpiration'),
    delete: createEnum(requestState, 'headerNotification.delete'),
  },
  'headerNotification',
);
