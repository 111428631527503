import { call, put, takeLatest } from 'redux-saga/effects';

import { file, notification } from '../../actionTypes';
import dataProvider from '../../store/dataProvider';

export function* download({ payload: { companyId, sectionKey, fileId, tempFile, fileName } }) {
  try {
    const params = tempFile ? { filter: { fileName } } : {};
    yield call(
      dataProvider.getOnly,
      `companies/${companyId}/related-document/${sectionKey}/${tempFile ? 'temp-download' : 'download'}/${fileId}`,
      params,
    );

    yield put({ type: file.download.end, payload: { fileId, sectionKey } });
  } catch (e) {
    yield put({ type: notification.add, payload: { error: e.message } });
    yield put({ type: file.download.error, payload: { fileId, sectionKey } });
  }
}

export default function* downloadFileSaga() {
  yield takeLatest(file.download.start, download);
}
