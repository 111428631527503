import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  approve: ({ approve }) => ({
    color: approve ? theme.palette.success.main : theme.palette.grey['500'],
  }),
  reject: ({ reject }) => ({
    color: reject ? theme.palette.error.main : theme.palette.grey['500'],
  }),
  dialogActions: {
    padding: theme.spacing(1, 3, 2),
  },
  dialogContainer: {
    minWidth: theme.spacing(60),
  },
  tooltip: {
    backgroundColor: theme.palette.background.paper,
    border: `1px solid ${theme.palette.grey['500']}`,
  },
  tooltipArrow: {
    color: theme.palette.grey['500'],
  },
}));

export default useStyles;
