import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Helmet } from 'react-helmet';
import { useParams } from 'react-router-dom';

import CenteredContainer from '../../components/CenteredContainer';
import { auth } from '../../../actionTypes';
import { title } from '../../routes/path';
import ResetPasswordForm from '../../containers/SetNewPasswordForm';

const AcceptInvitationPage = () => {
  const dispatch = useDispatch();
  const { token } = useParams();
  const loading = useSelector(state => state.auth.acceptInvitation.loading);

  const onSubmit = values => {
    dispatch({ type: auth.acceptInvitation.start, payload: { values, token } });
  };

  const titleInvitation =
    "Please set up a new password. After the password is set, you'll be granted access to the website.";
  return (
    <>
      <Helmet>
        <title>{title.public.invitationAccept}</title>
      </Helmet>
      <CenteredContainer>
        <ResetPasswordForm handleSubmit={onSubmit} title={titleInvitation} loading={loading} />
      </CenteredContainer>
    </>
  );
};

export default AcceptInvitationPage;
