import React from 'react';

import Certification from '../../images/General.png';
import useStyles from './styles';

const GeneralIcon = ({ size }) => {
  const classes = useStyles({ size });
  return (
    <div className={classes.container}>
      <img src={Certification} alt="Certification icon" />
    </div>
  );
};

export default GeneralIcon;
