import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Helmet } from 'react-helmet';

import { title } from '../../routes/path';
import CenteredContainer from '../../components/CenteredContainer';
import ResetPasswordForm from '../../containers/ResetPasswordForm';
import { auth } from '../../../actionTypes';

const ResetPasswordPage = () => {
  const dispatch = useDispatch();
  const loading = useSelector(state => state.auth.resetPassword.loading);

  const onSubmit = values => {
    dispatch({ type: auth.resetPassword.start, payload: values });
  };

  return (
    <>
      <Helmet>
        <title>{title.public.resetPassword}</title>
      </Helmet>
      <CenteredContainer>
        <ResetPasswordForm handleSubmit={onSubmit} loading={loading} />
      </CenteredContainer>
    </>
  );
};

export default ResetPasswordPage;
