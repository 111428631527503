import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  cardWrapper: {
    padding: theme.spacing(2, 2, 0, 2),
    marginBottom: theme.spacing(2),
  },
  cardContent: {
    padding: theme.spacing(0, 0, 2, 0),
    '&:last-child': {
      paddingBottom: theme.spacing(2),
    },
  },
  cardHeader: {
    padding: theme.spacing(0, 0, 1, 0),
    '& .MuiCardHeader-root': {
      padding: theme.spacing(1),
    },
    '& .MuiCardHeader-action': {
      margin: theme.spacing(0),
    },
  },
  button: {
    fontSize: theme.typography.h3.fontSize,
  },
}));

export default useStyles;
