import React, { useEffect } from 'react';
import { Grid } from '@material-ui/core';
import { FastField, Formik } from 'formik';

import FormInput from '../../../components/FormInput';
import useStyles from '../styles';
import { createOrganizationInfoSchema } from '../../../../utils/validate/shemas';
import businessFormTypes from '../../../../utils/constants/businessFormTypes';
import FormSelect from '../../../components/FormSelect';
import FormDatePicker from '../../../components/FormDataPicker';
import UsaStates from '../../../../utils/constants/usaStates';
import eeoCategoryTypes from '../../../../utils/constants/eeoCategoryTypes';
import FormAutocomplete from '../../../components/FormAutocomplete';

const WatchEffect = ({ dirty, isValid, values, formUpdate }) => {
  useEffect(() => {
    formUpdate(values, dirty, isValid);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dirty, values, isValid]);
  return null;
};

const OrganizationInfoSubForm = ({ handleSubmit, initialValues, formUpdate }) => {
  const classes = useStyles();
  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      validationSchema={createOrganizationInfoSchema}
      onSubmit={handleSubmit}
    >
      {props => (
        <>
          <WatchEffect formUpdate={formUpdate} dirty={props.dirty} isValid={props.isValid} values={props.values} />
          <Grid container direction="column" spacing={1}>
            <Grid item lg={4} sm={4}>
              <FastField
                className={classes.inputRow}
                label="Form of Business"
                name="formOfBusiness"
                options={businessFormTypes}
                id="formOfBusiness"
                component={FormSelect}
              />
            </Grid>
            <Grid item lg={4} sm={4}>
              <Grid container className={classes.calendarWrapper}>
                <FastField
                  label="Incorporation date"
                  name="incorporationDate"
                  disableFuture
                  variant="inline"
                  component={FormDatePicker}
                />
              </Grid>
            </Grid>
            <Grid item lg={4} sm={4}>
              <FastField
                className={classes.inputRow}
                label="State of Incorporation"
                name="incorporationState"
                options={UsaStates}
                component={FormAutocomplete}
              />
            </Grid>
            <Grid item lg={4} sm={4}>
              <FastField
                className={classes.inputRow}
                label="Percent Minority/Female Owned"
                name="percentMinorityOwned"
                min={0}
                max={100}
                number="integer"
                component={FormInput}
              />
            </Grid>
            <Grid item lg={4} sm={4}>
              <FastField
                className={classes.inputRow}
                label="EEO Category"
                name="eeoCategory"
                options={eeoCategoryTypes}
                component={FormAutocomplete}
              />
            </Grid>
          </Grid>
        </>
      )}
    </Formik>
  );
};

export default OrganizationInfoSubForm;
