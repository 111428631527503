import React from 'react';
import { Button, Dialog, DialogActions, DialogTitle, Grid, Typography } from '@material-ui/core';
import WarningIcon from '@material-ui/icons/Warning';

import useStyles from './styles';
import { useUserPermissions } from '../../../utils/effects/userPermissions';
import { userTypes } from '../../../utils/constants';

const WarningDocumentCreate = ({ open, handleClose, handleConfirm }) => {
  const classes = useStyles();
  const { type } = useUserPermissions();

  return (
    <Dialog open={open} onClose={handleClose}>
      <Grid container alignItems="center" className={classes.container}>
        <Grid item xs={2}>
          <Grid container alignItems="center" justify="center">
            <WarningIcon className={classes.icon} />
          </Grid>
        </Grid>
        <Grid item xs={10}>
          <DialogTitle className={classes.title}>
            {type === userTypes.Contractor ? (
              <Typography variant="subtitle2" align="center" gutterBottom>
                If you save current changes a new PQF will be created. You will need to resubmit the PQF document.
              </Typography>
            ) : (
              <Typography variant="subtitle2" align="center" gutterBottom>
                If you save current changes a new PQF will be created. This contractor will need to resubmit the PQF
                document.
              </Typography>
            )}
          </DialogTitle>
        </Grid>
      </Grid>
      <DialogActions>
        <Grid container alignItems="center" justify="flex-end" spacing={2}>
          <Grid item xs={2}>
            <Button autoFocus onClick={handleConfirm} color="primary" variant="contained" size="small" fullWidth>
              Save
            </Button>
          </Grid>
          <Grid item xs={2}>
            <Button onClick={handleClose} variant="contained" size="small" autoFocus fullWidth>
              Cancel
            </Button>
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  );
};

export default WarningDocumentCreate;
