import { call, put, takeLatest } from 'redux-saga/effects';

import { filePsmAudit, notification } from '../../actionTypes';
import dataProvider from '../../store/dataProvider';

export function* add({ payload: { companyId, files } }) {
  try {
    const { data } = yield call(dataProvider.createList, `companies/${companyId}/psm-audit/save`, files);

    yield put({ type: filePsmAudit.add.end, payload: data.map(file => ({ ...file, companyId })) });
  } catch (e) {
    yield put({ type: notification.add, payload: { error: e.message } });
    yield put({ type: filePsmAudit.add.error });
  }
}

export default function* addFilesSaga() {
  yield takeLatest(filePsmAudit.add.start, add);
}
