import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  container: {
    minWidth: '100%',
    padding: theme.spacing(0, 2),
    overflow: 'auto',
  },
  columns: {
    display: 'flex',
    gap: '12px',
    margin: '16px 0',
    justifyContent: 'space-between',
    width: '100%',
    maxWidth: '332px',
    flexWrap: 'wrap',
  },
  column: {
    maxWidth: '160px',
  },
  select: { minWidth: '136px' },
  actions: {
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-end',
  },
}));

export default useStyles;
