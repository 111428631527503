import {
  SharingRequest,
  SharingRequestApproved,
  SharingRequestRejected,
  UploadExpiration,
  ReviewApprove,
  ReviewReject,
  ReviewRequest,
} from '../../containers/Header/Notifications';

export default {
  FollowRequest: SharingRequest,
  FollowRequestApprove: SharingRequestApproved,
  FollowRequestReject: SharingRequestRejected,
  UploadExpiration: UploadExpiration,
  ReviewApprove: ReviewApprove,
  ReviewReject: ReviewReject,
  ReviewRequest: ReviewRequest,
};
