import React from 'react';
import { TableCell, TableRow, Typography } from '@material-ui/core';

import StatusChecker from '../StatusChecker';
import AnswerTypes from '../AnswerTypes';

const QuestionItem = ({
  questionText,
  id,
  answer,
  status,
  reviewComment,
  handleDownload,
  editable,
  updateList,
  downloadList,
  handleUpdate,
  sectionClass,
  questionClass,
}) => {
  return (
    <>
      <TableRow className={sectionClass}>
        <TableCell colSpan={2}>
          <Typography variant="body1">{questionText}</Typography>
        </TableCell>
      </TableRow>
      <TableRow className={questionClass}>
        <TableCell>
          {answer &&
            Object.keys(answer).map(item => (
              <AnswerTypes
                key={item}
                countAnswers={Object.keys(answer).length}
                fieldKey={item}
                {...answer[item]}
                downloadList={downloadList}
                handleDownload={handleDownload}
              />
            ))}
        </TableCell>
        <TableCell>
          <StatusChecker
            editable={editable}
            id={id}
            status={status}
            comment={reviewComment}
            updateList={updateList}
            handleUpdate={handleUpdate}
          />
        </TableCell>
      </TableRow>
    </>
  );
};

export default QuestionItem;
