import { settings } from '../../actionTypes';

const initialState = {
  loading: false,
  data: {},
  users: [],
  success: false,
  error: false,
};

export default function settingsReducer(state = initialState, action) {
  switch (action.type) {
    case settings.configuration.get.start:
      return { ...state, loading: true };

    case settings.configuration.get.end:
      return {
        ...state,
        loading: false,
        data: action.payload.data,
        users: action.payload.users,
      };
    case settings.configuration.get.error:
      return {
        ...state,
        loading: false,
        data: {},
      };
    case settings.configuration.update.start:
      return { ...state, loading: true };

    case settings.configuration.update.end:
      return {
        ...state,
        loading: false,
        data: { ...state.data, ...action.payload },
        success: true,
        error: false,
      };
    case settings.configuration.update.error:
      return {
        ...state,
        loading: false,
        success: false,
        error: true,
      };

    default:
      return state;
  }
}
