import React, { useEffect, useState } from 'react';
import {
  Grid,
  Typography,
  Button,
  DialogTitle,
  DialogActions,
  Dialog,
  CircularProgress,
  Collapse,
} from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';

import useStyles from './styles';
import headerNotification from '../../../../actionTypes/headerNotification';
import formatDate from '../../../../utils/textFormaters/date';
import { userTypes } from '../../../../utils/constants';

export const UploadExpiration = ({ item, companyId, userType, dismissHandler, loading }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const isSafetyCouncil = userType === userTypes.SafetyCouncil;
  const isOwner = userType === userTypes.Owner;
  const isContractor = userType === userTypes.Contractor;

  const MailingLogId = item.notificationEntities.MailingLog[0].id;
  const MailingDate = formatDate(item.createDate);

  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    if (isModalOpen)
      dispatch({ type: headerNotification.uploadExpiration.start, payload: { companyId, MailingLogId } });
  }, [MailingLogId, companyId, dispatch, isModalOpen]);

  const uploadExpirationData = useSelector(state => state.headerNotifications || '');
  const uploadExpirationBody =
    uploadExpirationData &&
    uploadExpirationData.uploadExpirationItems.body &&
    uploadExpirationData.uploadExpirationItems.body.split('/style>')[1];

  return (
    <>
      {(isSafetyCouncil || isOwner) && (
        <Typography variant="body1" gutterBottom>
          <b>{MailingDate} </b>
          Uploaded documents will expire soon.
          <b className={classes.linkTitle} onClick={() => setIsModalOpen(true)}>
            See details
          </b>
        </Typography>
      )}
      {isContractor && (
        <Typography variant="body1" gutterBottom>
          <b>{MailingDate} </b>
          Uploaded documents will expire soon.
          <b className={classes.linkTitle} onClick={() => setIsModalOpen(true)}>
            See details
          </b>
        </Typography>
      )}
      <Grid container justify="center" alignItems="flex-end" className={classes.buttonsWrapper}>
        <Button variant="contained" size="small" onClick={() => dismissHandler(companyId, item.id)}>
          Dismiss
        </Button>
      </Grid>
      <Dialog disableBackdropClick open={isModalOpen} onClose={() => setIsModalOpen(false)}>
        <Collapse in={loading}>
          <Grid item xs={12}>
            <Grid container alignItems="center" justify="center" className={classes.loadingContainer}>
              <DialogTitle disableTypography>
                <CircularProgress size={24} />
              </DialogTitle>
            </Grid>
          </Grid>
        </Collapse>
        <Collapse in={!loading}>
          <DialogTitle disableTypography>
            <Typography dangerouslySetInnerHTML={{ __html: uploadExpirationBody }} />
          </DialogTitle>
          <DialogActions>
            <Button variant="contained" color="primary" size="small" onClick={() => setIsModalOpen(false)}>
              OK
            </Button>
          </DialogActions>
        </Collapse>
      </Dialog>
    </>
  );
};
