import React from 'react';
import { Grid } from '@material-ui/core';

import PaginationWithCustomRows from '../../components/PaginationWithCustomRows';
import CompanyTable from './CompanyTable';
import FilterForm from './FilterForm';

const ArchiveCompany = ({ filter, pagination, companies }) => {
  const { handleFilter, filterValues, noFiltersSelected } = filter;
  const { total, currentPage, itemsPerPage, handleChangePage, handleRowAmountChange } = pagination;

  return (
    <Grid item xs={12}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <FilterForm handleOnChange={handleFilter} values={filterValues} />
        </Grid>
        <Grid item xs={12}>
          <CompanyTable {...companies} noFiltersSelected={noFiltersSelected} />
        </Grid>
        <Grid item xs={12}>
          <PaginationWithCustomRows
            total={total}
            currentPage={currentPage}
            itemsPerPage={itemsPerPage}
            onPageChange={handleChangePage}
            onRowAmountChange={handleRowAmountChange}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default ArchiveCompany;
