import React, { useState, useEffect } from 'react';
import { Grid, Typography } from '@material-ui/core';
import isEqual from 'lodash.isequal';

import { orgInfoTableSchema } from '../../../utils/validate/shemas';
import useStyles from './styles';
import PageLoader from '../../components/PageLoader';
import FormBody from '../../components/FormBody';
import LoadingButton from '../../components/LoadingButton';
import { changeNullToEmptyString, formatDateForInitialValues } from '../../../utils/containersHelpers';
import OrganizationInfoSubForm from './OrganizationInfoSubForm';
import CommonTableForm from '../CommonTable';
import FormNumberInput from '../../components/FormNumberInput';
import PromptNotification from '../../components/PromptComponent';
import { useCompanyInfo } from '../../../utils/effects/useCompanyInfo';
import { getLastYearsOptions } from '../../../utils/constants/yearHelper';

const maxAmountOfYears = 3;

const getAverage = list => {
  const sum = list.reduce((acc, currentValue, index) => {
    return index <= 2 ? acc + Number(currentValue.number) : acc;
  }, 0);

  const amountOfYears = list.length > maxAmountOfYears ? maxAmountOfYears : list.length;
  const average = sum / amountOfYears || 0;
  return Number.parseFloat(average).toFixed(1);
};

const transformTableValues = values =>
  values.map(item => ({
    id: item.saveId,
    year: item.year,
    number: parseInt(item.amount),
  }));

const OrganizationInfoForm = ({
  companyId,
  organizationInfo,
  organizationArrays,
  handleSubmit,
  loading,
  success,
  error,
  allowUpdate,
  requestInProgress,
}) => {
  const { isBasicContractor } = useCompanyInfo(companyId);

  const classes = useStyles();

  const [formStatus, setFormStatus] = useState({
    values: {},
    dirty: false,
    isValid: isBasicContractor,
  });

  const [tableStatus, setTableStatus] = useState({
    values: organizationArrays,
    changed: false,
  });

  const initialValues = formatDateForInitialValues(changeNullToEmptyString(organizationInfo));

  const [averageNumberOfEmployees, setAverageNumberOfEmployees] = useState(initialValues.averageNumberOfEmployees);

  useEffect(() => {
    setAverageNumberOfEmployees(initialValues.averageNumberOfEmployees);
  }, [initialValues.averageNumberOfEmployees]);

  const handleSubmitOnForms = () => {
    const valuesToSend = {
      organizationInfo: {
        ...formStatus.values,
        averageNumberOfEmployees,
      },
      organizationArrays: transformTableValues(tableStatus.values),
    };
    handleSubmit(valuesToSend);

    setFormStatus({
      ...formStatus,
      dirty: false,
      isValid: isBasicContractor,
    });

    setTableStatus({
      ...tableStatus,
      changed: false,
    });
  };

  if (loading && !Object.keys(initialValues).length) return <PageLoader />;

  const canSubmit = (formStatus.isValid && formStatus.dirty) || (formStatus.isValid && tableStatus.changed);

  const tableData = organizationArrays.map(item => ({
    ...item,
    saveId: item.id,
    id: item.year,
    amount: item.number,
  }));

  return (
    <>
      <PromptNotification formStatus={formStatus.dirty || tableStatus.changed} />
      <FormBody loading={loading} disabled={!allowUpdate}>
        <Grid item xs={12}>
          <Grid container>
            <Grid item lg={12}>
              {!isBasicContractor && (
                <OrganizationInfoSubForm
                  initialValues={initialValues}
                  handleSubmit={handleSubmitOnForms}
                  formUpdate={(values, dirty, isValid) => setFormStatus({ values, dirty, isValid })}
                />
              )}
              <Grid container>
                <Typography variant="h5" gutterBottom className={classes.title}>
                  Average Number of Employees
                </Typography>
              </Grid>
              <CommonTableForm
                tableData={tableData}
                setTableData={values => {
                  setAverageNumberOfEmployees(getAverage(transformTableValues(values)));
                  setTableStatus({
                    values,
                    changed: !isEqual(values, tableData),
                  });
                }}
                label="Employees Number"
                initialTableValues={{ year: '', amount: '' }}
                fieldName="amount"
                maskType="fromOne"
                fieldComponent={FormNumberInput}
                allowUpdate={allowUpdate}
                loading={loading}
                validationSchema={orgInfoTableSchema}
                getYearsOptions={getLastYearsOptions}
              />
            </Grid>
            <Grid item lg={6}>
              <Grid container justify="space-between">
                <Grid item>
                  <Typography variant="h5" gutterBottom>
                    Average Number of Employees over the last three years
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography variant="h5" gutterBottom>
                    {averageNumberOfEmployees}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        {allowUpdate && (
          <LoadingButton
            fixed
            disabled={!canSubmit}
            success={success}
            error={error}
            loading={requestInProgress}
            onClick={handleSubmitOnForms}
            text="save"
          />
        )}
      </FormBody>
    </>
  );
};
export default OrganizationInfoForm;
