import React, { useState } from 'react';
import Grid from '@material-ui/core/Grid';
import { FieldArray } from 'formik';

import DropZone from '../../../components/DropZone';
import UploadItem from '../UploadItem';

const FileUploads = ({
  values,
  url,
  expirationDate,
  defaultExpiration,
  completedOn,
  completedBy,
  maxFileSize,
  isDocumentList,
  accept,
  handleUploaded,
  onUploadStart,
  single,
}) => {
  const [fileStatuses, setFilesStatus] = useState({});

  const handleDrop = (files, arrayHelpers) => {
    if (single) {
      arrayHelpers.replace(0, {
        id: files[0].id,
        fileName: files[0].name,
        description: files[0].name,
        expirationDate: null,
        completedOn: null,
        completedBy: '',
      });
    } else {
      files.forEach(item => {
        arrayHelpers.push({
          id: item.id,
          fileName: item.name,
          description: item.name,
          expirationDate: null,
          completedOn: null,
          completedBy: '',
        });
      });
    }
  };

  const onUploadUpdate = file =>
    setFilesStatus(prevState => ({ ...prevState, [file.id]: { percent: file.percent, error: file.error } }));

  return (
    <Grid container spacing={2}>
      <FieldArray
        name="files"
        render={arrayHelpers => (
          <Grid item xs={12}>
            <DropZone
              url={url}
              maxFileSize={maxFileSize}
              isDocumentList={isDocumentList}
              accept={accept}
              dropped={values.files.length > 0}
              onDrop={files => handleDrop(files, arrayHelpers)}
              onUploadUpdate={onUploadUpdate}
              onUploaded={handleUploaded}
              onUploadStart={onUploadStart}
              single={single}
            />
            {values.files.length > 0 && (
              <Grid container spacing={2}>
                {values.files.map((item, index) => (
                  <Grid item xs={12} key={item.id}>
                    <UploadItem
                      formPrefix={`files[${index}]`}
                      expirationDate={expirationDate}
                      defaultExpiration={defaultExpiration}
                      completedBy={completedBy}
                      completedOn={completedOn}
                      name={item.fileName}
                      status={(fileStatuses[item.id] && fileStatuses[item.id].percent) || 0}
                      isError={(fileStatuses[item.id] && fileStatuses[item.id].error) || false}
                      handleRemove={() => arrayHelpers.remove(index)}
                    />
                  </Grid>
                ))}
              </Grid>
            )}
          </Grid>
        )}
      />
    </Grid>
  );
};

export default FileUploads;
