import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  container: {
    padding: theme.spacing(4),
  },
  title: {
    paddingTop: theme.spacing(3),
    '& h2': {
      fontSize: theme.spacing(3),
    },
  },
  content: {
    display: 'flex',
    justifyContent: 'center',
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    top: theme.spacing(0.5),
    right: theme.spacing(0.5),
  },
  actions: {
    padding: theme.spacing(2),
  },
}));

export default useStyles;
