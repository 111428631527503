import React from 'react';
import arrayMove from 'array-move';
import { useFormikContext } from 'formik';

import SortableList from '../SortableList';

const SortableWrap = ({ childrenKeys, parent, companyId }) => {
  const { setFieldValue, values } = useFormikContext();

  const onSortEnd = ({ oldIndex, newIndex }) => {
    const arr = childrenKeys.sort(function(a, b) {
      return values.fields[a].order - values.fields[b].order;
    });
    const listNewIndex = arrayMove(arr, oldIndex, newIndex);

    childrenKeys.forEach(item => {
      setFieldValue(`fields.${item}.order`, listNewIndex.indexOf(item), false);
    });
  };

  return (
    <SortableList
      childrenKeys={childrenKeys}
      parent={parent}
      lockAxis="y"
      onSortEnd={onSortEnd}
      setFieldValue={setFieldValue}
      useDragHandle
      companyId={companyId}
    />
  );
};

export default SortableWrap;
