import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  emptyDataWrapper: {
    marginTop: theme.spacing(6),
    padding: theme.spacing(2, 0),
  },
}));

export default useStyles;
