import React from 'react';
import { Formik } from 'formik';
import { getYear } from 'date-fns';
import Grid from '@material-ui/core/Grid';

import LoadingButton from '../../components/LoadingButton';
import PageLoader from '../../components/PageLoader';
import FormBody from '../../components/FormBody';
import PromptNotification from '../../components/PromptComponent';
import BLSIndustry from '../../../utils/constants/BLSIndustry';
import Table from './Table';
import { BLSFormSchema } from '../../../utils/validate/shemas';

const BLSForm = ({ loading, values, updating, handleUpdate, success, error }) => {
  if (loading) {
    return <PageLoader />;
  }

  const currentYear = getYear(new Date());

  const normalizeData = values.reduce(
    (acc, item) =>
      Object.keys(acc).includes(`${item.year}`)
        ? {
            ...acc,
            [item.year]: {
              ...acc[item.year],
              [item.naics]: item,
            },
          }
        : {
            ...acc,
            [item.year]: {
              [item.naics]: item,
            },
          },
    {},
  );

  const lastYearsData = Array.from({ length: 2 }).reduce((acc, _, index) => {
    const year = currentYear - index - 1;

    if (values.some(x => x.year === year)) {
      return {
        ...acc,
        [year]: values.filter(x => x.year === year)
          .reduce((yearItems, item) => ({ ...yearItems, [item.naics]: item.value }), {}),
      };
    }

    return acc;
  }, {});

  const initialValues = Object.keys(BLSIndustry).reduce(
    (acc, item) => ({
      ...acc,
      [item]: normalizeData[currentYear] && normalizeData[currentYear][item] ? normalizeData[currentYear][item] : { value: '' },
    }),
    {},
  );

  const onSubmit = values => {
    const newValues = Object.keys(values)
      .filter(item => values[item].value !== undefined)
      .map(key =>
        values[key].id
          ? values[key]
          : {
              naics: key,
              year: currentYear,
              value: parseInt(values[key].value),
            },
      );

    handleUpdate(newValues);
  };

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      onSubmit={onSubmit}
      validationSchema={() => BLSFormSchema()}
    >
      {props => (
        <>
          <PromptNotification formStatus={props.dirty} />
          <FormBody loading={updating}>
            <Grid item xs={12}>
              <Table loading={updating} currentYear={currentYear} values={props.values} lastYearsData={lastYearsData} />
            </Grid>
            <LoadingButton
              fixed
              skipCheckPQF
              disabled={!props.dirty || !props.isValid}
              text="save"
              loading={updating}
              onClick={props.handleSubmit}
              success={success}
              error={error}
            />
          </FormBody>
        </>
      )}
    </Formik>
  );
};

export default BLSForm;
