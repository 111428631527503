import React from 'react';
import { Helmet } from 'react-helmet';

import NotFoundComponent from '../../containers/NotFound';
import { title } from '../../routes/path';

const NotFound = () => {
  return (
    <>
      <Helmet>
        <title>{title.private.notFound}</title>
      </Helmet>
      <NotFoundComponent />
    </>
  );
};

export default NotFound;
