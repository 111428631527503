import { all, call, put, takeLatest } from 'redux-saga/effects';

import { currentCompany, notification } from '../../../actionTypes';
import dataProvider from '../../../store/dataProvider';

export function* update({ payload: { companyId, organizationInfo, organizationArrays, sectionKey } }) {
  try {
    const [organizationInfoData, organizationArraysData] = yield all([
      call(dataProvider.updateOnly, `companies/${companyId}/org-info`, organizationInfo),
      call(dataProvider.createList, `companies/${companyId}/avg-num-employees`, organizationArrays),
    ]);

    yield put({
      type: currentCompany.organizationInfo.update.end,
      payload: { organizationInfo: organizationInfoData.data, organizationArrays: organizationArraysData.data },
    });

    //update PQF document validation
    yield put({
      type: currentCompany.companyInfo.validation.start,
      payload: { companyId: companyId, validationKey: sectionKey },
    });
  } catch (e) {
    yield put({ type: currentCompany.organizationInfo.update.error });
    yield put({ type: notification.add, payload: { error: e.message } });
  }
}

export default function* organizationInfoSaga() {
  yield takeLatest(currentCompany.organizationInfo.update.start, update);
}
