export const clearTokens = (keyForException = []) => {
  const localStorageKeys = Object.keys(localStorage);

  localStorageKeys.forEach(key => {
    if (keyForException.includes(key)) return;
    delete localStorage[key];
  });
};

export const saveTokens = (data = {}) => {
  const dataKeys = Object.keys(data);

  dataKeys.forEach(key => {
    if (key.toLowerCase().includes('token') && key !== 'refreshToken') {
      localStorage.accessToken = data[key];
      return;
    }
    localStorage[key] = data[key];
  });
};

export const hasTokens = () => !!localStorage.accessToken && !!localStorage.username;

export const getHeaders = authenticated => {
  const headers = new Headers({
    'Content-Type': 'application/json',
    SkipResetValidationAfterSuccess: localStorage.skipResetValidationAfterSuccess,
  });
  if (authenticated) headers.set('Authorization', `Bearer ${localStorage.accessToken}`);

  return headers;
};

let newTokenRequest;

export const reauthenticateIfNeeded = async (
  { apiAuthUrl, permissionsUrl },
  status,
  { clearTokensData = clearTokens, saveTokensData = saveTokens },
) => {
  if (status !== 401 || !localStorage.accessToken) return false;
  if (newTokenRequest) {
    return newTokenRequest;
  }

  newTokenRequest = new Promise(async resolve => {
    const { refreshToken, accessToken } = localStorage;
    const request = new Request(apiAuthUrl, {
      method: 'PUT',
      body: JSON.stringify({ refreshToken, accessToken }),
      headers: getHeaders(true),
    });

    const response = await fetch(request);

    if (response.status < 200 || response.status >= 300) {
      clearTokensData();
      window.location.href = '/sign-in';
      resolve(false);
      newTokenRequest = null;
      return;
    }

    const data = await response.json();
    saveTokensData(data);
    resolve(true);
    newTokenRequest = null;
  });
  return newTokenRequest;
};

export const getValueFromToken = () => {
  if (localStorage.accessToken) {
    return JSON.parse(window.atob(localStorage.accessToken.split('.')[1]));
  }
  return null;
};

export const getPermissions = () => {
  const role = localStorage.getItem('role');
  const permissions = localStorage.getItem('permissions');
  return role ? Promise.resolve({ role, list: permissions }) : Promise.reject();
};
