import React from 'react';
import { FastField } from 'formik';
import { Grid } from '@material-ui/core';

import FormInput from '../../../components/FormInput';
import FormDatePicker from '../../../components/FormDataPicker';
import useStyles from './styles';
import FormNumberInput from '../../../components/FormNumberInput';
import FileUploaderInput from '../../../components/FileUploaderInput';

const AutomobileInsuranceSubForm = ({ names, companyId }) => {
  const classes = useStyles();

  const { companyInsuranceName, expirationDate, amount, autoinsur } = names;

  return (
    <Grid container>
      <Grid item lg={6} sm={6}>
        <Grid container>
          <Grid item lg={8}>
            <FastField className={classes.inputRow} label="Name" name={companyInsuranceName} component={FormInput} />
            <FastField label="Expiration Date" name={expirationDate} component={FormDatePicker} />
            <FastField
              className={classes.inputRow}
              label="Coverage Amount"
              name={amount}
              maskType="dollarNumber"
              component={FormNumberInput}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item lg={12} sm={12}>
        <FastField
          name={autoinsur}
          component={FileUploaderInput}
          label="Upload Automobile Insurance"
          companyId={companyId}
          expirationDate
        />
      </Grid>
    </Grid>
  );
};

export default AutomobileInsuranceSubForm;
