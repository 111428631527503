import { all, call, put, takeLatest } from 'redux-saga/effects';

import { currentCompany, notification } from '../../../actionTypes';
import dataProvider from '../../../store/dataProvider';

export function* getOrganizationInfo({ companyId }) {
  try {
    const [orgInfoData, orgArraysData] = yield all([
      call(dataProvider.getOnly, `companies/${companyId}/org-info`, {}),
      call(dataProvider.getList, `companies/${companyId}/avg-num-employees`, {}),
    ]);

    yield put({
      type: currentCompany.organizationInfo.get.end,
      payload: { organizationInfo: orgInfoData.data, organizationArrays: orgArraysData.data.data },
    });
  } catch (e) {
    yield put({ type: notification.add, payload: { error: e.message } });
    yield put({ type: currentCompany.organizationInfo.get.end, payload: { data: {} } });
  }
}

export default function* getOrganizationInfoSaga() {
  yield takeLatest(currentCompany.organizationInfo.get.start, getOrganizationInfo);
}
