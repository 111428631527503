import PeopleAltIcon from '@material-ui/icons/PeopleAlt';
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';
import EqualizerIcon from '@material-ui/icons/Equalizer';
import AssignmentIcon from '@material-ui/icons/Assignment';
import DescriptionIcon from '@material-ui/icons/Description';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import ContactSupportIcon from '@material-ui/icons/ContactSupport';
import ArchiveIcon from '@material-ui/icons/Archive';
import SettingsIcon from '@material-ui/icons/Settings';

import CertificationIcon from '../../ui/components/Icons/CertificationIcon';
import TrainingIcon from '../../ui/components/Icons/TrainingIcon';
import GeneralIcon from '../../ui/components/Icons/GeneralIcon';
import formatPath from '../../utils/textFormaters/formatPath';
import { routeCreator, sectionKey } from '../../ui/routes/path';
import { userTypes, contractorLevels, contractorLevelPathEnum } from './index';

export const userTypesSidebarMenu = (id, ownerAddendumList, companyType, type, selfCompany, isBasicContractor) => {
  const companyRoutes = routeCreator();
  const company = companyRoutes.private.company;

  const ownRoutes = routeCreator(companyRoutes.private.settings);
  const ownCompany = ownRoutes.private.company;

  const listAddendumCompany = pathAddendum =>
    ownerAddendumList.length === 0
      ? [
          {
            title: 'There are no addendums yet',
            disabled: true,
          },
        ]
      : ownerAddendumList.map(company => ({
          title: company.companyName,
          to: formatPath(pathAddendum, { ownerId: company.companyId }),
        }));

  const getRouteType =
    type === userTypes.SafetyCouncil && (companyType === userTypes.Contractor || companyType === userTypes.Owner)
      ? company
      : ownCompany;

  /* SLOPPY TEMP SOLUTION */

  const adminPanel =
    type === userTypes.SafetyCouncil
      ? [
          {
            title: 'Company Settings',
            to: formatPath(getRouteType.systemSettings, { id }),
          },
          {
            title: 'Users',
            to: formatPath(getRouteType.users, { id }),
          },
          // {
          //   title: 'Admin Panel',
          //   to: formatPath(getRouteType.adminPanel, { id }),
          // },
        ]
      : [
          {
            title: 'Company Settings',
            to: formatPath(getRouteType.systemSettings, { id }),
          },
          {
            title: 'Users',
            to: formatPath(getRouteType.users, { id }),
          },
        ];

  /*
        OLD MENU SYSTEM
        TO BE REPLACED
  */

  if (
    (type === userTypes.SafetyCouncil && companyType === userTypes.Contractor) ||
    (type === userTypes.Contractor && selfCompany)
  ) {
    /*
        CHECKS IF COMAPANY IS A BASIC CONTRACTOR
    */

    if (isBasicContractor) {
      return {
        children: [
          {
            title: 'General Information',
            to: formatPath(getRouteType.settingsInfo, { id }),
            icon: GeneralIcon,
            children: [
              {
                title: 'Company Information',
                to: formatPath(getRouteType.settingsInfo, { id }),
                validationKey: sectionKey.private.company.settingsInfo,
              },
              {
                title: 'Officers',
                to: formatPath(getRouteType.officers, { id }),
                validationKey: sectionKey.private.company.officers,
              },
              {
                title: 'Insurance Policies',
                to: formatPath(getRouteType.insurancePolicies, { id }),
                validationKey: sectionKey.private.company.insurancePolicies,
              },
            ],
          },
          {
            title: 'Organization',
            to: formatPath(getRouteType.organizationInformation, { id }),
            icon: AccountBalanceIcon,
            children: [
              {
                title: 'Organization Information',
                to: formatPath(getRouteType.organizationInformation, { id }),
                validationKey: sectionKey.private.company.organizationInformation,
              },
              {
                title: 'Services Performed',
                to: formatPath(getRouteType.services, { id }),
                validationKey: sectionKey.private.company.services,
              },
              {
                title: 'Work Categories',
                to: formatPath(getRouteType.workCategories, { id }),
                validationKey: sectionKey.private.company.workCategories,
              },
              {
                title: 'Financial Information',
                to: formatPath(getRouteType.financialInformation, { id }),
                validationKey: sectionKey.private.company.financialInformation,
              },
              {
                title: 'Legal',
                to: formatPath(getRouteType.legal, { id }),
                validationKey: sectionKey.private.company.legal,
              },
            ],
          },
          {
            title: 'Safety & Health Performance',
            to: formatPath(getRouteType.emr, { id }),
            icon: EqualizerIcon,
            children: [
              {
                title: 'Experience Modification Rate',
                to: formatPath(getRouteType.emr, { id }),
                validationKey: sectionKey.private.company.emr,
              },
              {
                title: 'Injury & Illness',
                to: formatPath(getRouteType.injuryIllness, { id }),
                validationKey: sectionKey.private.company.injuryIllness,
              },
            ],
          },
          {
            title: 'Safety, Health & Environmental Programs/Procedures',
            to: formatPath(getRouteType.envprog, { id }),
            icon: AssignmentIcon,
            children: [
              {
                title: 'Safety, Health & Environmental Program',
                to: formatPath(getRouteType.envprog, { id }),
                validationKey: sectionKey.private.company.envprog,
              },
            ],
          },
          {
            title: 'Skill Training, Assessment and Certifications',
            to: formatPath(getRouteType.craftsmanCertification, { id }),
            icon: CertificationIcon,
            children: [
              {
                title: 'Craftsman Certification',
                to: formatPath(getRouteType.craftsmanCertification, { id }),
                validationKey: sectionKey.private.company.craftsmanCertification,
              },
            ],
          },
          {
            title: 'Addendum',
            icon: AttachFileIcon,
            to: listAddendumCompany(formatPath(getRouteType.contractorAddendum, { id }))[0].to,
            children: listAddendumCompany(formatPath(getRouteType.contractorAddendum, { id })),
          },
          {
            title: 'Settings',
            to: formatPath(getRouteType.systemSettings, { id }),
            icon: SettingsIcon,
            children: adminPanel,
          },
        ],
      };
    }
    /*
          ASSUMES COMPANY IS NOT A BASIC CONTRACTOR
    */

    return {
      children: [
        {
          title: 'General Information',
          to: formatPath(getRouteType.settingsInfo, { id }),
          icon: GeneralIcon,
          children: [
            {
              title: 'Company Information',
              to: formatPath(getRouteType.settingsInfo, { id }),
              validationKey: sectionKey.private.company.settingsInfo,
            },
            {
              title: 'Officers',
              to: formatPath(getRouteType.officers, { id }),
              validationKey: sectionKey.private.company.officers,
            },
            {
              title: 'Insurance Policies',
              to: formatPath(getRouteType.insurancePolicies, { id }),
              validationKey: sectionKey.private.company.insurancePolicies,
            },
            {
              title: 'Parent Company',
              to: formatPath(getRouteType.parentCompany, { id }),
              validationKey: sectionKey.private.company.parentCompany,
            },
          ],
        },
        {
          title: 'Organization',
          to: formatPath(getRouteType.organizationInformation, { id }),
          icon: AccountBalanceIcon,
          children: [
            {
              title: 'Organization Information',
              to: formatPath(getRouteType.organizationInformation, { id }),
              validationKey: sectionKey.private.company.organizationInformation,
            },
            {
              title: 'Services Performed',
              to: formatPath(getRouteType.services, { id }),
              validationKey: sectionKey.private.company.services,
            },
            {
              title: 'Work Categories',
              to: formatPath(getRouteType.workCategories, { id }),
              validationKey: sectionKey.private.company.workCategories,
            },
            {
              title: 'Financial Information',
              to: formatPath(getRouteType.financialInformation, { id }),
              validationKey: sectionKey.private.company.financialInformation,
            },
            {
              title: 'References',
              to: formatPath(getRouteType.references, { id }),
              validationKey: sectionKey.private.company.references,
            },
            {
              title: 'Legal',
              to: formatPath(getRouteType.legal, { id }),
              validationKey: sectionKey.private.company.legal,
            },
          ],
        },
        {
          title: 'Safety & Health Performance',
          to: formatPath(getRouteType.emr, { id }),
          icon: EqualizerIcon,
          children: [
            {
              title: 'Experience Modification Rate',
              to: formatPath(getRouteType.emr, { id }),
              validationKey: sectionKey.private.company.emr,
            },
            {
              title: 'Injury & Illness',
              to: formatPath(getRouteType.injuryIllness, { id }),
              validationKey: sectionKey.private.company.injuryIllness,
            },
            {
              title: 'Citations',
              to: formatPath(getRouteType.citations, { id }),
              validationKey: sectionKey.private.company.citations,
            },
            {
              title: 'Safety, Health & Environmental Management',
              to: formatPath(getRouteType.environmentalManagement, { id }),
              validationKey: sectionKey.private.company.environmentalManagement,
            },
          ],
        },
        {
          title: 'Safety, Health & Environmental Programs/Procedures',
          to: formatPath(getRouteType.envprog, { id }),
          icon: AssignmentIcon,
          children: [
            {
              title: 'Safety, Health & Environmental Program',
              to: formatPath(getRouteType.envprog, { id }),
              validationKey: sectionKey.private.company.envprog,
            },
            {
              title: 'Hearing Conservation',
              to: formatPath(getRouteType.hearconserv, { id }),
              validationKey: sectionKey.private.company.hearconserv,
            },
            {
              title: 'Respiratory Protection',
              to: formatPath(getRouteType.respprot, { id }),
              validationKey: sectionKey.private.company.respprot,
            },
            {
              title: 'Hazard Communication',
              to: formatPath(getRouteType.hazardcomm, { id }),
              validationKey: sectionKey.private.company.hazardcomm,
            },
            {
              title: 'OSHA 29 CFR 1910',
              to: formatPath(getRouteType.osha, { id }),
              validationKey: sectionKey.private.company.osha,
            },
            {
              title: 'Spill Prevention and Waste Minimization',
              to: formatPath(getRouteType.spillwaste, { id }),
              validationKey: sectionKey.private.company.spillwaste,
            },
            {
              title: 'Substance Abuse Program',
              to: formatPath(getRouteType.substabuse, { id }),
              validationKey: sectionKey.private.company.substabuse,
            },
            {
              title: 'Employee English',
              to: formatPath(getRouteType.empeng, { id }),
              validationKey: sectionKey.private.company.empeng,
            },
            {
              title: 'Medical Program',
              to: formatPath(getRouteType.medprog, { id }),
              validationKey: sectionKey.private.company.medprog,
            },
            {
              title: 'Safety, Health and Environmental Meetings',
              to: formatPath(getRouteType.envmeet, { id }),
              validationKey: sectionKey.private.company.envmeet,
            },
            {
              title: 'Personal Protection Equipment',
              to: formatPath(getRouteType.protecteq, { id }),
              validationKey: sectionKey.private.company.protecteq,
            },
            {
              title: 'Corrective Action Process',
              to: formatPath(getRouteType.corrproc, { id }),
              validationKey: sectionKey.private.company.corrproc,
            },
            {
              title: 'Equipment and Materials',
              to: formatPath(getRouteType.equipmat, { id }),
              validationKey: sectionKey.private.company.equipmat,
            },
            {
              title: 'Subcontractor Program',
              to: formatPath(getRouteType.subprog, { id }),
              validationKey: sectionKey.private.company.subprog,
            },
            {
              title: 'Inspections and Audit Program',
              to: formatPath(getRouteType.inspprog, { id }),
              validationKey: sectionKey.private.company.inspprog,
            },
          ],
        },
        {
          title: 'Safety, Health & Environmental Training',
          to: formatPath(getRouteType.envtrain, { id }),
          validationKey: sectionKey.private.company.envtrain,
          icon: TrainingIcon,
        },
        {
          title: 'Safety, Health & Environmental Documentation',
          to: formatPath(getRouteType.envdoc, { id }),
          validationKey: sectionKey.private.company.envdoc,
          icon: DescriptionIcon,
        },
        {
          title: 'Skill Training, Assessment and Certifications',
          to: formatPath(getRouteType.craftsmanCertification, { id }),
          icon: CertificationIcon,
          children: [
            {
              title: 'Craftsman Certification',
              to: formatPath(getRouteType.craftsmanCertification, { id }),
              validationKey: sectionKey.private.company.craftsmanCertification,
            },
            {
              title: 'Workforce Development',
              to: formatPath(getRouteType.workfordev, { id }),
              validationKey: sectionKey.private.company.workfordev,
            },
          ],
        },
        {
          title: 'Addendum',
          icon: AttachFileIcon,
          to: listAddendumCompany(formatPath(getRouteType.contractorAddendum, { id }))[0].to,
          children: listAddendumCompany(formatPath(getRouteType.contractorAddendum, { id })),
        },
        {
          title: 'Settings',
          to: formatPath(getRouteType.systemSettings, { id }),
          icon: SettingsIcon,
          children: adminPanel,
        },
      ],
    };
  } else if (
    (type === userTypes.SafetyCouncil && companyType === userTypes.Owner) ||
    (type === userTypes.Owner && selfCompany)
  ) {
    const gradingRoutes = Object.keys(contractorLevels).map(level => ({
      title: `Grading for ${contractorLevels[level]}`,
      to: formatPath(getRouteType.questions, {
        id,
        [`contractorLevel(${Object.keys(contractorLevelPathEnum)
          .map(item => contractorLevelPathEnum[item])
          .join('|')})`]: contractorLevelPathEnum[level],
      }),
    }));

    const ownerAddendum = Object.keys(contractorLevels).map(level => ({
      title: `Addendum for ${contractorLevels[level]}`,
      to: formatPath(getRouteType.ownerAddendum, {
        id,
        [`contractorLevel(${Object.keys(contractorLevelPathEnum)
          .map(item => contractorLevelPathEnum[item])
          .join('|')})`]: contractorLevelPathEnum[level],
      }),
    }));

    return {
      children: [
        {
          title: 'General Information',
          to: formatPath(getRouteType.settingsInfo, { id }),
          icon: GeneralIcon,
          children: [
            {
              title: 'Company Information',
              to: formatPath(getRouteType.settingsInfo, { id }),
              validationKey: sectionKey.private.company.settingsInfo,
            },
          ],
        },
        {
          title: 'Addendum',
          to: ownerAddendum[0].to,
          icon: AttachFileIcon,
          children: ownerAddendum,
        },
        {
          title: 'Grading Questions',
          to: gradingRoutes[0].to,
          icon: ContactSupportIcon,
          children: gradingRoutes,
        },
        {
          title: 'Settings',
          to: formatPath(getRouteType.systemSettings, { id }),
          icon: SettingsIcon,
          children: adminPanel,
        },
      ],
    };
  } else {
    const gradingRoutes = Object.keys(contractorLevels).map(level => ({
      title: `Grading for ${contractorLevels[level]}`,
      to: formatPath(ownCompany.questions, {
        [`contractorLevel(${Object.keys(contractorLevelPathEnum)
          .map(item => contractorLevelPathEnum[item])
          .join('|')})`]: contractorLevelPathEnum[level],
      }),
    }));

    return {
      children: [
        {
          title: 'Users',
          to: ownCompany.users,
          icon: PeopleAltIcon,
        },
        {
          title: 'Grading Questions',
          to: gradingRoutes[0].to,
          icon: ContactSupportIcon,
          children: gradingRoutes,
        },
        {
          title: 'Archive',
          to: '',
          icon: ArchiveIcon,
          children: [
            {
              title: 'Companies',
              to: ownCompany.archiveCompany,
              validationKey: sectionKey.private.company.archiveCompany,
            },
            {
              title: 'Users',
              to: ownCompany.archiveUser,
              validationKey: sectionKey.private.company.archiveUser,
            },
          ],
        },
        {
          title: 'Settings',
          to: ownCompany.systemSettings,
          icon: SettingsIcon,
          children: [
            {
              title: 'System Settings',
              to: ownCompany.systemSettings,
            },
            {
              title: 'BLS Settings',
              to: ownCompany.bls,
            },
          ],
        },
      ],
    };
  }
};
