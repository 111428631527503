import { call, put, takeLatest } from 'redux-saga/effects';
import { normalize } from 'normalizr';

import { currentCompany, entity, file, notification } from '../../../actionTypes';
import dataProvider from '../../../store/dataProvider';
import { company as companySchema } from '../../../schema';
import { uploadHelpers } from '../../../utils/pageHelpers';

export function* get({ payload: { id } }) {
  try {
    const { data } = yield call(dataProvider.getOne, `Companies`, { id });

    const { uploads, ...companyData } = data;
    const { uploadKeys, uploadValues } = uploadHelpers(uploads);

    const normalizeData = normalize([{ ...companyData, ...uploadValues, uploadKeys }], [companySchema]);
    yield put({ type: entity.add, payload: normalizeData.entities });

    yield put({
      type: file.get.end,
      payload: Object.keys(uploads).reduce((acc, item) => ({ ...acc, [item]: uploads[item] }), {}),
    });

    yield put({
      type: currentCompany.info.get.end,
      payload: { state: normalizeData.result },
    });
  } catch (e) {
    yield put({
      type: currentCompany.info.get.end,
    });
    yield put({ type: notification.add, payload: { error: e.message } });
  }
}

export default function* getCompanyInfoSaga() {
  yield takeLatest(currentCompany.info.get.start, get);
}
