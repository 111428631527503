import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet';

import { currentCompany } from '../../../actionTypes';
import ParentCompanyForm from '../../containers/ParentCompanyForm';
import { title, sectionKey } from '../../routes/path';
import { formatPageTitle } from '../../../utils/textFormaters';
import { useCompanyInfo } from '../../../utils/effects/useCompanyInfo';
import { useUserPermissions } from '../../../utils/effects/userPermissions';

const ParentCompanyPage = () => {
  const dispatch = useDispatch();
  const { id } = useParams();

  const { companyId, companyName } = useCompanyInfo(id);
  const { allowUpdate } = useUserPermissions(companyId);

  const { loading, companyData, subsidiaries, success, error } = useSelector(
    state => state.currentCompany.parentCompany,
  );

  useEffect(() => {
    dispatch({ type: currentCompany.parentCompany.get.start, payload: { companyId } });
  }, [companyId, dispatch]);

  const onSubmit = (values, subsidiaries) => {
    dispatch({
      type: !values.id ? currentCompany.parentCompany.create.start : currentCompany.parentCompany.update.start,
      payload: { companyId, values, subsidiaries, sectionKey: sectionKey.private.company.parentCompany },
    });
  };

  return (
    <>
      <Helmet>
        <title>{formatPageTitle(title.private.company.parentCompany, { companyName })}</title>
      </Helmet>
      <ParentCompanyForm
        handleSubmit={onSubmit}
        loading={loading}
        companyData={companyData}
        subsidiaries={subsidiaries}
        success={success}
        error={error}
        allowUpdate={allowUpdate}
      />
    </>
  );
};

export default ParentCompanyPage;
