import React from 'react';
import { Formik, FastField } from 'formik';
import { Grid, Collapse } from '@material-ui/core';

import FormBody from '../../components/FormBody';
import LoadingButton from '../../components/LoadingButton';
import PageLoader from '../../components/PageLoader';
import FormCheckbox from '../../components/FormCheckbox';
import useStyles from './styles';
import PromptNotification from '../../components/PromptComponent';
import FormInput from '../../components/FormInput';
import { servicesPerformedSchema } from '../../../utils/validate/shemas';

const ServicesPerformedForm = ({
  services,
  others,
  companyServices,
  handleSubmit,
  loading,
  success,
  error,
  allowUpdate,
}) => {
  const classes = useStyles();

  if (!services.length) {
    return <PageLoader />;
  }

  const baseItems = {
    others: !!others,
    otherText: others || '',
  };

  const initialValues = services.reduce((acc, currentItem) => {
    return {
      ...acc,
      [`${currentItem.id}`]: companyServices.includes(currentItem.id),
    };
  }, baseItems);

  const onSubmit = values => {
    const checkedIds = Object.keys(values)
      .filter(key => values[key] && !Object.keys(baseItems).includes(key))
      .map(key => ({ id: parseInt(key) }));
    handleSubmit({ keys: checkedIds, othersText: values.others ? values.otherText : null });
  };
  return (
    <Formik
      initialValues={initialValues}
      enableReinitialize
      validationSchema={servicesPerformedSchema}
      onSubmit={onSubmit}
    >
      {props => (
        <>
          <PromptNotification formStatus={props.dirty} />
          <FormBody loading={loading} disabled={!allowUpdate}>
            <Grid item xs={12}>
              <Grid container>
                {services.map(service => (
                  <Grid item xs={12} key={service.id} className={classes.checkBoxItem}>
                    <FastField
                      name={`${service.id}`}
                      component={FormCheckbox}
                      label={service.name}
                      labelPlacement="end"
                    />
                  </Grid>
                ))}

                <Grid item xs={12} className={classes.checkBoxItem}>
                  <FastField name="others" component={FormCheckbox} label="Others" labelPlacement="end" />
                </Grid>
                <Collapse
                  in={props.values.others}
                  classes={{
                    wrapperInner: classes.collapseRowInner,
                    container: classes.collapseRow,
                  }}
                >
                  <Grid item xs={12}>
                    <FastField
                      name="otherText"
                      component={FormInput}
                      label="Others"
                      type="textarea"
                      rows={4}
                      multiline
                    />
                  </Grid>
                </Collapse>
              </Grid>
            </Grid>
            {allowUpdate && (
              <LoadingButton
                fixed
                disabled={!props.dirty || !props.isValid}
                onClick={props.handleSubmit}
                text="save"
                loading={loading}
                success={success}
                error={error}
              />
            )}
          </FormBody>
        </>
      )}
    </Formik>
  );
};

export default ServicesPerformedForm;
