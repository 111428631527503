import { call, put, takeEvery } from 'redux-saga/effects';

import { notification, review } from '../../actionTypes';
import dataProvider from '../../store/dataProvider';

export function* update({ payload: { companyId, pqfDocumentId, params } }) {
  try {
    const { data } = yield call(dataProvider.createList, `companies/${companyId}/pqf/${pqfDocumentId}/review`, [
      params,
    ]);

    yield put({
      type: review.update.end,
      payload: { params: data },
    });
  } catch (e) {
    yield put({
      type: review.update.error,
      payload: {
        params,
      },
    });
    yield put({ type: notification.add, payload: { error: e.message } });
  }
}

export default function* updateReviewSaga() {
  yield takeEvery(review.update.start, update);
}
