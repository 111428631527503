import { call, put, takeLatest } from 'redux-saga/effects';

import { pages, notification } from '../../../actionTypes';
import dataProvider from '../../../store/dataProvider';

export function* get({ payload: { companyId, companyName, scores } }) {
  try {
    yield call(dataProvider.createOne, `companies/${companyId}/grade/report?companyName=${companyName}`, scores);
    yield put({
      type: pages.dashboard.gradeDownload.end,
    });
  } catch (e) {
    yield put({ type: notification.add, payload: { error: e.message } });
    yield put({
      type: pages.dashboard.gradeDownload.error,
    });
  }
}

export default function* loadGradeDocumentSaga() {
  yield takeLatest(pages.dashboard.gradeDownload.start, get);
}
