import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  radio: isError => ({
    color: isError ? theme.palette.error.main : undefined,
    '&.Mui-checked': {
      color: theme.palette.primary.main,
    },
  }),
  label: isError => ({
    color: isError ? theme.palette.error.main : undefined,
    '& .MuiFormControlLabel-label': {
      marginLeft: theme.spacing(-0.6),
    },
  }),
}));

export default useStyles;
