import { put, call, takeLatest } from 'redux-saga/effects';

import dataProvider from '../../../store/dataProvider';
import headerNotification from '../../../actionTypes/headerNotification';
import { notification } from '../../../actionTypes';

export function* rejectFollow({ payload }) {
  const { companyId, senderCompanyId, notificationId } = payload;
  try {
    yield call(dataProvider.createOne, `companies/${companyId}/sharing/${senderCompanyId}/reject/${notificationId}`);
    yield put({ type: headerNotification.rejectFollow.end, payload });
  } catch (e) {
    yield put({ type: notification.add, payload: { error: e.message } });
  }
}

export default function* rejectFollowSaga() {
  yield takeLatest(headerNotification.rejectFollow.start, rejectFollow);
}
