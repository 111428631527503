import React from 'react';
import { useParams } from 'react-router-dom';
import MaterialTable from 'material-table';
import { utcToZonedTime } from 'date-fns-tz';
import { format } from 'date-fns';
import { Typography } from '@material-ui/core';

import CellStatus from './CellStatus';
import CellName from './CellName';
import CellScore from './CellScore';
import formatDate from '../../../utils/textFormaters/date';
import useStyles from './styles';
import { useCompanyInfo } from '../../../utils/effects/useCompanyInfo';
import timeZoneUSACentral from '../../../utils/constants/timeZoneUSACentral';

const DocumentList = ({ loading, list, handleLoad, filePqf = [], handleDownloadScoreCard, listLoadingScorecard }) => {
  const { id } = useParams();
  const { companyId } = useCompanyInfo(id);
  const classes = useStyles();

  const CellCompletedOn = ({ completedOn }) => {
    const completedTimeUTC = completedOn + '+0000';
    const zonedDate = completedOn && utcToZonedTime(completedTimeUTC, timeZoneUSACentral);
    const completedTime = format(new Date(zonedDate), "hh:mm aaaaa'm'");

    return (
      <>
        <Typography display="inline" variant="body1">
          {formatDate(completedOn)}
        </Typography>
        <span> {completedOn && `${completedTime} (CT)`}</span>
      </>
    );
  };

  const CellCompletedBy = ({ completedBy }) => (
    <Typography display="inline" variant="body1">
      {completedBy}
    </Typography>
  );

  const CellSubmittedBy = ({ submittedBy }) => (
    <Typography display="inline" variant="body1">
      {submittedBy}
    </Typography>
  );

  const columns = [
    {
      title: 'Name',
      field: 'name',
      width: '20%',
      render: ({ name, id, status }) => (
        <CellName status={status} name={name} id={id} filePqf={filePqf} handleLoad={handleLoad} companyId={companyId} />
      ),
    },
    {
      title: 'Completed',
      field: 'completedOn',
      width: '30%',
      render: ({ completedOn }) => <CellCompletedOn completedOn={completedOn} />,
    },
    {
      title: 'Submited By',
      field: 'submittedBy',
      width: '15%',
      render: ({ submittedBy }) => <CellSubmittedBy submittedBy={submittedBy} />,
    },
    {
      title: 'Completed By',
      field: 'completedBy',
      width: '15%',
      render: ({ completedBy }) => <CellCompletedBy completedBy={completedBy} />,
    },
    {
      title: 'Status',
      field: 'stateKey',
      width: '14%',
      render: ({ status, rejectReason, id }) => (
        <CellStatus id={id} status={status} message={rejectReason} companyId={companyId} />
      ),
    },
    {
      title: 'Grade',
      field: 'id',
      width: '6%',
      render: ({ id, status }) => (
        <CellScore
          status={status}
          loading={listLoadingScorecard.includes(id)}
          handleDownload={() => handleDownloadScoreCard(id)}
        />
      ),
    },
  ];

  return (
    <div className={classes.table}>
      <MaterialTable
        isLoading={loading}
        data={list}
        columns={columns}
        options={{
          sorting: false,
          search: false,
          editable: false,
          draggable: false,
          toolbar: false,
          paging: false,
        }}
        localization={{
          body: {
            emptyDataSourceMessage: 'No Records to Display',
          },
        }}
      />
    </div>
  );
};

export default DocumentList;
