import { call, put, takeLatest } from 'redux-saga/effects';

import { currentCompany, notification } from '../../../actionTypes';
import dataProvider from '../../../store/dataProvider';

export function* update({ payload: { companyId, value } }) {
  try {
    yield call(dataProvider.updateOnly, `companies/${companyId}/configuration`, value);

    yield put({
      type: currentCompany.settings.update.end,
      payload: value,
    });
  } catch (e) {
    yield put({ type: currentCompany.settings.update.error });
    yield put({ type: notification.add, payload: { error: e.message } });
  }
}

export default function* updateSettings() {
  yield takeLatest(currentCompany.settings.update.start, update);
}
