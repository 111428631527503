import { isAfter, isBefore, isValid, parseISO } from 'date-fns';

import refineNumberString from '../textFormaters/refineNumberString';

export function checkMinDate(date, error) {
  if (!isValid(date)) throw new Error('Min date value is not valid');
  return this.test('minDateCheckTest', error, function(value) {
    return value === null || isAfter(parseISO(value), date);
  });
}

export function checkMaxDate(date, error) {
  if (!isValid(date)) throw new Error('Max date value is not valid');
  return this.test('maxDateCheckTest', error, function(value) {
    return value === null || isBefore(parseISO(value), date);
  });
}

export function dateCheck(error) {
  return this.test('dateCheckTest', error, function(value) {
    return value === null || isValid(parseISO(value));
  });
}

export function compareNumbers(ref, message, type) {
  return this.test({
    name: 'compareNumbers',
    message,
    params: {
      reference: ref.path,
    },
    test: function(value) {
      const currentValue = value && refineNumberString(value);
      const refData = this.resolve(ref);
      const refValue = refData && refineNumberString(refData);
      const compare =
        type === 'greater'
          ? parseFloat(currentValue) >= parseFloat(refValue)
          : parseFloat(currentValue) <= parseFloat(refValue);
      return !currentValue || !refValue || compare;
    },
  });
}
