import { put, call, takeLatest } from 'redux-saga/effects';
import { normalize } from 'normalizr';

import { auth, entity } from '../../actionTypes';
import authProvider from '../../store/authProvider';
import { user as userSchema, company as companySchema } from '../../schema';

export function* check() {
  try {
    const { Id, FirstName, LastName, CompanyType, CompanyName, CompanyId, CompanyLevel } = yield call(
      authProvider.checkAuth,
    );
    const user = {
      id: Id,
      firstName: FirstName,
      lastName: LastName,
      companyType: CompanyType,
      companyName: CompanyName,
      companyId: CompanyId,
      level: CompanyLevel,
    };

    const company = {
      id: CompanyId,
      companyType: CompanyType,
      companyName: CompanyName,
      level: CompanyLevel,
    };

    // normalize data
    const normalizeData = normalize(user, userSchema);
    yield put({ type: entity.add, payload: normalizeData.entities });

    const companyNormalizeData = normalize(company, companySchema);
    yield put({ type: entity.add, payload: companyNormalizeData.entities });

    yield put({ type: auth.check.end, payload: { state: true, userId: Id } });
  } catch (e) {
    yield put({ type: auth.check.error, payload: { state: false, error: !!e && e.message } });
  }
}

export default function* checkAuthSaga() {
  yield takeLatest(auth.check.start, check);
}
