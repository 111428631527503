import React from 'react';
import { Grid, IconButton, CircularProgress } from '@material-ui/core';
import EventNoteIcon from '@material-ui/icons/EventNote';

import { pqfStatusesEnum } from '../../../../utils/constants';

const CellScore = ({ handleDownload, loading, status }) => {
  return (
    <Grid item xs={12}>
      {loading ? (
        <CircularProgress size={24} />
      ) : (
        status === pqfStatusesEnum.Completed && (
          <IconButton onClick={handleDownload} size="small">
            <EventNoteIcon />
          </IconButton>
        )
      )}
    </Grid>
  );
};

export default CellScore;
