import { colors } from '@material-ui/core';

export default {
  activeLink: {
    main: 'rgb(199, 228, 252)',
  },
  background: {
    transparent: 'transparent',
    backdrop: 'rgba(255,255,255,0.8)',
    error: colors.red.A100,
    success: colors.green.A100,
    warning: colors.orange.A400,
    primary: {
      main: '#043e59',
      ligth: '#36647a',
    },
  },
  labels: {
    error: '#ff8a80',
    success: '#d0e7b7',
  },
  text: {
    primary: colors.blueGrey[900],
    secondary: colors.blueGrey[600],
    link: colors.blue[600],
    inputLabel: colors.grey[500],
  },
};
