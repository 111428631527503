import { call, put, takeLatest } from 'redux-saga/effects';

import { currentCompany, notification } from '../../../actionTypes';
import dataProvider from '../../../store/dataProvider';

export function* get({ payload: { companyId, contractorLevel } }) {
  try {
    const { data } = yield call(dataProvider.getList, `companies/${companyId}/addendum`, {
      filter: { contractorLevel },
    });
    yield put({
      type: currentCompany.ownerAddendum.get.end,
      payload: { list: data },
    });
  } catch (e) {
    yield put({ type: notification.add, payload: { error: e.message } });
    yield put({ type: currentCompany.ownerAddendum.get.error });
  }
}

export default function* getAddendumSaga() {
  yield takeLatest(currentCompany.ownerAddendum.get.start, get);
}
