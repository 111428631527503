import { makeStyles } from '@material-ui/core/styles';

const dashboardStyles = makeStyles(theme => ({
  cardWrapper: {
    maxHeight: theme.spacing(40),
    overflowX: 'hidden',
    overflowY: 'auto',
    scrollbarColor: '#f0f0f0 transparent',
    scrollbarWidth: 'thin',
    '&::-webkit-scrollbar-track-piece': {
      backgroundColor: theme.palette.common.white,
    },
    '&::-webkit-scrollbar': {
      width: theme.spacing(0.4),
    },
    '&::-webkit-scrollbar-button': {
      backgroundColor: theme.palette.common.white,
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: theme.palette.grey['200'],
    },
  },
}));

export default dashboardStyles;
