import React from 'react';

import useStyles from './styles';

const Grade = ({ grade, ownerGrade, handleClick }) => {
  const classes = useStyles({ ownerGrade });
  return (
    <div className={classes.container} onClick={handleClick}>
      <div className={classes.title}> {ownerGrade ? 'own grade' : 'grade'} </div>
      {grade === null ? (
        <div className={`${classes.body} ${classes.defaultBody}`}>
          <span>TBD</span>
        </div>
      ) : (
        <div className={classes.body}>
          <span>{grade}</span>
        </div>
      )}
      <div className={classes.textAfter}>View Scorecard</div>
    </div>
  );
};

export default Grade;
