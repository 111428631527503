import React, { useState, useRef } from 'react';
import { Avatar, Button, Popper, Paper, Grow, ClickAwayListener, MenuItem, MenuList, Grid } from '@material-ui/core';
import PersonIcon from '@material-ui/icons/Person';
import Badge from '@material-ui/core/Badge';
import NotificationsIcon from '@material-ui/icons/Notifications';
import { useDispatch, useSelector } from 'react-redux';

import useStyles from './styles';
import { EmptyNotifications } from '../../containers/Header/Notifications';
import { headerNotification } from '../../../actionTypes';
import { notificationStatus } from '../../containers/Header/Notifications/headerNotificationTypes';
import NotificationsPopper from '../NotificationsPopper';
import requestComponentType from './requestComponentType';
import { useUserPermissions } from '../../../utils/effects/userPermissions';

const UserLabel = ({ firstName = '', lastName = '', avatar, handleLogout, headerNotifications }) => {
  const { items: notificationsList } = headerNotifications;
  const classes = useStyles();
  const dispatch = useDispatch();

  const anchorRef = useRef(null);
  const anchorBadge = useRef(null);
  const { loading, loadingNotificationId } = useSelector(store => store.headerNotifications);
  const userId = useSelector(state => state.auth.check.userId);
  const companyId = useSelector(state => state.entity.user[userId].companyId);
  const { type } = useUserPermissions();

  const [open, setOpen] = useState(false);
  const [openNotifications, setOpenNotifications] = useState(false);

  const notificationsListLength = notificationsList.filter(
    item => !item.userAction || item.userAction !== notificationStatus.dismissedSuccess,
  ).length;

  const dismissHandler = (companyId, notificationId) =>
    dispatch({ type: headerNotification.delete.start, payload: { companyId, notificationId } });

  const renderNotificationItem = (item, sharedInfoCompanyId, loading, dismissHandler) => {
    let RequestComponent;
    if (item.typeKey) RequestComponent = requestComponentType[item.typeKey];
    return item.typeKey ? (
      <RequestComponent
        item={item}
        companyId={companyId}
        loading={loading}
        userType={type}
        dismissHandler={dismissHandler}
      />
    ) : (
      <EmptyNotifications />
    );
  };

  return (
    <Grid item xs={12}>
      <Grid container spacing={2} alignItems="center" justify="flex-end">
        <Grid item className={classes.container}>
          <Badge
            color="error"
            badgeContent={notificationsListLength}
            className={classes.notificationIcon}
            onClick={() => setOpenNotifications(!openNotifications)}
            ref={anchorBadge}
          >
            <NotificationsIcon />
          </Badge>
          <NotificationsPopper
            anchorEl={anchorBadge.current}
            openNotifications={openNotifications}
            setOpenNotifications={setOpenNotifications}
            notificationsListLength={notificationsListLength}
            notificationsList={notificationsList}
            loading={loading}
            loadingNotificationId={loadingNotificationId}
            renderNotificationItem={renderNotificationItem}
            companyId={companyId}
            dismissHandler={dismissHandler}
          />
        </Grid>
        <Grid item className={classes.container}>
          <Button
            size="small"
            fullWidth
            className={classes.containerLabel}
            ref={anchorRef}
            onClick={() => setOpen(!open)}
            aria-haspopup="true"
            disableRipple
          >
            <span className={classes.labelText}>Welcome {`${firstName} ${lastName}`}</span>
            {avatar ? (
              <Avatar alt={`${firstName} ${lastName}`} src={avatar} className={classes.avatar} />
            ) : (
              <Avatar className={classes.icon}>
                <PersonIcon />
              </Avatar>
            )}
          </Button>
          <Popper
            className={classes.fullSize}
            open={open}
            anchorEl={anchorRef.current}
            transition
            disablePortal
            placement="bottom-end"
          >
            {({ TransitionProps }) => (
              <Grow {...TransitionProps} style={{ transformOrigin: 'right top' }}>
                <Paper>
                  <ClickAwayListener onClickAway={() => setOpen(false)}>
                    <MenuList autoFocusItem={open}>
                      <MenuItem onClick={handleLogout}>Logout</MenuItem>
                    </MenuList>
                  </ClickAwayListener>
                </Paper>
              </Grow>
            )}
          </Popper>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default UserLabel;
