import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  deepOffset: ({ deep }) => ({
    padding: theme.spacing(0, 0, 0, 1 * deep),
  }),
  parentContainer: ({ offsetTop }) => ({
    paddingTop: theme.spacing(offsetTop ? 1 : 0),
    paddingBottom: theme.spacing(1),
  }),
}));

export default useStyles;
