import React from 'react';
import { Grid, Typography, Link as MaterialLink, Button } from '@material-ui/core';

import formatPath from '../../../../utils/textFormaters/formatPath';
import routes from '../../../routes/path';
import useStyles from './styles';

// Notification for Owner

export const SharingRequestApproved = ({ item, companyId, dismissHandler }) => {
  const classes = useStyles();

  const { title: senderCompanyName, id: senderCompanyId } = item.notificationEntities.RecipientCompany[0];
  const contractorAddendum = item.notificationEntities.ContractorAddendum;
  const senderCompanyLink = formatPath(routes.private.company.id, { id: senderCompanyId });

  return (
    <>
      <Typography variant="h5" gutterBottom>
        Sharing Request
      </Typography>
      <MaterialLink href={senderCompanyLink}>{senderCompanyName}</MaterialLink>
      <span> approved follow request. </span>
      <span>See Addendum: </span>
      {contractorAddendum &&
        contractorAddendum.map(item => (
          <MaterialLink key={item.ownerAddendumId} href={senderCompanyLink}>
            {item.title + ' '}
          </MaterialLink>
        ))}
      <Grid container justify="center" alignItems="flex-end" className={classes.buttonsWrapper}>
        <Button variant="contained" size="small" onClick={() => dismissHandler(companyId, item.id)}>
          Dismiss
        </Button>
      </Grid>
    </>
  );
};
