import React, { useState } from 'react';
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Tooltip,
  Typography,
} from '@material-ui/core';
import CheckIcon from '@material-ui/icons/Check';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import { FastField, Formik } from 'formik';

import FormInput from '../../../components/FormInput';
import { reviewStatuses } from '../../../../utils/constants';
import { rejectReasonsSchema } from '../../../../utils/validate/shemas';
import useStyles from './style';

const StatusChecker = ({ id, status, comment, editable, updateList, handleUpdate }) => {
  const classes = useStyles({ approve: status === reviewStatuses.approve, reject: status === reviewStatuses.reject });
  const [open, setOpen] = useState(false);

  const Wrapper = ({ children }) =>
    comment ? (
      <Tooltip
        arrow
        leaveDelay={200}
        placement="bottom"
        classes={{ tooltip: classes.tooltip, arrow: classes.tooltipArrow }}
        title={<Typography variant="body1">{comment}</Typography>}
      >
        {children}
      </Tooltip>
    ) : (
      children
    );

  const handleApprove = () => editable && handleUpdate({ id, status: reviewStatuses.approve, comment: '' });

  const handleReject = ({ comment }) => {
    handleUpdate({
      id,
      status: reviewStatuses.reject,
      comment,
    });
    setOpen(false);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Grid item xs={12}>
      <Grid container justify="center" alignItems="center">
        {updateList.includes(id) ? (
          <CircularProgress size={22} />
        ) : (
          <>
            <IconButton size="small" onClick={handleApprove}>
              <CheckIcon className={classes.approve} />
            </IconButton>
            <Wrapper>
              <IconButton size="small" onClick={() => editable && setOpen(true)}>
                <HighlightOffIcon className={classes.reject} />
              </IconButton>
            </Wrapper>
          </>
        )}
      </Grid>
      <Formik initialValues={{ comment }} onSubmit={handleReject} validationSchema={rejectReasonsSchema}>
        {({ handleSubmit }) => (
          <Dialog open={open} onClose={handleClose} classes={{ paper: classes.dialogContainer }}>
            <DialogTitle> State reject reason </DialogTitle>
            <DialogContent>
              <FastField name="comment" component={FormInput} type="textarea" rows={4} multiline />
            </DialogContent>
            <DialogActions
              classes={{
                root: classes.dialogActions,
              }}
            >
              <Button autoFocus onClick={handleSubmit} color="primary" variant="contained" size="small">
                Save
              </Button>
              <Button onClick={handleClose} variant="contained" size="small" autoFocus>
                Cancel
              </Button>
            </DialogActions>
          </Dialog>
        )}
      </Formik>
    </Grid>
  );
};

export default StatusChecker;
