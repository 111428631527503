import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet';

import { title, sectionKey } from '../../routes/path';
import InjuryIllnessForm from '../../containers/InjuryIllnessForm';
import { currentCompany } from '../../../actionTypes';
import { formatPageTitle } from '../../../utils/textFormaters';
import { useCompanyInfo } from '../../../utils/effects/useCompanyInfo';

const InjuryIllness = () => {
  const dispatch = useDispatch();
  const { id } = useParams();

  const { companyId, companyName } = useCompanyInfo(id);

  const { loading, success, error, stack } = useSelector(store => store.currentCompany.injuryIllness);

  useEffect(() => {
    dispatch({ type: currentCompany.injuryIllness.get.start, payload: { companyId } });
  }, [companyId, dispatch]);

  const handleUpdate = values => {
    dispatch({
      type: currentCompany.injuryIllness.update.start,
      payload: { companyId, values, sectionKey: sectionKey.private.company.injuryIllness },
    });
  };
  return (
    <>
      <Helmet>
        <title> {formatPageTitle(title.private.company.injuryIllness, { companyName })}</title>
      </Helmet>
      <InjuryIllnessForm
        companyId={companyId}
        loading={loading}
        success={success}
        error={error}
        stack={stack}
        handleUpdate={handleUpdate}
      />
    </>
  );
};

export default InjuryIllness;
