import { call, put, takeLatest } from 'redux-saga/effects';

import { currentCompany, notification } from '../../../actionTypes';
import dataProvider from '../../../store/dataProvider';
import { sanitizePhoneNumbers } from '../../../utils/textFormaters/sanitizePhoneNumbers';

export function* update({ payload: { companyId, values, itemId, sectionKey } }) {
  try {
    const transformedValues = sanitizePhoneNumbers(values);
    const { data } = yield call(dataProvider.updateOne, `companies/${companyId}/references`, transformedValues);
    yield put({ type: currentCompany.reference.update.end, payload: { values: data, itemId } });

    // update PQF document validation
    yield put({
      type: currentCompany.companyInfo.validation.start,
      payload: { companyId: companyId, validationKey: sectionKey },
    });
  } catch (e) {
    yield put({ type: notification.add, payload: { error: e.message } });
    yield put({ type: currentCompany.reference.update.error });
  }
}

export default function* updateReferenceSaga() {
  yield takeLatest(currentCompany.reference.update.start, update);
}
