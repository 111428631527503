import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';

import CompanyUserList from '../../../pages/CompanyUserList';
import CompanyOfficers from '../../../pages/CompanyOfficers';
import CompanyInfo from '../../../pages/CompanyInfo';
import FinancialInformation from '../../../pages/FinancialInformation';
import ParentCompany from '../../../pages/ParentCompany';
import InsurancePolicy from '../../../pages/InsurancePolicy';
import OrganizationInfo from '../../../pages/OrganizationInfo';
import WorkCategories from '../../../pages/WorkCategories';
import ServicesPerformed from '../../../pages/ServicesPerformed';
import References from '../../../pages/References';
import CreateEnvironmentalManagement from '../../../pages/CreateEnvironmentalManagement';
import OwnerAddendum from '../../../pages/OwnerAddendum';
import InjuryIllness from '../../../pages/InjuryIllness';
import ContractorAddendum from '../../../pages/ContractorAddendum';
import YesNoQuestionDefault from '../../../pages/YesNoQuestionDefault';
import EMRatePage from '../../../pages/EMR';
import CraftsmanCertification from '../../../pages/CraftsmanCertification';
import Questions from '../../../pages/Questions';
import SystemSettings from '../../../pages/SystemSettings';
import BLSSettings from '../../../pages/BLSSettings';
import ArchiveCompany from '../../../pages/ArchiveCompany';
import ArchiveUser from '../../../pages/ArchiveUser';
import CompanySettings from '../../../pages/CompanySettings';
import { userTypes } from '../../../../utils/constants';

const SettingRoutes = ({ role, company, isBasicContractor }) => {
  if (role === userTypes.SafetyCouncil)
    return (
      <Switch>
        <Route path={company.users} exact component={CompanyUserList} />
        <Route path={company.archiveCompany} exact component={ArchiveCompany} />
        <Route path={company.archiveUser} exact component={ArchiveUser} />
        <Route path={company.systemSettings} exact component={SystemSettings} />
        <Route path={company.questions} exact component={Questions} />
        <Route path={company.bls} exact component={BLSSettings} />
        <Redirect to="/404" />
      </Switch>
    );

  if (role === userTypes.Owner)
    return (
      <Switch>
        <Route path={company.users} exact component={CompanyUserList} />
        <Route path={company.settingsInfo} exact component={CompanyInfo} />
        <Route path={company.ownerAddendum} exact component={OwnerAddendum} />
        <Route path={company.systemSettings} exact component={CompanySettings} />
        <Route path={company.questions} exact component={Questions} />
        <Redirect to="/404" />
      </Switch>
    );

  if (isBasicContractor)
    return (
      <Switch>
        <Route path={company.users} exact component={CompanyUserList} />
        <Route path={company.settingsInfo} exact component={CompanyInfo} />
        <Route path={company.officers} exact component={CompanyOfficers} />
        <Route path={company.financialInformation} exact component={FinancialInformation} />
        <Route path={company.insurancePolicies} exact component={InsurancePolicy} />
        <Route path={company.organizationInformation} exact component={OrganizationInfo} />
        <Route path={company.workCategories} exact component={WorkCategories} />
        <Route path={company.services} exact component={ServicesPerformed} />
        <Route path={company.ownerAddendum} exact component={OwnerAddendum} />
        <Route path={company.contractorAddendum} exact component={ContractorAddendum} />
        <Route path={company.emr} exact component={EMRatePage} />
        <Route path={company.craftsmanCertification} exact component={CraftsmanCertification} />
        <Route path={company.systemSettings} exact component={CompanySettings} />
        <Route path={company.injuryIllness} exact component={InjuryIllness} />

        {/* YesNoQuestion block */}
        <Route path={company.legal} exact render={() => <YesNoQuestionDefault sectionKey="legal" />} />
        <Route path={company.envprog} exact render={() => <YesNoQuestionDefault sectionKey="envprog" />} />
        <Redirect to="/404" />
      </Switch>
    );

  return (
    <Switch>
      <Route path={company.users} exact component={CompanyUserList} />
      <Route path={company.settingsInfo} exact component={CompanyInfo} />
      <Route path={company.officers} exact component={CompanyOfficers} />
      <Route path={company.financialInformation} exact component={FinancialInformation} />
      <Route path={company.parentCompany} exact component={ParentCompany} />
      <Route path={company.insurancePolicies} exact component={InsurancePolicy} />
      <Route path={company.organizationInformation} exact component={OrganizationInfo} />
      <Route path={company.workCategories} exact component={WorkCategories} />
      <Route path={company.services} exact component={ServicesPerformed} />
      <Route path={company.references} exact component={References} />
      <Route path={company.environmentalManagement} exact component={CreateEnvironmentalManagement} />
      <Route path={company.ownerAddendum} exact component={OwnerAddendum} />
      <Route path={company.contractorAddendum} exact component={ContractorAddendum} />
      <Route path={company.injuryIllness} exact component={InjuryIllness} />
      <Route path={company.emr} exact component={EMRatePage} />
      <Route path={company.craftsmanCertification} exact component={CraftsmanCertification} />
      <Route path={company.systemSettings} exact component={CompanySettings} />

      {/* YesNoQuestion block */}
      <Route path={company.legal} exact render={() => <YesNoQuestionDefault sectionKey="legal" />} />
      <Route path={company.citations} exact render={() => <YesNoQuestionDefault sectionKey="citations" />} />
      <Route path={company.envprog} exact render={() => <YesNoQuestionDefault sectionKey="envprog" />} />
      <Route path={company.hearconserv} exact render={() => <YesNoQuestionDefault sectionKey="hearconserv" />} />
      <Route path={company.respprot} exact render={() => <YesNoQuestionDefault sectionKey="respprot" />} />
      <Route path={company.hazardcomm} exact render={() => <YesNoQuestionDefault sectionKey="hazardcomm" />} />
      <Route path={company.osha} exact render={() => <YesNoQuestionDefault sectionKey="osha" />} />
      <Route path={company.spillwaste} exact render={() => <YesNoQuestionDefault sectionKey="spillwaste" />} />
      <Route path={company.substabuse} exact render={() => <YesNoQuestionDefault sectionKey="substabuse" />} />
      <Route path={company.empeng} exact render={() => <YesNoQuestionDefault sectionKey="empeng" />} />
      <Route path={company.envmeet} exact render={() => <YesNoQuestionDefault sectionKey="envmeet" />} />
      <Route path={company.protecteq} exact render={() => <YesNoQuestionDefault sectionKey="protecteq" />} />
      <Route path={company.corrproc} exact render={() => <YesNoQuestionDefault sectionKey="corrproc" />} />
      <Route path={company.equipmat} exact render={() => <YesNoQuestionDefault sectionKey="equipmat" />} />
      <Route path={company.subprog} exact render={() => <YesNoQuestionDefault sectionKey="subprog" />} />
      <Route path={company.inspprog} exact render={() => <YesNoQuestionDefault sectionKey="inspprog" />} />
      <Route path={company.envtrain} exact render={() => <YesNoQuestionDefault sectionKey="envtrain" />} />
      <Route path={company.workfordev} exact render={() => <YesNoQuestionDefault sectionKey="workfordev" />} />
      <Route path={company.envdoc} exact render={() => <YesNoQuestionDefault sectionKey="envdoc" />} />

      {/* Pages use YesNoQuestion as a part */}
      <Route path={company.medprog} exact render={() => <YesNoQuestionDefault sectionKey="medprog" />} />

      <Redirect to="/404" />
    </Switch>
  );
};

export default SettingRoutes;
