import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet';

import { title, sectionKey } from '../../routes/path';
import CreateFinancialInfoForm from '../../containers/CreateFinancialInfoForm';
import { currentCompany } from '../../../actionTypes';
import { formatPageTitle } from '../../../utils/textFormaters';
import { changeEmptyStringToNull, removeDollarCommaSigns } from '../../../utils/containersHelpers';
import { useCompanyInfo } from '../../../utils/effects/useCompanyInfo';
import { useUserPermissions } from '../../../utils/effects/userPermissions';

const valuesDollarAmount = ['minProjectSize', 'maxProjectSize', 'bankLineOfCredit', 'bondingCapacity', 'netWorth'];

const FinancialInformation = () => {
  const dispatch = useDispatch();
  const { id } = useParams();

  const { companyId, companyName } = useCompanyInfo(id);
  const { allowUpdate } = useUserPermissions(companyId);

  const { financialInfo, financialArrays, loading, requestInProgress, success, error } = useSelector(
    store => store.currentCompany.finInfo,
  );

  useEffect(() => {
    dispatch({ type: currentCompany.finInfo.get.start, companyId });
  }, [companyId, dispatch]);

  const handleSubmit = values => {
    const newValues = changeEmptyStringToNull(values.financialInfo);
    const formattedValues = removeDollarCommaSigns(newValues, valuesDollarAmount);

    dispatch({
      type: currentCompany.finInfo.update.start,
      payload: {
        companyId,
        financialInfo: { ...formattedValues, id: financialInfo.id },
        financialArrays: values.financialArrays,
        sectionKey: sectionKey.private.company.financialInformation,
      },
    });
  };

  return (
    <>
      <Helmet>
        <title> {formatPageTitle(title.private.company.financialInformation, { companyName })}</title>
      </Helmet>
      <CreateFinancialInfoForm
        financialInfo={financialInfo}
        financialArrays={financialArrays}
        handleSubmit={handleSubmit}
        valuesDollarAmount={valuesDollarAmount}
        loading={loading}
        requestInProgress={requestInProgress}
        companyId={companyId}
        success={success}
        error={error}
        allowUpdate={allowUpdate}
      />
    </>
  );
};

export default FinancialInformation;
