import { put, call, takeLatest } from 'redux-saga/effects';

import { currentCompany, notification } from '../../../actionTypes';
import dataProvider from '../../../store/dataProvider';
import { successMessages } from '../../../store/constants';
import { formatPath } from '../../../utils/textFormaters';
import routes from '../../../ui/routes/path';

export function* resend({ payload: { id } }) {
  try {
    yield call(dataProvider.createOne, `Users/${id}/invite`, {
      redirectPath: `${window.location.origin}${formatPath(routes.public.invitationAccept, { token: '' })}`,
    });
    yield put({ type: currentCompany.user.resend.end });
    yield put({ type: notification.add, payload: { success: successMessages.resendUserInvitation } });
  } catch (e) {
    yield put({ type: notification.add, payload: { error: e.message } });
    yield put({ type: currentCompany.user.resend.end });
  }
}

export default function* resendUserSaga() {
  yield takeLatest(currentCompany.user.resend.start, resend);
}
