import * as Yup from 'yup';

import messages from '../../../utils/validate/errorMessages';

export const validationSchema = Yup.lazy(value => {
  return Yup.object().shape({
    fields: Yup.object().shape(
      Object.keys(value.fields).reduce(
        (acc, item) => ({
          ...acc,
          [item]: Yup.object().shape({
            text: Yup.string()
              .min(3, messages.requiredQuestionMin3)
              .max(255, messages.requiredQuestionTitleMax255)
              .required(messages.required),
            fileId: Yup.mixed()
              .when('type', {
                is: type => type === 'Link',
                then: schema => schema.required(messages.required),
              }),
            options: Yup.array()
              .of(
                Yup.object().shape({
                  text: Yup.string()
                    .min(3, messages.requiredQuestionOptionMin3)
                    .max(255, messages.requiredQuestionOptionMax255)
                    .required(messages.required),
                }),
              )
              .when('type', {
                is: type => type === 'Dropdown',
                then: schema => schema.min(1, messages.required),
              }),
          }),
        }),
        {},
      ),
    ),
  });
});
