import { put, call, takeLatest } from 'redux-saga/effects';
import { normalize } from 'normalizr';

import { currentCompany, entity, notification } from '../../../actionTypes';
import dataProvider from '../../../store/dataProvider';
import { successMessages } from '../../../store/constants';
import { companyUser as companyUserSchema } from '../../../schema';
import { sanitizePhoneNumbers } from '../../../utils/textFormaters/sanitizePhoneNumbers';
import { formatPath } from '../../../utils/textFormaters';
import routes from '../../../ui/routes/path';

export function* create({ payload: { values, companyId } }) {
  try {
    const transformedValues = sanitizePhoneNumbers(values);
    const { data } = yield call(dataProvider.createOne, `Companies/${companyId}/Users`, {
      ...transformedValues,
      redirectPath: `${window.location.origin}${formatPath(routes.public.invitationAccept, { token: '' })}`,
    });
    yield put({
      type: currentCompany.user.create.end,
      payload: {
        state: data,
      },
    });
    const normalizeData = normalize(data, [companyUserSchema]);
    yield put({ type: entity.add, payload: normalizeData.entities });

    if (data.isInvitationSent) {
      yield put({ type: notification.add, payload: { success: successMessages.companyUserInvite } });
    }
  } catch (e) {
    yield put({ type: notification.add, payload: { error: e.message } });
    yield put({ type: currentCompany.user.create.error });
  }
}

export default function* createUserSaga() {
  yield takeLatest(currentCompany.user.create.start, create);
}
