import { createEnum } from '../actionTypesHelpers';

export const bussinessFormTypes = createEnum({
  'S-Corp': 'SCorp',
  'C-Corp': 'CCorp',
  Partnership: 'Partnership',
  'Limited Liability Company': 'LimitedLiabilityCompany',
});

export default Object.keys(bussinessFormTypes).map(key => ({
  label: key,
  value: bussinessFormTypes[key],
}));
