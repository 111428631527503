import { call, put, takeLatest } from 'redux-saga/effects';

import { workingHourNotes } from '../../actionTypes';
import dataProvider from '../../store/dataProvider';

export function* update({ payload: { values, contractorId, ownerId } }) {
  try {
    const { data } = yield call(dataProvider.createOne, `companies/${contractorId}/working-hour-note/${ownerId}`, values);

    yield put({
      type: workingHourNotes.update.end,
      payload: data,
    });
  } catch (e) {
    yield put({ type: workingHourNotes.update.error });
  }
}

export default function* updateWorkingHourNotes() {
  yield takeLatest(workingHourNotes.update.start, update);
}
