import React from 'react';
import { ClickAwayListener, Grow, MenuItem, MenuList, Paper, Popper } from '@material-ui/core';

import { notificationStatus } from '../../containers/Header/Notifications/headerNotificationTypes';
import PageLoader from '../PageLoader';
import { EmptyNotifications } from '../../containers/Header/Notifications';
import useStyles from './styles';

const NotificationsPopper = ({
  anchorEl,
  openNotifications,
  setOpenNotifications,
  notificationsListLength,
  notificationsList,
  loading,
  loadingNotificationId,
  renderNotificationItem,
  companyId,
  dismissHandler,
}) => {
  const classes = useStyles();

  return (
    <Popper
      className={classes.notificationPopper}
      open={openNotifications}
      anchorEl={anchorEl}
      transition
      disablePortal
      placement="bottom"
    >
      {({ TransitionProps }) => (
        <Grow {...TransitionProps} style={{ transformOrigin: 'top start' }}>
          <Paper className={classes.notificationContent}>
            <ClickAwayListener onClickAway={() => setOpenNotifications(false)}>
              <MenuList autoFocusItem={openNotifications}>
                {notificationsListLength ? (
                  notificationsList.map(item => {
                    if (item.userAction && item.userAction === notificationStatus.dismissedSuccess) return null;
                    if (loading && loadingNotificationId === item.id) return <PageLoader key={item.id} />;
                    return (
                      <MenuItem key={item.id} className={classes.notificationItem}>
                        {renderNotificationItem(item, companyId, loading, dismissHandler)}
                      </MenuItem>
                    );
                  })
                ) : (
                  <MenuItem>
                    <EmptyNotifications />
                  </MenuItem>
                )}
              </MenuList>
            </ClickAwayListener>
          </Paper>
        </Grow>
      )}
    </Popper>
  );
};

export default NotificationsPopper;
