import React from 'react';
import { Typography, Link } from '@material-ui/core';

import { chromeDownloadLink } from '../../../utils/constants/chromeDownloadLink';
import useStyles from './styles';

const BrowserSupportMessage = () => {
  const classes = useStyles();

  const isChrome = navigator.userAgent.indexOf('Chrome') > -1;

  return (
    !isChrome && (
      <Typography variant="subtitle1" className={classes.typography}>
        The system works best with Chrome.
        <Link href={chromeDownloadLink} target="_blank">
          Download Chrome
        </Link>
      </Typography>
    )
  );
};

export default BrowserSupportMessage;
