import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  inputRow: {
    margin: theme.spacing(1, 0),
  },
  tableWrapper: {
    marginBottom: theme.spacing(4),
    '&>table tr td:last-child': {
      width: theme.spacing(15),
    },
  },
}));

export default useStyles;
