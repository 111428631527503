import React, { useState } from 'react';
import { Grid, Typography } from '@material-ui/core';
import isEqual from 'lodash.isequal';

import { finInfoTableSchema } from '../../../utils/validate/shemas';
import FormNumberInput from '../../components/FormNumberInput';
import LoadingButton from '../../components/LoadingButton';
import PageLoader from '../../components/PageLoader';
import FormBody from '../../components/FormBody';
import { changeNullToEmptyString, addDollarCommaSigns } from '../../../utils/containersHelpers';
import useStyles from './styles';
import CommonTableForm from '../CommonTable';
import FinInfoSubForm from './FinInfoSubForm';
import refineNumberString from '../../../utils/textFormaters/refineNumberString';
import PromptNotification from '../../components/PromptComponent';
import { getLastYearsOptions } from '../../../utils/constants/yearHelper';

const CreateFinancialInfoForm = ({
  handleSubmit,
  financialInfo,
  financialArrays,
  loading,
  success,
  error,
  requestInProgress,
  allowUpdate,
  companyId,
}) => {
  const [status, setStatus] = useState({
    form: {
      values: {},
      dirty: false,
      isValid: false,
    },
    table: {
      values: [],
      changed: false,
    },
  });

  const classes = useStyles();
  const initialValues = changeNullToEmptyString(financialInfo);

  const handleSubmitClick = () => {
    const valuesToSend = {
      financialInfo: status.form.values,
      financialArrays: status.table.values.map(item => ({
        id: item.saveId,
        year: item.year,
        amount: parseFloat(refineNumberString(item.amount)),
      })),
    };
    handleSubmit(valuesToSend);

    setStatus({
      form: {
        ...status.form,
        dirty: false,
      },
      table: {
        ...status.table,
        changed: false,
      },
    });
  };

  if (loading && !Object.keys(initialValues).length) return <PageLoader />;

  const canSubmit = (status.form.isValid && status.form.dirty) || (status.form.isValid && status.table.changed);

  const tableData = financialArrays.map(item => ({
    ...item,
    saveId: item.id,
    id: item.year,
    amount: addDollarCommaSigns(item.amount),
  }));

  return (
    <>
      <PromptNotification formStatus={status.form.dirty || status.table.changed} />
      <FormBody loading={loading} disabled={!allowUpdate}>
        <Grid item lg={12}>
          <FinInfoSubForm
            companyId={companyId}
            initialValues={initialValues}
            handleSubmit={handleSubmitClick}
            formUpdate={(values, dirty, isValid) =>
              setStatus({
                ...status,
                form: {
                  values,
                  isValid,
                  dirty,
                },
              })
            }
          />
          <Grid container>
            <Grid item lg={12} className={classes.innerWrapper}>
              <Typography variant="h5">Annual Dollar Volume</Typography>
              <CommonTableForm
                tableData={tableData}
                setTableData={values =>
                  setStatus({
                    ...status,
                    table: {
                      values,
                      changed: !isEqual(values, tableData),
                    },
                  })
                }
                label="Amount"
                initialTableValues={{ year: '', amount: '' }}
                fieldName="amount"
                maskType="dollarNumber"
                fieldComponent={FormNumberInput}
                allowUpdate={allowUpdate}
                loading={loading}
                validationSchema={finInfoTableSchema}
                getYearsOptions={getLastYearsOptions}
              />
            </Grid>
          </Grid>
        </Grid>
        {allowUpdate && (
          <LoadingButton
            fixed
            disabled={!canSubmit}
            success={success}
            error={error}
            loading={requestInProgress}
            onClick={handleSubmitClick}
            text="save"
          />
        )}
      </FormBody>
    </>
  );
};

export default CreateFinancialInfoForm;
