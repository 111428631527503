import React from 'react';

import EmrTable from './EmrTable';
import InjuryAndIllnessTable from './InjuryAndIllnessTable';
import FileQuestion from './FileQuestion';
import Text from './Text';

const AnswerTypes = ({ type, countAnswers, fieldKey, title, answer, downloadList, handleDownload }) => {
  const types = {
    Text: <Text fieldKey={fieldKey} countAnswers={countAnswers} title={title} answer={answer} />,
    EmrTable: <EmrTable data={answer} />,
    InjuryAndIllnessTable: <InjuryAndIllnessTable data={answer} />,
    Uploads: <FileQuestion files={answer} downloadList={downloadList} handleDownload={handleDownload} />,
  };
  return types[type] || null;
};

export default AnswerTypes;
