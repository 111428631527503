import React from 'react';
import { Card, Grid, Collapse } from '@material-ui/core';

import useStyles from './styles';

const NotificationContainer = ({ open, success, error, warning, children }) => {
  const classes = useStyles({ error, success, warning });

  return (
    <Collapse in={open} className={classes.wrapper}>
      <Grid container spacing={2} className={classes.container}>
        <Grid item xs={12}>
          <Card className={classes.card}>{children}</Card>
        </Grid>
      </Grid>
    </Collapse>
  );
};

export default NotificationContainer;
