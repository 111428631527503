import React from 'react';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';
import { ThemeProvider } from '@material-ui/styles';
import { ToastContainer, Zoom } from 'react-toastify';
import CssBaseline from '@material-ui/core/CssBaseline';
import 'react-toastify/dist/ReactToastify.css';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import 'typeface-roboto';

import Routes from './ui/routes/Routes';
import { TOAST_AUTOCLOSE } from './utils/constants';
import theme from './theme';
import createStore, { history } from './createStore';

const store = createStore();

function App() {
  return (
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <ConnectedRouter history={history}>
            <ToastContainer autoClose={TOAST_AUTOCLOSE} position={'bottom-center'} transition={Zoom} />
            <Routes />
          </ConnectedRouter>
        </MuiPickersUtilsProvider>
      </ThemeProvider>
    </Provider>
  );
}

export default App;
