/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import qs from 'query-string';
import { useLocation } from 'react-router-dom';
import { push } from 'connected-react-router';
import { Helmet } from 'react-helmet';

import { archive } from '../../../actionTypes';
import { title } from '../../routes/path';
import { companyRolesSelection } from '../../../utils/constants/companyRoleTypes';
import ArchiveCompany from '../../containers/ArchiveCompany';

const skipFalsyValues = values =>
  Object.keys(values)
    .filter(key => values[key].length > 0)
    .reduce((acc, current) => ({ ...acc, [current]: values[current] }), {});

const CompanyList = () => {
  const location = useLocation();
  const dispatch = useDispatch();

  const { total, items, loading } = useSelector(store => store.archive.company);
  const companies = useSelector(store => items.map(id => store.entity.company[id]));

  const { itemsPerPage = 25, currentPage = 1, companyName = '', companyType = '' } = qs.parse(location.search, {
    comma: true,
  });

  const filterValues = {
    companyName,
    companyType: companyType === companyRolesSelection.All ? '' : companyType,
    deleted: true,
  };

  const noFiltersSelected = !companyName && !companyType;

  useEffect(() => {
    const payload = {
      pagination: {
        page: currentPage,
        perPage: itemsPerPage,
      },
      filter: filterValues,
    };
    dispatch({
      type: archive.company.get.start,
      payload: { payload },
    });
  }, [currentPage, dispatch, companyName, companyType, itemsPerPage]);

  const handleChangePage = offset => {
    const currentPage = offset / itemsPerPage + 1;

    const updatedFilterValues = skipFalsyValues(filterValues);

    dispatch(
      push({
        pathname: location.pathname,
        search: qs.stringify(
          { itemsPerPage: itemsPerPage, currentPage, ...updatedFilterValues },
          { arrayFormat: 'comma', encode: false, skipNull: true },
        ),
      }),
    );
  };

  const handleFilter = function(values) {
    dispatch(
      push({
        pathname: location.pathname,
        search: qs.stringify({ ...skipFalsyValues(values) }, { arrayFormat: 'comma', encode: false, skipNulls: true }),
      }),
    );
  };

  const handleRowAmountChange = value => {
    dispatch(
      push({
        pathname: location.pathname,
        search: qs.stringify(
          { itemsPerPage: value, currentPage: 1, ...skipFalsyValues(filterValues) },
          { arrayFormat: 'comma', encode: false, skipNulls: true },
        ),
      }),
    );
  };

  const handleRestore = companyId => {
    const payload = {
      pagination: {
        page: currentPage,
        perPage: itemsPerPage,
      },
      filter: filterValues,
    };
    dispatch({
      type: archive.company.recover.start,
      payload: { payload, companyId },
    });
  };

  return (
    <>
      <Helmet>
        <title>{title.private.company.archiveCompany}</title>
      </Helmet>
      <ArchiveCompany
        filter={{
          handleFilter,
          filterValues,
          noFiltersSelected,
        }}
        pagination={{
          total,
          itemsPerPage,
          currentPage,
          handleChangePage,
          handleRowAmountChange,
        }}
        companies={{
          list: companies,
          loading,
          handleRestore,
        }}
      />
    </>
  );
};

export default CompanyList;
