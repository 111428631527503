import React from 'react';
import { Grid, Fab, Typography } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';

const AddRow = ({ handleAdd }) => {
  return (
    <Grid item xs={12}>
      <Grid container spacing={2} alignItems="center">
        <Grid item>
          <Fab color="primary" size="small" aria-label="Add question" onClick={handleAdd}>
            <AddIcon />
          </Fab>
        </Grid>
        <Grid item>
          <Typography> Add question </Typography>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default AddRow;
