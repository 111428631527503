import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet';

import SettingsForm from '../../containers/SettingsForm';
import settings from '../../../actionTypes/settings';
import { title } from '../../routes/path';
import { formatPageTitle } from '../../../utils/textFormaters';
import { useCompanyInfo } from '../../../utils/effects/useCompanyInfo';
import { useUserPermissions } from '../../../utils/effects/userPermissions';
import { userTypes } from '../../../utils/constants';

const SystemSettings = () => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const { companyName, companyId } = useCompanyInfo(id);
  const { type } = useUserPermissions();
  const { data, loading, success, error, users } = useSelector(state => state.settings.settings);

  useEffect(() => {
    dispatch({
      type: settings.configuration.get.start,
      payload: { fullData: type === userTypes.SafetyCouncil, companyId },
    });
  }, [companyId, dispatch, type]);

  const handleUpdateSize = value => {
    dispatch({
      type: settings.configuration.update.start,
      payload: { value, companyId },
    });
  };

  return (
    <>
      <Helmet>
        <title>{formatPageTitle(title.private.company.systemSettings, { companyName })}</title>
      </Helmet>
      <SettingsForm
        type={type}
        users={users}
        data={data}
        loading={loading}
        error={error}
        success={success}
        handleUpdateSize={handleUpdateSize}
      />
    </>
  );
};

export default SystemSettings;
