import React from 'react';
import { Grid, Typography, Box, CircularProgress } from '@material-ui/core';

import { useUserPermissions } from '../../../utils/effects/userPermissions';
import useStyles from './styles';
import UsersTable from './UsersTable';
import CreateNewUser from './CreateNewUser';

const SafetyCouncilType = 'SafetyCouncil';

const UsersListData = ({
  loading,
  userListData,
  updatingUserIds,
  createSuccess,
  handleCreateUser,
  handleResendInvitation,
  handleUpdateUser,
  handleDeleteUser,
}) => {
  const classes = useStyles();
  const { type } = useUserPermissions();
  const isSafetyCouncilUserType = type === SafetyCouncilType;

  return (
    <Grid container>
      {isSafetyCouncilUserType && <CreateNewUser handleCreateUser={handleCreateUser} createSuccess={createSuccess} />}
      {userListData && userListData.length > 0 && !loading ? (
        <UsersTable
          isSafetyCouncilUserType={isSafetyCouncilUserType}
          userListData={userListData}
          updatingUserIds={updatingUserIds}
          handleResendInvitation={handleResendInvitation}
          handleUpdateUser={handleUpdateUser}
          handleDeleteUser={handleDeleteUser}
        />
      ) : (
        <Grid item xs={12}>
          <Box borderColor="grey.300" borderTop={1} className={classes.emptyDataWrapper}>
            <Grid container justify="center">
              {loading ? (
                <CircularProgress />
              ) : (
                <Typography gutterBottom variant="body1">
                  You don't have any users yet
                </Typography>
              )}
            </Grid>
          </Box>
        </Grid>
      )}
    </Grid>
  );
};

export default UsersListData;
