import React from 'react';
import { Grid } from '@material-ui/core';

import PageLoader from '../../components/PageLoader';
import PaginationWithCustomRows from '../../components/PaginationWithCustomRows';
import CompanyListTable from './CompanyListTabel';
import CreateFilterForm from './CreateFilterForm';
import PaperWrapper from '../../components/PaperWrapper';

const Marketplace = ({
  isSafetyCouncil,
  handleFilter,
  allServices,
  allWorkCategories,
  loading,
  fullInfoLoading,
  companies,
  total,
  handleChangePage,
  noFiltersSelected,
  filterValues,
  itemsPerPage,
  currentPage,
  handleDelete,
  handleRowAmountChange,
  handelSort,
  order,
  selectedSortField,
  handleAddToFavorite,
  loadingListAddToFavorite,
  handleExport,
  exporting,
  visibleColumns,
  setVisibleColumns,
  handleExportWorkingHours,
  exportingWorkingHours,
  canExportWorkingHours
}) => {
  return (
    <PaperWrapper>
      {fullInfoLoading ? (
        <PageLoader />
      ) : (
        <Grid item xs={12}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <CreateFilterForm
                isSafetyCouncil={isSafetyCouncil}
                handleOnChange={handleFilter}
                allServices={allServices}
                allWorkCategories={allWorkCategories}
                values={filterValues}
                handleOnExport={handleExport}
                exporting={exporting}
                handleExportWorkingHours={handleExportWorkingHours}
                exportingWorkingHours={exportingWorkingHours}
                canExportWorkingHours={canExportWorkingHours}
              />
            </Grid>
            <Grid item xs={12}>
              <CompanyListTable
                loading={loading}
                companies={companies}
                handleDelete={handleDelete}
                handleAddToFavorite={handleAddToFavorite}
                loadingListAddToFavorite={loadingListAddToFavorite}
                handelSort={handelSort}
                noFiltersSelected={noFiltersSelected}
                order={order}
                selectedSortField={selectedSortField}
                visibleColumns={visibleColumns}
                setVisibleColumns={setVisibleColumns}
              />
            </Grid>
            <Grid item xs={12}>
              <PaginationWithCustomRows
                total={total}
                onPageChange={handleChangePage}
                itemsPerPage={itemsPerPage}
                currentPage={currentPage}
                onRowAmountChange={handleRowAmountChange}
              />
            </Grid>
          </Grid>
        </Grid>
      )}
    </PaperWrapper>
  );
};

export default Marketplace;
