import React from 'react';
import { Button, Grid } from '@material-ui/core';
import { FastField, Formik, Field } from 'formik';

import FormSelect from '../../../components/FormSelect';
import useStyles from './styles';

const FieldFormContainer = ({
  tableData,
  label,
  initialTableValues,
  validationSchema,
  fieldName,
  fieldNumber,
  fieldMaskType,
  fieldComponent,
  allowUpdate,
  handleSubmit,
  specialFilter,
  getYearsOptions,
}) => {
  const classes = useStyles();
  const onSubmit = (values, { resetForm }) => {
    resetForm();
    handleSubmit(values);
  };
  const getAvailableLastYearsOptions = () => {
    return getYearsOptions().filter(item => !tableData || tableData.every(e => e.year !== item.value));
  };
  const isFormDisplayed = !tableData || getAvailableLastYearsOptions().length;

  return (
    <Formik initialValues={initialTableValues} validationSchema={validationSchema} onSubmit={onSubmit}>
      {props => {
        return (
          <>
            {isFormDisplayed ? (
              <>
                {allowUpdate && (
                  <Grid container spacing={1} className={classes.tableFormWrapper}>
                    <Grid item lg={5} sm={5} className={classes.fieldItem}>
                      <Field
                        className={classes.inputRow}
                        label="Year"
                        name="year"
                        options={getAvailableLastYearsOptions()}
                        specialFilter={specialFilter}
                        component={FormSelect}
                      />
                    </Grid>
                    <Grid item lg={5} sm={5} className={classes.fieldItem}>
                      <FastField
                        className={classes.inputRow}
                        label={label}
                        name={fieldName}
                        number={fieldNumber}
                        maskType={fieldMaskType}
                        component={fieldComponent}
                      />
                    </Grid>
                    <Grid item lg={2} sm={2} className={classes.fieldItem}>
                      <Grid container className={classes.buttonContainer}>
                        <Grid item lg={12}>
                          <Grid container justify="flex-end">
                            <Button
                              size="small"
                              variant="contained"
                              color="primary"
                              onClick={() => props.handleSubmit()}
                            >
                              Add
                            </Button>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                )}
              </>
            ) : null}
          </>
        );
      }}
    </Formik>
  );
};

export default FieldFormContainer;
