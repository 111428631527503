import { workingHourNotes } from '../../actionTypes';

const initialState = {
  loading: false,
  exporting: false,
  error: null,
};

export default function update(state = initialState, action) {
  switch (action.type) {
    case workingHourNotes.update.start:
      return { ...state, loading: true };

    case workingHourNotes.update.end:
      return {
        ...state,
        loading: false,
        success: true,
        error: false,
      };

    case workingHourNotes.update.error:
      return {
        ...state,
        loading: false,
        success: false,
        error: true,
      };

    default:
      return state;
  }
}
