import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { push } from 'connected-react-router';

import { userTypes } from '../../../utils/constants';
import { file } from '../../../actionTypes';
import { formatPath } from '../../../utils/textFormaters';
import routes from '../../routes/path';

const FileDownload = () => {
  const dispatch = useDispatch();
  const { hash } = useParams();

  const userId = useSelector(state => state.auth.check.userId);
  const { companyType } = useSelector(state => state.entity.user[userId]);

  useEffect(() => {
    try {
      const { CompanyId, Id, Key } = JSON.parse(atob(hash));
      dispatch({
        type: file.download.start,
        payload: { companyId: CompanyId, sectionKey: Key, fileId: Id },
      });

      const relocationPath =
        companyType === userTypes.Contractor
          ? routes.private.index
          : formatPath(routes.private.company.id, { id: CompanyId });

      dispatch(push(relocationPath));
    } catch (e) {
      dispatch(push(routes.private.notFound));
    }
  }, [companyType, dispatch, hash]);

  return '';
};

export default FileDownload;
