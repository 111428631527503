import React from 'react';
import { Typography, Link as MaterialLink } from '@material-ui/core';

import { formatPhone, formatDate } from '../../../../../utils/textFormaters';

const phoneKeys = ['cmpninfoQ3', 'officersQ17', 'envmgmtCQ5', 'insurpolQ4'];
const emailKeys = ['envmgmtCQ4', 'officersQ16'];
const dateKeys = ['orginfoQ2', 'emrQ2'];
const urlKeys = ['cmpninfoQ5'];

const Text = ({ title, countAnswers, answer, fieldKey }) => {
  const formatAnswer =
    (phoneKeys.includes(fieldKey) && formatPhone(answer)) ||
    (dateKeys.includes(fieldKey) && formatDate(answer)) ||
    (emailKeys.includes(fieldKey) && <MaterialLink href={`mailto:${answer}`}>{answer}</MaterialLink>) ||
    (urlKeys.includes(fieldKey) && <MaterialLink href={`//${answer}`}>{answer}</MaterialLink>) ||
    answer;
  return (
    <Typography variant="body1" gutterBottom>
      {countAnswers > 1 && <strong>{title}:</strong>} {formatAnswer || 'N/A'}
    </Typography>
  );
};

export default Text;
