export default {
  '11': 'Agriculture, forestry, fishing and hunting',
  '21': 'Mining, quarrying, and oil and gas extractions',
  '23': 'Construction',
  '31': 'Manufacturing',
  '32': 'Manufacturing',
  '33': 'Manufacturing',
  '42': 'Wholesale trade',
  '44': 'Retail trade',
  '45': 'Retail trade',
  '48': 'Transportation and warehousing',
  '49': 'Transportation and warehousing',
  '22': 'Utilities',
  '51': 'Information',
  '52': 'Finance and insurance',
  '53': 'Real estate and rental and leasing',
  '54': 'Professional, scientific, and technical services',
  '55': 'Management of companies and enterprises',
  '56': 'Administrative and support and waste management and remediation services',
  '61': 'Educational services',
  '62': 'Health care and social assistance',
  '71': 'Arts, entertainment, and recreation',
  '72': 'Accomodation and food services',
  '81': 'Other services (except public administration)',
};
