import React from 'react';

import YesNoFieldType from './yesNoFieldType';
import TextFieldType from './textFieldType';
import FrequencyFieldType from './frequencyFieldType';
import UploadFieldType from './uploadFieldType';
import { yesnonaOptions, yesnoOptions, frequencyOptions } from '../../../../utils/constants/formConstats';

const FieldComponent = ({ detail, disabler, formikValues }) => {
  const types = {
    YesNo: <YesNoFieldType name={detail.key} options={yesnoOptions} />,
    YesNoNA: <YesNoFieldType name={detail.key} options={yesnonaOptions} />,
    Section: null,
    Text: <TextFieldType type="textarea" label={detail.text} name={detail.key} />,
    Number: <TextFieldType name={detail.key} format={detail.format} />,
    Frequency: <FrequencyFieldType label="Frequency" name={detail.key} options={frequencyOptions} />,
    Uploads: (!disabler?.disablingFieldNames.includes(detail.key) || formikValues[disabler?.fieldName]) && (
      <UploadFieldType
        name={detail.key}
        label={detail.text}
        sectionKey={detail.key}
        companyId={detail.companyId}
        expirationDate={detail.expirationDate}
        disabled={disabler?.disablingFieldNames.includes(detail.key) && !formikValues[disabler?.fieldName]}
      />
    ),
  };
  return types[detail.type] || null;
};

export default FieldComponent;
