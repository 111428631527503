import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  link: {
    cursor: 'pointer',
  },
  ownerContainer: {
    marginBottom: theme.spacing(0.5),
    paddingRight: theme.spacing(1.5),
    '&:last-child': {
      marginBottom: 0,
    },
  },
  nameDateWrapper: {
    paddingRight: theme.spacing(1.5),
  },
}));

export default useStyles;
