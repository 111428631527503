import React from 'react';
import { Grid, Collapse } from '@material-ui/core';

import SingleQuestion from '../SingleQuestion';
import { enumTypes } from '../../../../utils/constants/formBuilder';
import useStyles from './styles';

const QuestionStructure = ({ values, questions, readOnly, preview, listKeys, level = 0, companyId, answersLib }) => {
  const classes = useStyles();
  return (
    <Grid container spacing={2}>
      {listKeys
        .sort((a, b) => questions[a].order - questions[b].order)
        .map(q => {
          const isShow =
            questions[q].isActive &&
            (questions[q].parentId === null ||
              (questions[q].parentId && questions[questions[q].parentId].type === enumTypes.Title) ||
              (questions[q].showOnCondition &&
                questions[q].parentId &&
                questions[q].parentAnswerCondition === values[questions[q].parentId]));
          return (
            <Collapse
              key={q}
              in={isShow}
              classes={{
                container: classes.container,
                wrapperInner: classes.innerWrapper,
              }}
            >
              <Grid item lg={12} key={q}>
                <SingleQuestion
                  readOnly={readOnly}
                  preview={preview}
                  show={isShow}
                  level={level}
                  label={questions[q].text}
                  name={`${q}`}
                  type={questions[q].type}
                  options={questions[q].options}
                  companyId={companyId}
                  ownerCompanyId={questions[q].companyId}
                  formBuilderFieldId={q}
                  formBuilderFieldAnswerId={answersLib[q]?.id}
                />
                {questions[q].children.length > 0 && (
                  <Grid item xs={12}>
                    <QuestionStructure
                      readOnly={readOnly}
                      preview={preview}
                      values={values}
                      level={level + 1}
                      questions={questions}
                      listKeys={questions[q].children.filter(q => questions[q].isActive)}
                      answersLib={answersLib}
                      companyId={companyId}
                    />
                  </Grid>
                )}
              </Grid>
            </Collapse>
          );
        })}
    </Grid>
  );
};

export default QuestionStructure;
