import React from 'react';
import { Field } from 'formik';

import FileUploaderInput from '../../../components/FileUploaderInput';

const UploadFieldType = ({ label, name, sectionKey, expirationDate, companyId, disabled }) => {
  return (
    <Field
      name={name}
      component={FileUploaderInput}
      label={label}
      sectionKey={sectionKey}
      companyId={companyId}
      expirationDate={expirationDate}
      disabled={disabled}
    />
  );
};

export default UploadFieldType;
