import { put, call, takeLatest } from 'redux-saga/effects';

import { notification } from '../../../actionTypes';
import dataProvider from '../../../store/dataProvider';
import headerNotification from '../../../actionTypes/headerNotification';

export function* deleteSaga({ payload }) {
  const { companyId, notificationId } = payload;
  try {
    yield call(dataProvider.updateOnly, `companies/${companyId}/notifications/${notificationId}/dismiss`);
    yield put({
      type: headerNotification.delete.end,
      payload,
    });
  } catch (e) {
    yield put({ type: notification.add, payload: { error: !!e && e.message } });
  }
}

export default function* headerNotificationDeleteSaga() {
  yield takeLatest(headerNotification.delete.start, deleteSaga);
}
