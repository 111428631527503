import { currentCompany } from '../../actionTypes';

const initialState = {
  loading: true,
  emrInfo: {},
  emrArrays: [],
  naicsCodes: [],
  success: false,
  error: false,
};

export default function emrate(state = initialState, action) {
  switch (action.type) {
    case currentCompany.emrate.get.start:
      return { ...initialState, loading: true };

    case currentCompany.emrate.get.end:
      return {
        ...state,
        loading: false,
        emrInfo: action.payload.emrInfo,
        emrArrays: action.payload.emrArrays,
        naicsCodes: action.payload.naicsCodes,
      };

    case currentCompany.emrate.get.error:
      return {
        ...state,
        loading: false,
        emrInfo: {},
        emrArrays: [],
      };

    case currentCompany.emrate.update.start:
      return { ...state, loading: true };

    case currentCompany.emrate.update.end:
      return {
        ...state,
        emrInfo: action.payload.emrInfo,
        emrArrays: action.payload.emrArrays,
        loading: false,
        success: true,
        error: false,
      };

    case currentCompany.emrate.update.error:
      return {
        ...state,
        loading: false,
        success: false,
        error: true,
      };

    default:
      return state;
  }
}
