import { changeEmptyStringToNull, changeNullToEmptyString } from '../../../utils/containersHelpers';

export const modifyArrayFieldsNullToEmptyString = obj => {
  const merged = {};
  Object.keys(obj).forEach(key => {
    if (Array.isArray(obj[key])) {
      merged[key] = obj[key].map(item => changeNullToEmptyString(item));
    } else {
      merged[key] = obj[key];
    }
  });
  return merged;
};

export const modifyArrayFieldsEmptyStringToNull = obj => {
  const merged = {};
  Object.keys(obj).forEach(key => {
    if (Array.isArray(obj[key])) {
      merged[key] = obj[key].map(item => changeEmptyStringToNull(item));
    } else {
      merged[key] = obj[key];
    }
  });
  return merged;
};
