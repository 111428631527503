import React from 'react';
import Tooltip from '@material-ui/core/Tooltip';
import ErrorSharpIcon from '@material-ui/icons/ErrorSharp';
import CheckSharpIcon from '@material-ui/icons/CheckSharp';

import useStyles from './styles';

const validTooltipTitle = 'Expiration date for all documents is valid';
const notValidTooltipTitle = 'Expiration date for some documents is over';

const OverdueCell = ({ overdue }) => {
  const classes = useStyles();
  return (
    <Tooltip title={!overdue ? validTooltipTitle : notValidTooltipTitle}>
      {!overdue ? <CheckSharpIcon className={classes.success} /> : <ErrorSharpIcon className={classes.error} />}
    </Tooltip>
  );
};

export default OverdueCell;
