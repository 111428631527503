import React from 'react';
import { TextField } from '@material-ui/core';
import get from 'lodash.get';

import MaskedInput from './maskedInput';

const FormNumberInput = ({
  maskType,
  size = 'small',
  type = 'text',
  handleChange,
  field,
  form: { errors, touched },
  tabIndex,
  ...props
}) => {
  const { onChange, ...fieldProps } = field;
  const itemError = get(errors, field.name.split('.'));
  const itemTouched = get(touched, field.name.split('.'));

  return (
    <TextField
      {...props}
      {...field}
      fullWidth
      variant="outlined"
      size={size}
      type={type}
      onChange={e => {
        handleChange && handleChange(e, fieldProps);
        type !== 'decimal' && onChange(e);
      }}
      error={!!itemError && !!itemTouched}
      helperText={!!itemError && itemTouched && itemError}
      InputProps={{
        inputComponent: MaskedInput,
      }}
      inputProps={{ maskType, tabIndex }}
    />
  );
};

export default FormNumberInput;
