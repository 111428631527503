import React from 'react';
import NavigationPrompt from 'react-router-navigation-prompt';
import { useSelector } from 'react-redux';
import { Button, Dialog, DialogTitle, Typography, DialogActions, Grid } from '@material-ui/core';

import useStyles from './styles';

const PromptNotification = ({ formStatus }) => {
  const { data } = useSelector(state => state.settings.settings);
  const classes = useStyles();
  if (!formStatus) return '';

  return (
    <NavigationPrompt when={data.promptConfirm}>
      {({ onConfirm, onCancel }) => (
        <Dialog
          open={true}
          classes={{
            paper: classes.dialogWrapper,
          }}
        >
          <DialogTitle disableTypography>
            <Typography variant="subtitle1" align="center">
              You have unsaved data. Are you sure you want to leave this page without saving?
            </Typography>
          </DialogTitle>
          <DialogActions>
            <Grid container direction="row" justify="flex-end">
              <Grid item xs={2}>
                <Button variant="contained" size="small" onClick={onCancel} fullWidth>
                  Cancel
                </Button>
              </Grid>
              <Grid item xs={2} className={classes.button}>
                <Button variant="contained" size="small" color="primary" onClick={onConfirm} fullWidth>
                  OK
                </Button>
              </Grid>
            </Grid>
          </DialogActions>
        </Dialog>
      )}
    </NavigationPrompt>
  );
};

export default PromptNotification;
