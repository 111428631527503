import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  statusText: {
    color: theme.palette.success.main,
  },
  tableRow: {
    position: 'relative',
    overflow: 'hidden',
    '& td': {
      width: '20%',
    },
  },
}));

export default useStyles;
