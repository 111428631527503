import { normalize } from 'normalizr';
import { call, put, takeLatest } from 'redux-saga/effects';

import { company as companySchema } from '../../../schema';
import { archive, entity, notification } from '../../../actionTypes';
import dataProvider from '../../../store/dataProvider';

export function* get({ payload: { payload = {} } }) {
  try {
    const { total, data } = yield call(dataProvider.getList, 'Companies/deleted-companies', payload);

    // normalize data
    const normalizeData = normalize(data, [companySchema]);
    yield put({ type: entity.add, payload: normalizeData.entities });

    yield put({
      type: archive.company.get.end,
      payload: { list: normalizeData.result, total },
    });
  } catch (e) {
    yield put({ type: notification.add, payload: { error: !!e && e.message } });
  }
}

export default function* companyListPageSaga() {
  yield takeLatest(archive.company.get.start, get);
}
