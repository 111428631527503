import { call, put, takeLatest } from 'redux-saga/effects';

import { currentCompany, notification } from '../../../actionTypes';
import dataProvider from '../../../store/dataProvider';
import { successMessages } from '../../../store/constants';

export function* remove({ payload: { companyId, userId } }) {
  try {
    yield call(dataProvider.deleteOne, `companies/${companyId}/users`, { id: userId });

    yield put({
      type: currentCompany.user.remove.end,
      payload: { userId },
    });
    yield put({
      type: notification.add,
      payload: { success: successMessages.companyUserDelete },
    });
  } catch (e) {
    yield put({ type: notification.add, payload: { error: !!e && e.message } });
    yield put({ type: currentCompany.user.restore.error, payload: { userId } });
  }
}

export default function* removeUserSaga() {
  yield takeLatest(currentCompany.user.remove.start, remove);
}
