import React from 'react';
import { Formik, FastField } from 'formik';
import { Paper, Typography, Grid, Button, CircularProgress, Backdrop } from '@material-ui/core';

import { setNewPasswordSchema } from '../../../utils/validate/shemas';
import FormInput from '../../components/FormInput';
import useStyles from './styles';

const SetNewPasswordForm = ({ loading, error, title, handleSubmit }) => {
  const classes = useStyles();
  return (
    <Paper className={classes.paper}>
      <Backdrop timeout={300} open={loading} className={classes.backdrop}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <Formik
        initialValues={{
          password: '',
          confirmPassword: '',
        }}
        validationSchema={setNewPasswordSchema}
        onSubmit={handleSubmit}
      >
        {props => (
          <form onSubmit={props.handleSubmit} noValidate>
            <Typography align="center" variant="h5" gutterBottom>
              {title}
            </Typography>

            {!!error && (
              <Typography gutterBottom variant="subtitle2" color="error" align="center">
                {error}
              </Typography>
            )}
            <FastField
              className={classes.inputRow}
              label="Password"
              type="password"
              name="password"
              component={FormInput}
            />
            <FastField
              className={classes.inputRow}
              label="Confirm Password"
              type="password"
              name="confirmPassword"
              component={FormInput}
            />
            <div className={classes.inputRow}>
              <Grid container alignItems="center" justify="flex-end">
                <Grid item sm={4}>
                  <Button size="large" fullWidth variant="contained" color="primary" type="submit">
                    Submit
                  </Button>
                </Grid>
              </Grid>
            </div>
          </form>
        )}
      </Formik>
    </Paper>
  );
};

export default SetNewPasswordForm;
