import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  inputRow: {
    margin: theme.spacing(1, 0),
  },
  contentOverflowWrapper: {
    overflow: 'hidden',
    maxWidth: theme.spacing(120),
  },
  labelWrapper: {
    marginBottom: theme.spacing(1),
  },
  labelCheckboxRight: {
    margin: theme.spacing(0),
    padding: theme.spacing(0),
  },
  checkboxLeft: {
    margin: theme.spacing(0),
    padding: theme.spacing(0, 1, 0, 1),
  },
  title: {
    marginBottom: theme.spacing(1),
  },
  boxWrapper: {
    padding: theme.spacing(1),
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    height: '100%',
    minWidth: theme.spacing(40),
    minHeight: theme.spacing(40),
  },
  textArea: {
    width: '100%',
    borderWidth: 1,
    borderColor: theme.palette.grey['400'],
    borderRadius: theme.spacing(0.5),
  },
}));

export default useStyles;
