import { call, put, takeLatest } from 'redux-saga/effects';
import { normalize } from 'normalizr';

import { currentCompany, entity, notification } from '../../../actionTypes';
import dataProvider from '../../../store/dataProvider';
import { company as companySchema } from '../../../schema';

export function* update({ payload: { id, image } }) {
  try {
    let formData = new FormData();
    formData.append('companyImage', image);
    const { data: { company } } = yield call(dataProvider.updateOneFormData, `Companies/${id}/image`, formData);

    const normalizeData = normalize([{ ...company, level: company.companyLevel }], [companySchema]);
    yield put({ type: entity.add, payload: normalizeData.entities });
    yield put({ type: currentCompany.info.updateImage.end, payload: {} });

  } catch (e) {
    yield put({ type: currentCompany.info.updateImage.error });
    yield put({ type: notification.add, payload: { error: e.message } });
  }
}

export default function* updateCompanyImageSaga() {
  yield takeLatest(currentCompany.info.updateImage.start, update);
}
