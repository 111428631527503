import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  button: {
    padding: theme.spacing(7 / 8),
    minWidth: theme.spacing(5),
  },
}));

export default useStyles;
