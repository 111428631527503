import { call, put, takeLatest } from 'redux-saga/effects';

import { pages, notification } from '../../../actionTypes';
import dataProvider from '../../../store/dataProvider';

export function* get({ payload: { companyId } }) {
  try {
    const { data } = yield call(dataProvider.getOnly, `companies/${companyId}/grade/scores/unofficial`);
    yield put({
      type: pages.dashboard.unofficialGrade.end,
      payload: {
        list: data.scores,
        mark: data.grade,
      },
    });
  } catch (e) {
    yield put({ type: notification.add, payload: { error: e.message } });
    yield put({
      type: pages.dashboard.unofficialGrade.error,
    });
  }
}

export default function* loadUnofficialGradeSaga() {
  yield takeLatest(pages.dashboard.unofficialGrade.start, get);
}
