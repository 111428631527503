export const normalizeValues = (fields, parentId) =>
  Object.keys(fields)
    .filter(key => `${fields[key].parentId}` === `${parentId}`)
    .reduce((acc, questionId) => {
      if (fields[questionId].generated) {
        const { id, hasChild, parentId, generated, ...obj } = fields[questionId];
        return [
          ...acc,
          {
            ...obj,
            children: normalizeValues(fields, questionId),
          },
        ];
      }

      const { generated, hasChild, ...obj } = fields[questionId];
      return [
        ...acc,
        {
          ...obj,
          children: normalizeValues(fields, questionId),
        },
      ];
    }, []);

export const normalizeValuesWithId = (fields, parentId) =>
  Object.keys(fields)
    .filter(key => `${fields[key].parentId}` === `${parentId}`)
    .reduce((acc, questionId) => {
      const { generated, ...obj } = fields[questionId];
      return [
        ...acc,
        {
          ...obj,
          children: normalizeValuesWithId(fields, questionId),
        },
      ];
    }, []);

export const createStructure = arr =>
  arr.reduce((acc, item) => {
    const child = item.children.length > 0 ? createStructure(item.children) : {};
    return {
      ...acc,
      ...child,
      [`${item.id}`]: {
        ...item,
        hasChild: item.children.length > 0,
        generated: false,
      },
    };
  }, {});
