import React, { memo } from 'react';
import { Typography } from '@material-ui/core';
import { Field } from 'formik';

import useStyles from './styles';
import FormInput from '../../../components/FormInput';

const LabelField = ({ label, name, id }) => {
  const classes = useStyles();

  return (
    <div className={classes.wrapper}>
      <Typography variant="h6">{label}</Typography>
      <Field
        name={name}
        id={id}
        component={FormInput}
        className={classes.textField}
        variant="outlined"
        type="number"
        number="decimal"
      />
    </div>
  );
};

export default memo(LabelField);
