import { all, call, put, select, takeLatest } from 'redux-saga/effects';

import { currentCompany, notification } from '../../../actionTypes';
import { sanitizePhoneNumbers } from '../../../utils/textFormaters/sanitizePhoneNumbers';
import dataProvider from '../../../store/dataProvider';
import { updateFiles } from '../../file/updateFiles';
import { uploadSelector } from '../../../utils/pageHelpers';

export function* update({ payload: { companyId, questions, updateQuestions, updateProfile, sectionKey } }) {
  const transformedProfile = sanitizePhoneNumbers(updateProfile);
  try {
    const { questionList, files } = questions.reduce(
      (acc, item) => ({
        questionList:
          typeof item.answer === 'string'
            ? [...acc.questionList, item]
            : [...acc.questionList, { ...item, answer: '' }],
        files: typeof item.answer === 'object' ? [...acc.files, item] : acc.files,
      }),
      { questionList: [], files: [] },
    );

    if (files.length) {
      yield all(
        files.map(item =>
          call(updateFiles, {
            companyId,
            deleteList: item.answer.deleteList,
            createList: item.answer.createList,
            sectionKey: item.key,
          }),
        ),
      );
    }

    const fileObj = yield select(store =>
      uploadSelector(
        store,
        files.map(item => item.key),
      ),
    );

    yield call(dataProvider.createList, `companies/${companyId}/yes-no-questions`, questionList);
    const { data } = yield call(dataProvider.updateOnly, `companies/${companyId}/health-prof`, transformedProfile);

    const newQuestions = updateQuestions.map(item =>
      Object.keys(fileObj).includes(item.key) ? { ...item, answer: fileObj[item.key] } : item,
    );

    yield put({
      type: currentCompany.healthProf.update.end,
      payload: { healthProfile: data, questions: newQuestions },
    });

    // update PQF document validation
    yield put({
      type: currentCompany.companyInfo.validation.start,
      payload: { companyId: companyId, validationKey: sectionKey },
    });
  } catch (e) {
    yield put({ type: currentCompany.healthProf.update.error });
    yield put({ type: notification.add, payload: { error: e.message } });
  }
}

export default function* getHealthProfSaga() {
  yield takeLatest(currentCompany.healthProf.update.start, update);
}
