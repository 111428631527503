import React from 'react';
import { Grid, Typography } from '@material-ui/core';

import CollapseCard from '../../../components/Cards/CollapseCard';
import formateDate from '../../../../utils/textFormaters/date';
import { bussinessFormTypes } from '../../../../utils/constants/businessFormTypes';
import { addDollarCommaSigns, addCommaSigns } from '../../../../utils/containersHelpers';
import useStyles from './styles';

const OrganizationCard = ({ info }) => {
  const { formOfBusiness, incorporationDate, incorporationState, employees, annualRevenue, ceoName } = info;
  const classes = useStyles();

  const currentFormOfBusiness = Object.keys(bussinessFormTypes).find(
    type => formOfBusiness === bussinessFormTypes[type],
  );

  const isEmptyContent = !(
    formOfBusiness ||
    incorporationDate ||
    incorporationState ||
    ceoName ||
    employees ||
    annualRevenue
  );

  return (
    <CollapseCard title="Organization">
      <Grid item xs={12} className={classes.gridContainer}>
        {formOfBusiness && (
          <Grid container justify="space-between">
            <Grid item xs={6}>
              <Typography variant="h6">Company Type</Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography align="right" variant="subtitle2">
                {currentFormOfBusiness}
              </Typography>
            </Grid>
          </Grid>
        )}

        {incorporationDate && (
          <Grid container justify="space-between">
            <Grid item xs={6}>
              <Typography variant="h6">Established year</Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography align="right" variant="subtitle2">
                {formateDate(incorporationDate)}
              </Typography>
            </Grid>
          </Grid>
        )}

        {incorporationState && (
          <Grid container justify="space-between">
            <Grid item xs={6}>
              <Typography variant="h6">State Established</Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography align="right" variant="subtitle2">
                {incorporationState}
              </Typography>
            </Grid>
          </Grid>
        )}

        {ceoName && (
          <Grid container justify="space-between">
            <Grid item xs={6}>
              <Typography variant="h6">President/CEO</Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography align="right" variant="subtitle2">
                {ceoName}
              </Typography>
            </Grid>
          </Grid>
        )}

        {employees !== null && (
          <Grid container justify="space-between">
            <Grid item xs={6}>
              <Typography variant="h6">Employees</Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography align="right" variant="subtitle2">
                {addCommaSigns(employees)}
              </Typography>
            </Grid>
          </Grid>
        )}

        {!!annualRevenue && (
          <Grid container justify="space-between">
            <Grid item xs={6}>
              <Typography variant="h6">Net Worth</Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography align="right" variant="subtitle2">
                {addDollarCommaSigns(annualRevenue)}
              </Typography>
            </Grid>
          </Grid>
        )}

        {isEmptyContent && (
          <Typography variant="body1" align="center">
            There is no information yet
          </Typography>
        )}
      </Grid>
    </CollapseCard>
  );
};

export default OrganizationCard;
