import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  buttonStyles: ({ loading }) => ({
    pointerEvents: loading ? 'none' : 'auto',
  }),
  error: {
    fontSize: theme.typography.h2.fontSize,
    color: theme.palette.error.main,
    marginRight: theme.spacing(2),
  },
  success: {
    fontSize: theme.typography.h2.fontSize,
    color: theme.palette.success.main,
    marginRight: theme.spacing(2),
  },
  loading: {
    marginRight: theme.spacing(2),
  },
  placeholder: {
    height: theme.spacing(5),
    padding: 0,
  },
  submitAppBar: {
    width: 'auto',
    bottom: 0,
    top: 'auto',
    textAlign: 'right',
    boxShadow: 'none',
  },
  fixed: ({ fixed }) =>
    fixed && {
      padding: theme.spacing(2),
    },
}));

export default useStyles;
