import { currentCompany } from '../../actionTypes';

const initialState = {
  loading: false,
  loadingOnTable: false,
  references: [],
  largestJobInfo: {},
};

export default function reference(state = initialState, action) {
  switch (action.type) {
    case currentCompany.reference.create.start:
      return { ...state, loadingOnTable: true };

    case currentCompany.reference.create.end:
      return {
        ...state,
        loadingOnTable: false,
        references: [...state.references, action.payload],
      };
    case currentCompany.reference.create.error:
      return {
        ...state,
        loadingOnTable: false,
      };
    case currentCompany.reference.get.start:
      return {
        ...initialState,
        loading: true,
      };

    case currentCompany.reference.get.end:
      const { references, largestJobInfo } = action.payload;
      return {
        ...state,
        loading: false,
        references: references,
        largestJobInfo: largestJobInfo,
      };
    case currentCompany.reference.get.error:
      return {
        ...state,
        loading: false,
      };
    case currentCompany.reference.delete.start:
      return { ...state, loadingOnTable: true };

    case currentCompany.reference.delete.end:
      return {
        ...state,
        loadingOnTable: false,
        references: state.references.filter(item => item.id !== action.payload),
      };
    case currentCompany.reference.delete.error:
      return {
        ...state,
        loadingOnTable: false,
      };
    case currentCompany.reference.update.start:
      return { ...state, loadingOnTable: true };

    case currentCompany.reference.update.end:
      const { itemId, values } = action.payload;

      return {
        ...state,
        loadingOnTable: false,
        references: state.references.map(item => (item.id === itemId ? values : item)),
      };
    case currentCompany.reference.update.error:
      return {
        ...state,
        loadingOnTable: false,
      };

    case currentCompany.reference.updateJobInfo.start:
      return {
        ...state,
        loading: true,
      };
    case currentCompany.reference.updateJobInfo.end:
      return {
        ...state,
        loading: false,
        largestJobInfo: action.payload,
        success: true,
        error: false,
      };

    case currentCompany.reference.updateJobInfo.error:
      return {
        ...state,
        loading: false,
        success: false,
        error: true,
      };

    default:
      return state;
  }
}
