import React from 'react';
import { Button } from '@material-ui/core';
import ClearIcon from '@material-ui/icons/Clear';

import TooltipWrapper from '../../TooltipWrapper';
import useStyles from '../styles';

const ClearButton = ({ isEmptyForm, clearFilterValues }) => {
  const classes = useStyles();

  return (
    isEmptyForm && (
      <TooltipWrapper tooltip title="Clear all filters">
        <Button
          variant="outlined"
          classes={{
            root: classes.button,
          }}
          color="secondary"
          fullWidth
          onClick={clearFilterValues}
        >
          <ClearIcon />
        </Button>
      </TooltipWrapper>
    )
  );
};

export default ClearButton;
