import React from 'react';
import { Link as MaterialLink } from '@material-ui/core';
import { Link } from 'react-router-dom';

import useStyles from './styles';

const LinkWrapper = ({ children, showLink, to, href, target }) => {
  const classes = useStyles();

  return showLink ? (
    <MaterialLink component={to && Link} to={to} href={`//${href}`} target={target} className={classes.link}>
      {children}
    </MaterialLink>
  ) : (
    children
  );
};

export default LinkWrapper;
