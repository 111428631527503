import React from 'react';
import { Grid } from '@material-ui/core';

import ShowMoreLessButton from './ShowMoreLessButton';
import ClearButton from './ClearButton';

const ShowMoreLessFilters = ({ moreFilter, setMoreFilter, isEmptyForm, clearFilterValues }) => {
  return (
    <Grid container spacing={1} justify="flex-end" alignItems="center">
      <Grid item xs={9}>
        <ShowMoreLessButton moreFilter={moreFilter} setMoreFilter={setMoreFilter} />
      </Grid>
      <Grid item xs={3}>
        <ClearButton isEmptyForm={isEmptyForm} clearFilterValues={clearFilterValues} />
      </Grid>
    </Grid>
  );
};

export default ShowMoreLessFilters;
