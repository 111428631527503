import { call, put, takeLatest } from 'redux-saga/effects';

import { workingHours, notification } from '../../actionTypes';
import dataProvider from '../../store/dataProvider';
import { userTypes } from '../../utils/constants';

export function* exportXlsxWorkingHours({ payload: { userType, contractorCompanyId, ownerCompanyId, ...restParams } }) {
  try {
    const getUrlByUserType = (userType, contractorCompanyId, ownerCompanyId) => {
      switch (userType) {
        case userTypes.Contractor:
          return [`companies/${contractorCompanyId}/working-hours/xlsx`];
        case userTypes.Owner:
          return contractorCompanyId
            ? [`companies/${ownerCompanyId}/owner-working-hours/${contractorCompanyId}/xlsx`]
            : [`companies/${ownerCompanyId}/owner-working-hours/xlsx`, restParams];
        case userTypes.SafetyCouncil:
          return contractorCompanyId
            ? [`companies/${contractorCompanyId}/working-hours/xlsx`, restParams]
            : [`admin-working-hours/xlsx`, restParams];
        default:
          break;
      }
    };

    yield call(dataProvider.getOnly, ...getUrlByUserType(userType, contractorCompanyId, ownerCompanyId));

    yield put({ type: workingHours.exportWorkingHours.end, payload: {} });
  } catch (e) {
    yield put({ type: notification.add, payload: { error: e.message } });
    yield put({ type: workingHours.exportWorkingHours.error, payload: { error: e.message } });
  }
}

export default function* exportXlsxWorkingHoursSaga() {
  yield takeLatest(workingHours.exportWorkingHours.start, exportXlsxWorkingHours);
}
