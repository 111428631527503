import { call, put, takeLatest } from 'redux-saga/effects';

import { currentCompany, notification } from '../../../actionTypes';
import dataProvider from '../../../store/dataProvider';
import { sanitizePhoneNumbers } from '../../../utils/textFormaters/sanitizePhoneNumbers';

export function* update({
  payload: {
    companyId,
    values: { id, firstName, lastName, phoneNumber },
  },
}) {
  try {
    const transformedValues = sanitizePhoneNumbers({ firstName, lastName, phoneNumber });

    const { data } = yield call(dataProvider.updateOnly, `companies/${companyId}/users/${id}`, transformedValues);

    yield put({
      type: currentCompany.user.update.end,
      payload: data,
    });
  } catch (e) {
    yield put({ type: notification.add, payload: { error: e.message } });
    yield put({ type: currentCompany.user.update.error, payload: { id } });
  }
}

export default function* updateUserSaga() {
  yield takeLatest(currentCompany.user.update.start, update);
}
