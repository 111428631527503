import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  inputRow: {
    margin: theme.spacing(1, 0),
  },
  fileContainer: {
    margin: theme.spacing(2, 0),
  },
}));

export default useStyles;
