import { call, put, takeLatest } from 'redux-saga/effects';

import { workingHours, notification } from '../../actionTypes';
import dataProvider from '../../store/dataProvider';

export function* canExportWorkingHours({ payload: { ownerCompanyId, isSafetyCouncil } }) {
  try {
    const { data } = yield call(
      dataProvider.getOnly,
      isSafetyCouncil ? `admin-working-hours/can-export` : `companies/${ownerCompanyId}/owner-working-hours/can-export`,
    );
    yield put({ type: workingHours.canExportWorkingHours.end, payload: data });
  } catch (e) {
    yield put({ type: notification.add, payload: { error: e.message } });
    yield put({ type: workingHours.canExportWorkingHours.error, payload: { error: e.message } });
  }
}

export default function* exportCanExportWorkingHoursSaga() {
  yield takeLatest(workingHours.canExportWorkingHours.start, canExportWorkingHours);
}
