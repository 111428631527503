import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux';

import routes from './path';

const PrivateRoute = props => {
  const isLoggedIn = useSelector(state => state.auth.check.state);
  return !isLoggedIn ? <Route {...props} /> : <Redirect to={routes.private.index} />;
};

export default PrivateRoute;
