import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet';

import BLSForm from '../../containers/BLS';
import { settings } from '../../../actionTypes';
import { title } from '../../routes/path';
import { formatPageTitle } from '../../../utils/textFormaters';
import { useCompanyInfo } from '../../../utils/effects/useCompanyInfo';

const SystemSettings = () => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const { companyName, companyId } = useCompanyInfo(id);

  const { loading, updating, data, error, success } = useSelector(state => state.settings.BLSData);

  useEffect(() => {
    dispatch({ type: settings.BLSData.get.start });
  }, [companyId, dispatch]);

  const handleUpdate = values => {
    dispatch({
      type: settings.BLSData.update.start,
      payload: { values },
    });
  };

  return (
    <>
      <Helmet>
        <title>{formatPageTitle(title.private.company.bls, { companyName })}</title>
      </Helmet>
      <BLSForm
        loading={loading}
        updating={updating}
        values={data}
        error={error}
        success={success}
        handleUpdate={handleUpdate}
      />
    </>
  );
};

export default SystemSettings;
