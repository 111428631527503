import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import { FastField, Formik } from 'formik';

import { craftsmanValidateSchema } from '../../../utils/validate/shemas';
import LoadingButton from '../../components/LoadingButton';
import FormBody from '../../components/FormBody';
import FormRadio from '../../components/FormRadio';
import { yesnoOptions, yesnonaOptions } from '../../../utils/constants/formConstats';
import useStyles from './styles';
import CraftsmanCertificationSubForm from './CraftsmanCertificationSubForm';
import { modifyArrayFieldsNullToEmptyString } from './modifyCraftsman';
import PageLoader from '../../components/PageLoader';
import PromptNotification from '../../components/PromptComponent';
import { changeNotAvailableToNA } from '../../../utils/containersHelpers';

const CraftsmanCertificationForm = ({ handleSubmit, allowUpdate, initialValues, loading, success, error }) => {
  const classes = useStyles();

  const formattedValues = initialValues && changeNotAvailableToNA(modifyArrayFieldsNullToEmptyString(initialValues));

  if (!formattedValues) return <PageLoader />;

  return (
    <Formik
      enableReinitialize
      initialValues={formattedValues}
      onSubmit={handleSubmit}
      validationSchema={craftsmanValidateSchema}
    >
      {props => {
        const { hasTrainingPrograms, hasPracticalTestingPrograms } = props.values;
        return (
          <>
            <PromptNotification formStatus={props.dirty} />
            <FormBody disabled={!allowUpdate}>
              <Grid container className={classes.wrapper}>
                <Grid container direction="row" alignItems="center" justify="space-between">
                  <Typography variant="subtitle2">
                    Do you comply with the SLCUC Participation Policy for contractor worker certification?
                  </Typography>
                  <Grid item lg={3}>
                    <FastField name="compliesWithSLCUC" component={FormRadio} options={yesnonaOptions} />
                  </Grid>
                </Grid>

                <Grid container direction="row" alignItems="center" justify="space-between">
                  <Typography variant="subtitle2">
                    Do you comply with the Golden Triangle Business Roundtable Crat Certification and Assessment Policy?
                  </Typography>
                  <Grid item lg={3}>
                    <FastField name="compliesWithGoldenTriangle" component={FormRadio} options={yesnonaOptions} />
                  </Grid>
                </Grid>

                <Grid container direction="row" alignItems="center" justify="space-between">
                  <Typography variant="subtitle2">
                    Do you have training/certification programs for certifying "A" Craftsmen?
                  </Typography>
                  <Grid item lg={3}>
                    <FastField name="hasTrainingPrograms" component={FormRadio} options={yesnoOptions} />
                  </Grid>
                </Grid>
                {hasTrainingPrograms === 'Yes' && (
                  <CraftsmanCertificationSubForm data={props.values.trainingPrograms} categoryKey="trainingPrograms" />
                )}

                <Grid container direction="row" alignItems="center" justify="space-between">
                  <Typography variant="subtitle2">
                    Do you have practical (hands-on) testing programs for certifying "A" Craftsmen?
                  </Typography>
                  <Grid item lg={3}>
                    <FastField name="hasPracticalTestingPrograms" component={FormRadio} options={yesnoOptions} />
                  </Grid>
                </Grid>
                {hasPracticalTestingPrograms === 'Yes' && (
                  <CraftsmanCertificationSubForm
                    categoryKey="practicalTestingPrograms"
                    data={props.values.practicalTestingPrograms}
                  />
                )}

                <Grid container direction="row" alignItems="center" justify="space-between">
                  <Typography variant="subtitle2">Is the Company an accredited NCCER Assessment Center?</Typography>
                  <Grid item lg={3}>
                    <FastField name="isNccerAssessmentCenter" component={FormRadio} options={yesnoOptions} />
                  </Grid>
                </Grid>
              </Grid>
              {allowUpdate && (
                <LoadingButton
                  fixed
                  disabled={!props.dirty || !props.isValid}
                  success={success}
                  error={error}
                  loading={loading}
                  text="save"
                  onClick={props.handleSubmit}
                />
              )}
            </FormBody>
          </>
        );
      }}
    </Formik>
  );
};

export default CraftsmanCertificationForm;
