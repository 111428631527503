import { normalize } from 'normalizr';
import { call, put, takeLatest } from 'redux-saga/effects';

import { company as companySchema } from '../../../schema';
import { archive, entity, notification } from '../../../actionTypes';
import dataProvider from '../../../store/dataProvider';
import { successMessages } from '../../../store/constants';

export function* recoverCompany({ payload: { companyId, payload } }) {
  try {
    yield call(dataProvider.updateOnly, `Companies/${companyId}/unarchive`, {});
    const { total, data } = yield call(dataProvider.getList, 'Companies/deleted-companies', payload);

    // normalize data
    const normalizeData = normalize(data, [companySchema]);
    yield put({ type: entity.add, payload: normalizeData.entities });

    yield put({
      type: archive.company.recover.end,
      payload: { list: normalizeData.result, total },
    });
    yield put({ type: notification.add, payload: { success: successMessages.companyRestore } });
  } catch (e) {
    yield put({ type: archive.company.recover.error });
    yield put({ type: notification.add, payload: { error: !!e && e.message } });
  }
}

export default function* recoverCompanySaga() {
  yield takeLatest(archive.company.recover.start, recoverCompany);
}
