import { currentCompany } from '../../actionTypes';

const initialState = {
  loading: true,
  saving: false,
  success: false,
  error: false,
  list: [],
};

export default function ownerAddendum(state = initialState, action) {
  switch (action.type) {
    case currentCompany.ownerAddendum.get.start:
      return initialState;

    case currentCompany.ownerAddendum.get.end:
      return {
        ...state,
        loading: false,
        list: action.payload.list,
      };

    case currentCompany.ownerAddendum.get.error:
      return {
        ...state,
        loading: false,
        error: true,
        list: [],
      };

    case currentCompany.ownerAddendum.create.start:
      return {
        ...state,
        saving: true,
      };

    case currentCompany.ownerAddendum.create.end:
      return {
        ...state,
        saving: false,
        success: true,
        list: action.payload.list,
      };

    case currentCompany.ownerAddendum.create.error:
      return {
        ...state,
        saving: false,
        error: true,
      };

    default:
      return state;
  }
}
