import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet';

import { currentCompany } from '../../../actionTypes';
import YesNoQuestionTypical from '../../containers/YesNoQuestionTypical';
import { title } from '../../routes/path';
import { formatPageTitle } from '../../../utils/textFormaters';
import { useCompanyInfo } from '../../../utils/effects/useCompanyInfo';
import { useUserPermissions } from '../../../utils/effects/userPermissions';

const disablerSuffix = 'Disabled';

const YesNoQuestionDefault = ({ sectionKey }) => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const { companyId, companyName } = useCompanyInfo(id);
  const { allowUpdate } = useUserPermissions(companyId);

  const { stack, disabler, loading, success, error } = useSelector(store => store.currentCompany.yesNoQuestions[`${sectionKey}`]);

  useEffect(() => {
    dispatch({ type: currentCompany.yesNoQuestions.get.start, payload: { sectionKey, companyId, param: {} } });
  }, [sectionKey, companyId, dispatch]);

  const handleSubmit = submitValues => {
    dispatch({
      type: currentCompany.yesNoQuestions.update.start,
      payload: {
        sectionKey,
        companyId,
        values: Object.keys(submitValues).map(key => ({ key, answer: key.endsWith(disablerSuffix) ? submitValues[key] : submitValues[key].answer })),
        updateStack: Object.keys(submitValues).filter(key => !key.endsWith(disablerSuffix)).map(key => submitValues[key]),
        disabler: disabler && { ...disabler, value: submitValues[disabler.fieldName] },
      },
    });
  };

  return (
    <>
      <Helmet>
        <title> {formatPageTitle(title.private.company[sectionKey], { companyName })} </title>
      </Helmet>
      <YesNoQuestionTypical
        companyId={companyId}
        questions={stack}
        disabler={disabler}
        loading={loading}
        handleSubmit={handleSubmit}
        success={success}
        error={error}
        allowUpdate={allowUpdate}
      />
    </>
  );
};

export default YesNoQuestionDefault;
