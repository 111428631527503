import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet';

import { title } from '../../routes/path';
import CompanySettingsForm from '../../containers/CompanySettingsForm';
import currentCompany from '../../../actionTypes/currentCompany';
import { formatPageTitle } from '../../../utils/textFormaters';
import { useCompanyInfo } from '../../../utils/effects/useCompanyInfo';
import { useUserPermissions } from '../../../utils/effects/userPermissions';

const CompanySettings = () => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const { type } = useUserPermissions();
  const { companyName, companyId, companyType } = useCompanyInfo(id);
  const { data, loading, updating, success, error, users } = useSelector(state => state.currentCompany.settings);

  useEffect(() => {
    dispatch({
      type: currentCompany.settings.get.start,
      payload: { companyId },
    });
  }, [companyId, dispatch]);

  const handleUpdate = value => {
    dispatch({
      type: currentCompany.settings.update.start,
      payload: { value, companyId },
    });
  };

  return (
    <>
      <Helmet>
        <title>{formatPageTitle(title.private.company.systemSettings, { companyName })}</title>
      </Helmet>
      <CompanySettingsForm
        users={users}
        data={data}
        loading={loading}
        updating={updating}
        error={error}
        success={success}
        handleUpdate={handleUpdate}
        companyType={companyType}
        userType={type}
      />
    </>
  );
};

export default CompanySettings;
