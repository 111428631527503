import { pages } from '../../actionTypes';

const initialState = {
  list: [],
  grade: null,
  loading: false,
};

export default function unofficialGrade(state = initialState, action) {
  switch (action.type) {
    case pages.dashboard.unofficialGrade.start:
      return { ...state, loading: true };
    case pages.dashboard.unofficialGrade.end:
      return {
        ...state,
        list: action.payload.list,
        grade: action.payload.mark,
        loading: false,
      };
    case pages.dashboard.unofficialGrade.error:
      return {
        ...state,
        loading: false,
      };

    default:
      return state;
  }
}
