import React from 'react';
import { Grid, IconButton } from '@material-ui/core';

const ButtonIcon = ({ handler, children }) => {
  return (
    <Grid item>
      <IconButton size="small" onClick={handler}>
        {children}
      </IconButton>
    </Grid>
  );
};

export default ButtonIcon;
