import React from 'react';
import Tooltip from '@material-ui/core/Tooltip';
import ErrorSharpIcon from '@material-ui/icons/ErrorSharp';
import CheckSharpIcon from '@material-ui/icons/CheckSharp';

import useStyles from './styles';

const validTooltipTitle = 'PQF filed';
const notValidTooltipTitle = 'PQF not filed';

const FillingCell = ({ valid }) => {
  const classes = useStyles();
  return (
    <Tooltip title={valid ? validTooltipTitle : notValidTooltipTitle}>
      {valid ? <CheckSharpIcon className={classes.success} /> : <ErrorSharpIcon className={classes.error} />}
    </Tooltip>
  );
};

export default FillingCell;
