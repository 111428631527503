import React, { useState } from 'react';
import { Card, CardContent, CardHeader, Collapse, IconButton } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';

import useStyles from './styles';

export const CollapseCard = ({ children, title, actions = [], collapse }) => {
  const classes = useStyles();

  const [open, setOpen] = useState(true);
  const actionStack = (
    <>
      {actions.map((action, index) => (
        <IconButton key={index} onClick={action.action} size="small" className={classes.button}>
          <action.icon fontSize="inherit" />
        </IconButton>
      ))}
      {collapse && (
        <IconButton onClick={() => setOpen(!open)} size="small" className={classes.button}>
          {open ? <ExpandLessIcon /> : <ExpandMoreIcon />}
        </IconButton>
      )}
    </>
  );

  return (
    <Card variant="outlined" className={classes.cardWrapper}>
      <CardHeader
        title={title}
        action={actionStack}
        className={classes.cardHeader}
        titleTypographyProps={{ variant: 'button' }}
      />
      {!!children && (
        <Collapse in={open}>
          <CardContent className={classes.cardContent}>{children}</CardContent>
        </Collapse>
      )}
    </Card>
  );
};

export default CollapseCard;
