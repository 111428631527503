import { createEnum, requestState } from '../utils/actionTypesHelpers';

export default createEnum(
  {
    list: createEnum(requestState, 'workingHours.list'),
    update: createEnum(requestState, 'workingHours.update'),
    exportWorkingHours: createEnum(requestState, 'workingHours.exportWorkingHours'),
    canExportWorkingHours: createEnum(requestState, 'workingHours.canExportWorkingHours'),
  },
  'workingHours',
);
