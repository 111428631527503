import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { title, sectionKey } from '../../routes/path';
import CreateOfficersForm from '../../containers/CreateOfficersForm';
import { currentCompany } from '../../../actionTypes';
import { formatPageTitle } from '../../../utils/textFormaters';
import { useCompanyInfo } from '../../../utils/effects/useCompanyInfo';
import { useUserPermissions } from '../../../utils/effects/userPermissions';

const CreateOfficers = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const { companyId, companyName } = useCompanyInfo(id);
  const { allowUpdate } = useUserPermissions(companyId);

  const { officers, loading, requestInProgress, success, error } = useSelector(state => state.currentCompany.officer);

  useEffect(() => {
    dispatch({ type: currentCompany.officer.get.start, companyId });
  }, [companyId, dispatch]);

  const handleSubmit = values => {
    const officersIds = Object.keys(values).map(officer => values[officer].id);

    officersIds.includes(0)
      ? dispatch({
          type: currentCompany.officer.create.start,
          payload: { companyId, values, sectionKey: sectionKey.private.company.officers },
        })
      : dispatch({
          type: currentCompany.officer.update.start,
          payload: { companyId, values, sectionKey: sectionKey.private.company.officers },
        });
  };

  return (
    <>
      <Helmet>
        <title> {formatPageTitle(title.private.company.officers, { companyName })} </title>
      </Helmet>
      <CreateOfficersForm
        handleSubmit={handleSubmit}
        dbValues={officers}
        loading={loading}
        requestInProgress={requestInProgress}
        success={success}
        error={error}
        allowUpdate={allowUpdate}
        companyId={companyId}
      />
    </>
  );
};

export default CreateOfficers;
