import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  paper: {
    minWidth: theme.spacing(100),
  },
  modalActionsRow: {
    padding: theme.spacing(1, 3, 2),
    display: 'block',
  },
}));

export default useStyles;
