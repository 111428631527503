import { makeStyles } from '@material-ui/core/styles';

export const iconSpacing = 6;

const useStyles = makeStyles(theme => ({
  menu: ({ height }) => ({
    minHeight: height,
    transition: 'all 0.3',
    position: 'relative',
    overflow: 'hidden',
    '& ul': {
      listStyle: 'none',
      margin: 0,
      padding: 0,
    },
  }),
  menuTab: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  icon: {
    width: theme.spacing(iconSpacing),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'orange',
  },
  submenuBlock: {
    position: 'absolute',
    top: 0,
    left: theme.spacing(iconSpacing),
    right: 0,
    bottom: 0,
    borderLeft: `2px solid ${theme.palette.grey['500']}`,
    backgroundColor: theme.palette.common.white,
    transform: 'translateX(110%)',
  },
  active: {
    '& > ul': {
      transform: 'translateX(0)',
    },
  },
}));

export default useStyles;
