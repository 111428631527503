import React, { useEffect } from 'react';
import { Field, Formik } from 'formik';
import { Grid, Button } from '@material-ui/core';

import { subsidiaryTableSchema } from '../../../../utils/validate/shemas';
import FormInput from '../../../components/FormInput';
import useStyles from './styles';

const WatchEffect = ({ setFieldTouched }) => {
  useEffect(() => {
    setFieldTouched('name', true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return null;
};

const SubsidiaryInputForm = ({ allowUpdate, initialTableValues, handleSubmit, tableData }) => {
  const classes = useStyles();

  const onSubmit = (values, { resetForm }) => {
    resetForm();
    handleSubmit(values);
  };

  return (
    <Formik
      initialValues={initialTableValues}
      validationSchema={() => subsidiaryTableSchema(tableData)}
      onSubmit={onSubmit}
      enableReinitialize
    >
      {props => {
        return (
          <>
            {allowUpdate && (
              <>
                {!tableData.length && <WatchEffect setFieldTouched={props.setFieldTouched} />}
                <Grid container direction="row" className={classes.tableWrapper}>
                  <Grid item xs={8} className={classes.nameInput}>
                    <Field label="Subsidiary" name="name" type="string" component={FormInput} />
                  </Grid>
                  <Grid item xs className={classes.buttonSection}>
                    <Button
                      size="small"
                      variant="contained"
                      color="primary"
                      type="button"
                      onClick={() => props.handleSubmit()}
                    >
                      Add
                    </Button>
                  </Grid>
                </Grid>
              </>
            )}
          </>
        );
      }}
    </Formik>
  );
};

export default SubsidiaryInputForm;
