import React from 'react';
import { Grid } from '@material-ui/core';
import RestoreIcon from '@material-ui/icons/Restore';

import Ellipsis from '../../../components/Ellipsis';
import IconWithPopUp from '../../../components/IconWithPopUp';

export default ({ handleRestoreUser }) => [
  {
    title: 'First name',
    render: ({ firstName }) => <Ellipsis title={firstName} />,
    width: '30%',
  },
  {
    title: 'Last name',
    render: ({ lastName }) => <Ellipsis title={lastName} />,
    width: '30%',
  },
  {
    title: 'Company name',
    render: ({ companyName }) => <Ellipsis title={companyName} />,
    width: '30%',
  },
  {
    title: 'Email',
    render: ({ emailAddress }) => <Ellipsis title={emailAddress} />,
    width: '30%',
  },
  {
    title: 'Actions',
    sorting: false,
    width: '10%',
    render: ({ id: userId, companyId }) => (
      <Grid item xs={12}>
        <Grid container alignItems="center" justify="center">
          <IconWithPopUp
            title="Are you sure you want to restore this user?"
            handler={() => handleRestoreUser(userId, companyId)}
            tooltipTitle="Restore"
          >
            <RestoreIcon size="small" color="primary" />
          </IconWithPopUp>
        </Grid>
      </Grid>
    ),
  },
];
