import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import get from 'lodash.get';

import FileUploader from '../../containers/FileUploader';
import formatPath from '../../../utils/textFormaters/formatPath';
import { fileUploadUrl, filePsmUploadUrl } from '../../../utils/constants';
import { filePsmMaxSize } from '../../../utils/constants/fileSizes';
import { file, filePsmAudit } from '../../../actionTypes';

const FileUploaderController = ({
  value,
  editable = true,
  error,
  expirationDate,
  defaultExpiration,
  completedOn,
  completedBy,
  listType,
  handlePsmSubmit,
  cardTitle,
  hasPermissions,
  sectionKey,
  companyId,
  uploaderTitle,
  handleUpdate,
  disabled,
}) => {
  const isDocumentList = listType === 'documentList';
  const url = isDocumentList
    ? formatPath(filePsmUploadUrl, { companyId })
    : formatPath(fileUploadUrl, { companyId, sectionKey });
  const dispatch = useDispatch();

  const maxFileSize = useSelector(store => store.settings.settings.data.maxFileSizeMb);
  const maxFileSizeUpload = isDocumentList ? filePsmMaxSize : maxFileSize;

  const { loading, list, downloadList } = useSelector(store =>
    get(store, isDocumentList ? `filePsmAudit` : `file.file.${sectionKey}`, {
      loading: true,
      list: [],
      downloadList: [],
      loadingOnAdd: false,
    }),
  );

  // clear field value on change list
  useEffect(() => {
    handleUpdate({ list, count: list.length, createList: [], deleteList: [] });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [list]);

  const handleDownload = fileId => {
    const isTempFile = !list.map(file => file.id).includes(fileId);
    if (isDocumentList) {
      dispatch({
        type: filePsmAudit.download.start,
        payload: {
          companyId,
          id: fileId,
        },
      });
    } else {
      dispatch({
        type: file.download.start,
        payload: {
          sectionKey,
          companyId,
          fileId,
          tempFile: isTempFile,
          fileName: isTempFile ? value.createList.filter(file => file.id === fileId)[0].fileName : null,
        },
      });
    }
  };

  const handleSaveFiles = newFiles => {
    const createList = [...value.createList, ...newFiles];
    handleUpdate({
      ...value,
      count: [...createList, ...list].filter(file => !value.deleteList.includes(file.id)).length,
      deleteList: value.deleteList,
      createList,
    });
    isDocumentList && handlePsmSubmit();
  };

  const handleDelete = fileId => {
    isDocumentList &&
      dispatch({
        type: filePsmAudit.remove.start,
        payload: { companyId, id: fileId },
      });

    const deleteOld = list.map(file => file.id).includes(fileId);
    const createList = (!deleteOld && value.createList.filter(file => file.id !== fileId)) || value.createList;
    const deleteList = (deleteOld && [...value.deleteList, fileId]) || value.deleteList;

    handleUpdate({
      ...value,
      count: [...createList, ...list].filter(file => !deleteList.includes(file.id)).length,
      createList,
      deleteList,
    });
  };

  return (
    <FileUploader
      url={url}
      editable={editable}
      error={error}
      hasPermissions={hasPermissions}
      maxFileSize={maxFileSizeUpload}
      uploaderTitle={uploaderTitle}
      expirationDate={expirationDate}
      defaultExpiration={defaultExpiration}
      completedBy={completedBy}
      completedOn={completedOn}
      listType={listType}
      cardTitle={cardTitle}
      files={value ? [...value.createList, ...list].filter(file => !value.deleteList.includes(file.id)) : []}
      loading={loading}
      deleteList={[]}
      downloadList={downloadList}
      handleDelete={handleDelete}
      handleDownload={handleDownload}
      handleSaveFiles={handleSaveFiles}
      disabled={disabled}
    />
  );
};

export default FileUploaderController;
