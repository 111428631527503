import { archive } from '../../actionTypes';

const initialState = {
  items: [],
  total: 0,
  loading: false,
  error: null,
};

export default function list(state = initialState, action) {
  switch (action.type) {
    case archive.company.get.start:
      return {
        ...initialState,
        loading: true,
      };
    case archive.company.get.end:
      return {
        ...state,
        loading: false,
        total: action.payload.total,
        items: action.payload.list,
      };
    case archive.company.get.error:
      return {
        ...state,
        error: action.payload.error,
      };

    case archive.company.recover.start:
      return { ...state, loading: true };
    case archive.company.recover.end:
      return {
        ...state,
        loading: false,
        total: action.payload.total,
        items: action.payload.list,
      };
    case archive.company.recover.error:
      return {
        ...state,
        loading: false,
      };

    default:
      return state;
  }
}
