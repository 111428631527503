import React from 'react';
import { useSelector } from 'react-redux';

import FileUploader from '../../containers/FileUploader';
import formatPath from '../../../utils/textFormaters/formatPath';

const SingleFileUploaderController = ({
  form,
  value,
  error,
  companyId,
  handleUpdate,
  fileNameFieldName,
  handleDownload,
  uploaderTitle,
  uploadUrl,
  editable,
  disabled,
}) => {
  const url = formatPath(uploadUrl, { companyId });

  const maxFileSizeUpload = useSelector(store => store.settings.settings.data.maxFileSizeMb);

  const { loading } = useSelector(store => store.addendumFile.addendumFile);

  const handleSaveFiles = newFiles => {
    handleUpdate(newFiles[0]);
  };

  return (
    <FileUploader
      url={url}
      editable={editable}
      error={error}
      hasPermissions={true}
      maxFileSize={maxFileSizeUpload}
      uploaderTitle={uploaderTitle}
      expirationDate={false}
      cardTitle={''}
      files={value ? [{ fileId: value, fileName: form.getFieldMeta(fileNameFieldName).value }] : []}
      loading={loading}
      handleDownload={handleDownload}
      handleSaveFiles={handleSaveFiles}
      single={true}
      disabled={disabled}
    />
  );
};

export default SingleFileUploaderController;
