import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  container: {
    border: 'none',
    textAlign: 'center',
    maxWidth: theme.spacing(15),
    width: '100%',
    cursor: 'pointer',
    margin: '0 auto',
  },
  title: ({ ownerGrade }) => ({
    fontSize: theme.typography.h5.fontSize,
    fontWeight: 700,
    textTransform: 'uppercase',
    padding: theme.spacing(0.5),
    backgroundColor: ownerGrade ? theme.palette.warning.dark : theme.palette.info.dark,
    color: theme.palette.common.white,
  }),
  body: {
    padding: theme.spacing(1, 2),
    fontSize: theme.spacing(5),
    lineHeight: '1.2',
    textTransform: 'uppercase',
    backgroundColor: theme.palette.grey['100'],
    border: `2px solid ${theme.palette.grey['500']}`,
    borderTop: 'none',
  },
  defaultBody: {
    padding: theme.spacing(1),
    fontSize: theme.spacing(3),
  },
  textAfter: {
    textAlign: 'center',
    fontSize: theme.typography.body2.fontSize,
    color: theme.palette.primary.main,
  },
}));

export default useStyles;
