import { put, takeLatest, call, all } from 'redux-saga/effects';

import dataProvider from '../../../store/dataProvider';
import { currentCompany, notification } from '../../../actionTypes';

export function* validation({ payload: { companyId, validationKey } }) {
  try {
    const [{ data }, conditions] = yield all([
      call(dataProvider.getOnly, `companies/${companyId}/pqf/validate/${validationKey}`, {
        filter: { fullValidation: true },
      }),
      call(dataProvider.getList, `companies/${companyId}/conditions`),
    ]);

    yield put({
      type: currentCompany.companyInfo.validation.end,
      payload: {
        validationKey,
        valid: data.sections.length === 0,
        validationFields: data.sections.reduce(
          (acc, item) => ({ ...acc, [item.name]: item.fields.map(field => field.name) }),
          {},
        ),
      },
    });

    yield put({
      type: currentCompany.companyInfo.updateConditions.update,
      payload: { status: conditions.data },
    });
  } catch (e) {
    yield put({
      type: currentCompany.companyInfo.validation.end,
      payload: { validationKey },
    });
    yield put({ type: notification.add, payload: { error: e.message } });
  }
}

export default function* getCompanyInfo() {
  yield takeLatest(currentCompany.companyInfo.validation.start, validation);
}
