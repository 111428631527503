import React from 'react';
import MaterialTable from 'material-table';

import columns from './columns';
import useStyles from './styles';

const CompanyTable = ({ list, loading, handleRestore, noFiltersSelected }) => {
  const classes = useStyles();

  return (
    <div className={classes.table}>
      <MaterialTable
        isLoading={loading}
        data={list}
        columns={columns({ handleRestore })}
        options={{
          sorting: false,
          search: false,
          editable: false,
          draggable: false,
          toolbar: false,
          paging: false,
          thirdSortClick: false,
          tableLayout: 'fixed',
        }}
        localization={{
          body: {
            emptyDataSourceMessage: noFiltersSelected ? `There are no archived companies yet` : 'No Results Found',
          },
        }}
      />
    </div>
  );
};

export default CompanyTable;
