import { currentCompany } from '../../actionTypes';

const initialState = {
  organizationInfo: {},
  organizationArrays: [],
  requestInProgress: false,
  loading: false,
  success: false,
  error: false,
};

export default function update(state = initialState, action) {
  switch (action.type) {
    case currentCompany.organizationInfo.get.start:
      return {
        ...initialState,
        loading: true,
      };

    case currentCompany.organizationInfo.get.end:
      return {
        ...state,
        loading: false,
        organizationInfo: action.payload.organizationInfo,
        organizationArrays: action.payload.organizationArrays,
      };

    case currentCompany.organizationInfo.get.error:
      return {
        ...state,
        loading: false,
        organizationInfo: {},
        organizationArrays: [],
      };

    case currentCompany.organizationInfo.update.start:
      return { ...state, requestInProgress: true, loading: true };

    case currentCompany.organizationInfo.update.end:
      return {
        ...state,
        loading: false,
        requestInProgress: false,
        organizationInfo: action.payload.organizationInfo,
        organizationArrays: action.payload.organizationArrays,
        success: true,
        error: false,
      };

    case currentCompany.organizationInfo.update.error:
      return {
        ...state,
        loading: false,
        requestInProgress: false,
        success: false,
        error: true,
      };

    default:
      return state;
  }
}
