import { createEnum } from '../actionTypesHelpers';

export const maxIntNumber = 2147483647;

export const favoriteEnum = createEnum({
  true: 'Favorite',
  false: 'Not favorite',
});

export const overdueEnum = createEnum({
  true: 'Expired',
  false: 'Actual',
});

export const enums = {
  frequency: createEnum({
    daily: 'Daily',
    weekly: 'Weekly',
    monthly: 'Monthly',
    quarterly: 'Quarterly',
    annually: 'Annually',
    asNeeded: 'As Needed',
    uponHire: 'Upon Hire',
    biweekly: 'Bi-weekly',
  }),
  yesno: createEnum({
    Yes: 'Yes',
    No: 'No',
  }),
  yesnona: createEnum({
    Yes: 'Yes',
    No: 'No',
    'N/A': 'N/A',
  }),
  fleetIndividualInsurance: createEnum({
    Fleet: 'Fleet',
    Individual: 'Individual',
  }),
};

export const yesNoOptions = [
  {
    label: 'Yes',
    value: true,
  },
  {
    label: 'No',
    value: false,
  },
];

function createOptionsEnum(obj) {
  return Object.keys(obj).reduce((acc, key) => [...acc, { value: key, label: obj[key] }], []);
}

export const frequencyOptions = createOptionsEnum(enums.frequency);
export const yesnoOptions = createOptionsEnum(enums.yesno);
export const yesnonaOptions = createOptionsEnum(enums.yesnona);
export const fleetIndividualInsurance = createOptionsEnum(enums.fleetIndividualInsurance);
export const favoriteOptions = createOptionsEnum(favoriteEnum);
export const overdueOptions = createOptionsEnum(overdueEnum);
