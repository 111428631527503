import { settings } from '../../actionTypes';

const initialState = {
  loading: true,
  updating: false,
  data: [],
  success: false,
  error: false,
};

export default function BLSDataReducer(state = initialState, action) {
  switch (action.type) {
    case settings.BLSData.get.start:
      return { ...state, loading: true };

    case settings.BLSData.get.end:
      return {
        ...state,
        loading: false,
        data: action.payload.data,
      };
    case settings.BLSData.get.error:
      return {
        ...state,
        loading: false,
        data: [],
      };

    case settings.BLSData.update.start:
      return { ...state, updating: true };

    case settings.BLSData.update.end:
      return {
        ...state,
        updating: false,
        data: state.data.filter(x => !action.payload.data.some(y => y.id === x.id)).concat(action.payload.data),
        success: true,
        error: false,
      };
    case settings.BLSData.update.error:
      return {
        ...state,
        updating: false,
        success: false,
        error: true,
      };

    default:
      return state;
  }
}
