import React from 'react';
import { Button, Grid, CircularProgress, AppBar } from '@material-ui/core';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import CloudDoneIcon from '@material-ui/icons/CloudDone';

import useStyles from './styles';

const Wrapper = ({ fixed, className, children }) =>
  fixed ? (
    <AppBar position="fixed" className={className} component="div" color="transparent">
      {children}
    </AppBar>
  ) : (
    children
  );

const FormButtons = ({
  fixed,
  handlePreview,
  hasValues,
  isValid,
  disabled,
  loading,
  error,
  success,
  onClick,
  position = 'flex-end',
}) => {
  const classes = useStyles({ loading, fixed });

  return (
    <>
      <Grid item xs={12} className={classes.placeholder}>
        <Grid container>
          <Grid item xs={12}>
            <Wrapper fixed={fixed} className={classes.submitAppBar}>
              <Grid container spacing={1} justify={position} alignItems="center" className={classes.fixed}>
                <Grid item>
                  <Grid container alignItems="center">
                    {loading && <CircularProgress size={30} className={classes.loading} />}
                    {!loading && error && <HighlightOffIcon className={classes.error} />}
                    {!loading && success && !error && <CloudDoneIcon className={classes.success} />}
                    <Button
                      disabled={disabled}
                      onClick={onClick}
                      size="small"
                      variant="contained"
                      color="primary"
                      className={classes.buttonStyles}
                    >
                      save
                    </Button>
                  </Grid>
                </Grid>
                <Grid item>
                  <Button
                    disabled={!isValid || !hasValues}
                    onClick={handlePreview}
                    size="small"
                    variant="contained"
                    color="secondary"
                    className={classes.buttonStyles}
                  >
                    preview
                  </Button>
                </Grid>
              </Grid>
            </Wrapper>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default FormButtons;
