import { call, put, takeLatest } from 'redux-saga/effects';

import { addendumFile, notification } from '../../actionTypes';
import dataProvider from '../../store/dataProvider';

export function* downloadByFormBuilderFieldAnswerId({ payload: { companyId, fileId, tempFile, fileName } }) {
  try {
    const params = tempFile ? { filter: { fileName } } : {};
    yield call(
      dataProvider.getOnly,
      `companies/${companyId}/addendum/file/${tempFile ? 'temp-download' : 'download/by-form-builder-field-answer-id'}/${fileId}`,
      params,
    );

    yield put({ type: addendumFile.downloadByFormBuilderFieldAnswerId.end, payload: { fileId } });
  } catch (e) {
    yield put({ type: notification.add, payload: { error: e.message } });
    yield put({ type: addendumFile.downloadByFormBuilderFieldAnswerId.error, payload: { fileId } });
  }
}

export default function* downloadAddendumFileByFormBuilderFieldAnswerIdSaga() {
  yield takeLatest(addendumFile.downloadByFormBuilderFieldAnswerId.start, downloadByFormBuilderFieldAnswerId);
}
