import React from 'react';
import { Grid, Typography, Select, MenuItem } from '@material-ui/core';

import useStyles from './styles';

const SelectRow = ({ defaultValue, options, onChange }) => {
  const classes = useStyles();
  return (
    <Grid container alignItems="center">
      <Typography variant="body1" className={classes.typography}>
        Rows per page:
      </Typography>
      <Select
        disableUnderline
        classes={{ root: classes.root, select: classes.select }}
        value={defaultValue}
        onChange={e => onChange(e.target.value)}
      >
        {options.map(option => (
          <MenuItem key={option} value={option}>
            {option}
          </MenuItem>
        ))}
      </Select>
    </Grid>
  );
};

export default SelectRow;
