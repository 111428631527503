import { createEnum, requestState } from '../utils/actionTypesHelpers';

export default createEnum(
  {
    check: createEnum(requestState, 'auth.check'),
    login: createEnum(requestState, 'auth.login'),
    logout: createEnum(requestState, 'auth.logout'),
    resetPassword: createEnum(requestState, 'auth.resetPassword'),
    setNewPassword: createEnum(requestState, 'auth.setNewPassword'),
    acceptInvitation: createEnum(requestState, 'auth.acceptInvitation'),
  },
  'auth',
);
