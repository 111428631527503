import { call, put, takeLatest } from 'redux-saga/effects';

import { currentCompany, notification } from '../../../actionTypes';
import dataProvider from '../../../store/dataProvider';

export function* deleteReference({ payload: { companyId, itemId, sectionKey } }) {
  try {
    yield call(dataProvider.deleteOne, `companies/${companyId}/references`, { id: itemId });
    yield put({ type: currentCompany.reference.delete.end, payload: itemId });

    // update PQF document validation
    yield put({
      type: currentCompany.companyInfo.validation.start,
      payload: { companyId: companyId, validationKey: sectionKey },
    });
  } catch (e) {
    yield put({ type: notification.add, payload: { error: e.message } });
    yield put({ type: currentCompany.reference.delete.error });
  }
}

export default function* createReferenceSaga() {
  yield takeLatest(currentCompany.reference.delete.start, deleteReference);
}
