import React from 'react';
import { Formik, FastField } from 'formik';
import { Grid, Table, TableHead, TableBody, TableRow, TableCell, Typography } from '@material-ui/core';
import get from 'lodash.get';

import LoadingButton from '../../components/LoadingButton';
import FormCheckbox from '../../components/FormCheckbox';
import PageLoader from '../../components/PageLoader';
import FormBody from '../../components/FormBody';
import useStyles from './styles';
import PromptNotification from '../../components/PromptComponent';

const CategoriesForm = ({
  categories,
  groups,
  handleSubmit,
  loading,
  success,
  error,
  allowUpdate,
  allowedCategories,
}) => {
  const classes = useStyles();

  const allCategories = groups.reduce((acc, group) => [...acc, ...group.workCategories], []);

  const initialValues = allCategories.reduce((acc, categoryId) => {
    return {
      ...acc,
      [categoryId]: {
        isEmployee: allowedCategories.includes(categoryId) && get(categories, `${categoryId}.isEmployee`, false),
        isSubContractor:
          allowedCategories.includes(categoryId) && get(categories, `${categoryId}.isSubContractor`, false),
      },
    };
  }, {});

  if (loading && !groups.length) {
    return <PageLoader />;
  }

  const normalizeSubmit = values => {
    const data = Object.keys(values)
      .map(id => ({ id, ...values[id] }))
      .filter(item => item.isEmployee || item.isSubContractor);
    handleSubmit(data);
  };

  return (
    <Formik initialValues={initialValues} onSubmit={normalizeSubmit}>
      {props => (
        <>
          <PromptNotification formStatus={props.dirty} />
          <FormBody loading={loading} disabled={!allowUpdate}>
            <Grid item xs={12}>
              <Typography variant="body1" gutterBottom className={classes.titleOffset}>
                Check the categories in which you are interested in bidding and in which you are qualified to perform
                work. Attach additional information clarifying capabilities and specialties
              </Typography>
              <Grid container spacing={2}>
                {groups.map(group => (
                  <Grid item lg={6} key={group.id}>
                    <Table size="small">
                      <TableHead>
                        <TableRow className={classes.tableTitle}>
                          <TableCell colSpan={3} align="left">
                            {group.name}
                          </TableCell>
                        </TableRow>
                        <TableRow className={classes.columnTitle}>
                          <TableCell align="center"> Employee </TableCell>
                          <TableCell align="center"> Sub-contractor </TableCell>
                          <TableCell align="center"> Work Category </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {group.workCategories.map(category => (
                          <TableRow key={category} className={classes.tableBody}>
                            <TableCell align="center" component="th" scope="row">
                              <FastField
                                name={`${category}.isEmployee`}
                                component={FormCheckbox}
                                labelClassName={classes.checkboxContainer}
                              />
                            </TableCell>
                            <TableCell align="center">
                              <FastField
                                name={`${category}.isSubContractor`}
                                component={FormCheckbox}
                                labelClassName={classes.checkboxContainer}
                              />
                            </TableCell>
                            <TableCell> {categories[category].name} </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </Grid>
                ))}
              </Grid>
            </Grid>
            {allowUpdate && (
              <LoadingButton
                fixed
                disabled={!props.dirty || !props.isValid}
                text="save"
                loading={loading}
                onClick={props.handleSubmit}
                success={success}
                error={error}
              />
            )}
          </FormBody>
        </>
      )}
    </Formik>
  );
};

export default CategoriesForm;
