import React from 'react';
import { SortableContainer } from 'react-sortable-hoc';
import { Grid } from '@material-ui/core';
import { useFormikContext } from 'formik';

import SingleQuestion from '../SingleQuestion';

const handleRemove = (key, values, setFieldValue) => {
  const currentParent = values.fields[key].parentId;

  const clearValues = Object.keys(values.fields).reduce(
    (acc, item) => (item === key ? acc : { ...acc, [item]: values.fields[item] }),
    {},
  );

  const sortValues = Object.keys(clearValues)
    .filter(item => values.fields[item].parentId === currentParent)
    .sort(function(a, b) {
      return clearValues[a].order - clearValues[b].order;
    })
    .reduce(
      (acc, item, index) => ({
        ...acc,
        [item]: {
          ...clearValues[item],
          order: index + 1,
        },
      }),
      clearValues,
    );

  setFieldValue('fields', sortValues, true);
};

const SortableList = SortableContainer(({ childrenKeys, companyId }) => {
  const { setFieldValue, values } = useFormikContext();
  return (
    <Grid container spacing={2}>
      {childrenKeys
        .sort(function(a, b) {
          return values.fields[a].order - values.fields[b].order;
        })
        .map((keyName, index) => {
          return (
            <SingleQuestion
              key={`item-${keyName}`}
              setFieldValue={setFieldValue}
              index={index}
              handleRemove={() => handleRemove(keyName, values, setFieldValue)}
              name={`fields.${keyName}`}
              companyId={companyId}
            />
          );
        })}
    </Grid>
  );
});

export default SortableList;
