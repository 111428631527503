import React from 'react';
import { FastField } from 'formik';
import { Grid } from '@material-ui/core';

import FormInput from '../../../components/FormInput';
import FormDatePicker from '../../../components/FormDataPicker';
import FormSelect from '../../../components/FormSelect';
import useStyles from './styles';
import companyRoleTypes from '../../../../utils/constants/companyRoleTypes';
import FormNumberInput from '../../../components/FormNumberInput';

const CompanyInfoSubForm = ({ names, isBasicContractor, isSafetyCouncilUserType }) => {
  const classes = useStyles();

  const { type, name, fax, url, taxId, year } = names;

  return (
    <>
      {isSafetyCouncilUserType && (
        <FastField
          className={classes.inputRow}
          label="Type"
          name={type}
          id="createCompanyRole"
          options={companyRoleTypes}
          component={FormSelect}
        />
      )}
      <FastField className={classes.inputRow} label="Company Name" name={name} component={FormInput} />
      <FastField className={classes.inputRow} label="Fax" type="tel" name={fax} component={FormInput} />
      <FastField className={classes.inputRow} label="Web Site" name={url} component={FormInput} />
      <FastField
        className={classes.inputRow}
        label="Tax ID"
        name={taxId}
        maskType="fromOne"
        component={FormNumberInput}
      />
      {!isBasicContractor && (
        <Grid container className={classes.calendarWrapper}>
          <FastField
            className={classes.inputRow}
            label="Year Established Under Present Firm Name"
            disableToolbar
            name={year}
            id="yearCreated"
            format="MM/yyyy"
            views={['year', 'month']}
            margin="normal"
            variant="inline"
            disableFuture
            component={FormDatePicker}
          />
        </Grid>
      )}
    </>
  );
};

export default CompanyInfoSubForm;
