import React from 'react';
import { Grid, TableCell, TableRow } from '@material-ui/core';
import { Field, Formik } from 'formik';
import IconButton from '@material-ui/core/IconButton';
import CancelIcon from '@material-ui/icons/Cancel';
import DoneIcon from '@material-ui/icons/Done';

import FormInput from '../../../components/FormInput';
import { subsidiaryTableSchema } from '../../../../utils/validate/shemas';

const EditForm = ({ values, handleSubmit, setItemToEdit }) => {
  return (
    <Formik initialValues={values} onSubmit={handleSubmit} validationSchema={() => subsidiaryTableSchema()}>
      {props => {
        return (
          <TableRow>
            <TableCell align="center">
              <Grid container alignItems="center">
                <Field label="Subsidiary" multiline name="name" type="string" component={FormInput} />
              </Grid>
            </TableCell>
            <TableCell align="center">
              <Grid container direction="row" justify="center" alignItems="center">
                <IconButton aria-label="Done" color="primary" onClick={() => props.handleSubmit()}>
                  <DoneIcon />
                </IconButton>
                <IconButton aria-label="Cancel" color="default" onClick={() => setItemToEdit(null)}>
                  <CancelIcon />
                </IconButton>
              </Grid>
            </TableCell>
          </TableRow>
        );
      }}
    </Formik>
  );
};

export default EditForm;
