import React, { useState } from 'react';
import { Button, Dialog, DialogActions, DialogTitle, Typography, DialogContent } from '@material-ui/core';
import { Formik } from 'formik';

import FileUploads from '../FileUploads';
import useStyles from './styles';
import { fileUploaderPopup } from '../../../../utils/validate/shemas';
import { acceptFormat } from '../../../../utils/enums/fileFormats';
import { httpStatusCodes } from '../../../../utils/constants';

const UploaderModal = ({
  state,
  expirationDate,
  defaultExpiration,
  isDocumentList,
  completedBy,
  completedOn,
  url,
  maxFileSize,
  handleClose,
  handleSave,
  single,
}) => {
  const classes = useStyles();
  const [uploadedFiles, setUploadedFiles] = useState({});

  const [allFilesIsUploaded, setAllFilesIsUploaded] = useState(false);

  const formSubmit = (values, { resetForm }) => {
    handleSave(
      values.files.map(item => ({
        ...uploadedFiles[item.id].data,
        id: uploadedFiles[item.id].data.fileId,
        fileName: item.fileName,
        description: item.description,
        expirationDate: item.expirationDate,
        completedOn: item.completedOn,
        completedBy: item.completedBy,
      })),
    );
    setUploadedFiles({});
    resetForm();
  };

  return (
    <Formik
      initialValues={{ files: [] }}
      onSubmit={formSubmit}
      validationSchema={() => fileUploaderPopup(expirationDate, completedOn, completedBy)}
    >
      {({ values, resetForm, isValid, handleSubmit }) => {
        const hasErrorOnUploadedFile = values.files.reduce(
          (acc, item) =>
            acc ||
            (uploadedFiles[item.id] &&
              (uploadedFiles[item.id].status >= httpStatusCodes.maxValidCode ||
                uploadedFiles[item.id].status < httpStatusCodes.minValidCode)),
          false,
        );
        return (
          <Dialog
            open={state}
            onClose={() => {
              handleClose(resetForm);
              setUploadedFiles({});
            }}
            classes={{
              paper: classes.modalContainer,
            }}
          >
            <DialogTitle disableTypography>
              <Typography variant="subtitle1">
                Upload the file in {isDocumentList ? acceptFormat.psmAuditFormat : acceptFormat.commonFileFormat} format{' '}
                {maxFileSize}mb max
              </Typography>
            </DialogTitle>
            <DialogContent>
              <FileUploads
                values={values}
                url={url}
                expirationDate={expirationDate}
                defaultExpiration={defaultExpiration}
                completedBy={completedBy}
                completedOn={completedOn}
                accept={isDocumentList ? acceptFormat.psmAuditFormat : acceptFormat.commonFileFormat}
                isDocumentList={isDocumentList}
                maxFileSize={maxFileSize}
                single={single}
                handleUploaded={files => {
                  setUploadedFiles(prevState => ({ ...prevState, ...files }));
                  setAllFilesIsUploaded(true);
                }}
                onUploadStart={() => setAllFilesIsUploaded(false)}
              />
            </DialogContent>
            <DialogActions className={classes.actionOffsets}>
              <Button
                variant="contained"
                disabled={!isValid || !allFilesIsUploaded || hasErrorOnUploadedFile}
                size="small"
                color="primary"
                onClick={handleSubmit}
              >
                Save
              </Button>
              <Button variant="contained" size="small" onClick={() => handleClose(resetForm)}>
                Cancel
              </Button>
            </DialogActions>
          </Dialog>
        );
      }}
    </Formik>
  );
};

export default UploaderModal;
