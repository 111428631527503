import React from 'react';
import { Grid, Paper, Table, TableBody, TableCell, TableRow, Typography } from '@material-ui/core';

import { formatInjuryValue } from '../../../../../utils/containersHelpers';
import useStyles from './styles';

const InjuryAndIllnessTable = ({ data }) => {
  const classes = useStyles();
  return (
    <Grid container component={Paper}>
      <Table>
        <TableBody className={classes.tableBody}>
          <TableRow>
            <TableCell>Year</TableCell>
            {data.map(year => (
              <TableCell key={year.id}>
                <Grid item xs={12}>
                  <Typography variant="body1" align="center">
                    {year.year}
                  </Typography>
                </Grid>
              </TableCell>
            ))}
          </TableRow>
          <TableRow>
            <TableCell>Total hours</TableCell>
            {data.map(year => (
              <TableCell key={year.id}>
                <Grid item xs={12}>
                  <Typography variant="body1" align="center">
                    {year.totalHours}
                  </Typography>
                </Grid>
              </TableCell>
            ))}
          </TableRow>
          <TableRow>
            <TableCell />
            {data.map(year => (
              <TableCell key={year.id}>
                <Grid item xs={12}>
                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                      <Typography variant="body1" align="center">
                        No.
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography variant="body1" align="center">
                        Rate
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </TableCell>
            ))}
          </TableRow>
          <TableRow>
            <TableCell>Fatalities</TableCell>
            {data.map(year => (
              <TableCell key={year.id}>
                <Grid item xs={12}>
                  <Grid container spacing={1}>
                    <Grid item xs={6}>
                      <Typography variant="body1" align="center">
                        {year.fatalities}
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography variant="body1" align="center">
                        {formatInjuryValue(year.fatalitiesRate)}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </TableCell>
            ))}
          </TableRow>
          <TableRow>
            <TableCell>
              Total number of cases with days away from work, job transfer, or restrictions (H + I + J) [DART]
            </TableCell>
            {data.map(year => (
              <TableCell key={year.id}>
                <Grid item xs={12}>
                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                      <Typography variant="body1" align="center">
                        {year.lostWorkdayRestricted}
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography variant="body1" align="center">
                        {formatInjuryValue(year.lostWorkdayRestrictedRate)}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </TableCell>
            ))}
          </TableRow>
          <TableRow>
            <TableCell>Total number of cases with days away from work (H) [LWDI]</TableCell>
            {data.map(year => (
              <TableCell key={year.id}>
                <Grid item xs={12}>
                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                      <Typography variant="body1" align="center">
                        {year.lostWorkdayAway}
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography variant="body1" align="center">
                        {formatInjuryValue(year.lostWorkdayAwayRate)}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </TableCell>
            ))}
          </TableRow>
          <TableRow>
            <TableCell>Total number of injuries and illnesses involving medical treatment only (I + J) [RWC]</TableCell>
            {data.map(year => (
              <TableCell key={year.id}>
                <Grid item xs={12}>
                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                      <Typography variant="body1" align="center">
                        {year.injuriesAndIllnessMedicalOnly}
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography variant="body1" align="center">
                        {formatInjuryValue(year.injuriesAndIllnessMedicalOnlyRate)}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </TableCell>
            ))}
          </TableRow>
          <TableRow>
            <TableCell>Total number of injuries and illnesses (M) [TRIR]</TableCell>
            {data.map(year => (
              <TableCell key={year.id}>
                <Grid item xs={12}>
                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                      <Typography variant="body1" align="center">
                        {year.totalOsha}
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography variant="body1" align="center">
                        {formatInjuryValue(year.totalOshaRate)}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </TableCell>
            ))}
          </TableRow>
          <TableRow>
            <TableCell>Total number of days away from work (K) [SR]</TableCell>
            {data.map(year => (
              <TableCell key={year.id}>
                <Grid item xs={12}>
                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                      <Typography variant="body1" align="center">
                        {year.totalDaysAwayFromWork}
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography variant="body1" align="center">
                        {formatInjuryValue(year.totalDaysAwayFromWorkRate)}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </TableCell>
            ))}
          </TableRow>
        </TableBody>
      </Table>
    </Grid>
  );
};

export default InjuryAndIllnessTable;
