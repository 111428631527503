import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  inputRow: {
    margin: theme.spacing(1, 0),
  },
  itemContainer: {
    padding: theme.spacing(0, 1),
  },
  subFormContainer: {
    marginLeft: theme.spacing(20),
  },
  divider: {
    margin: theme.spacing(3, 0),
  },
  formRadioContainer: {
    '& > h6': {
      marginRight: theme.spacing(4),
    },
  },
}));

export default useStyles;
