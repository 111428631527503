import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  table: {
    '& .MuiTableCell-head': {
      backgroundColor: theme.palette.background.primary.main,
      color: 'white',
    },
    '& .MuiTableSortLabel-root': {
      color: 'white',
      '&:hover': {
        color: 'white',
      },
    },
    '& .MuiTableRow-root': {
      '&:hover': {
        backgroundColor: 'rgb(224, 224, 224)',
      },
    },
    '& .MuiLink-root': {
      maxWidth: 'fit-content',
    },
  },
}));

export default useStyles;
