import createAuthProvider from '../utils/providerHelpers/createAuthProvider';
import { saveTokens, clearTokens, hasTokens, getPermissions } from '../utils/providerHelpers/tokensHelper';
import { urls, errorMessages, methods } from './constants';

const authProvider = createAuthProvider(
  { saveTokens, clearTokens, hasTokens, getPermissions },
  urls,
  errorMessages,
  methods,
);

export default authProvider;
