import merge from 'lodash.merge';

import { entity } from '../../actionTypes';

const initialState = {};

export default function add(state = initialState, { type, payload }) {
  switch (type) {
    case entity.add:
      return merge({}, state, payload);

    default:
      return state;
  }
}
