import { all, call, put } from 'redux-saga/effects';

import { file, notification } from '../../actionTypes';
import dataProvider from '../../store/dataProvider';

export function* updateFiles({ deleteList, createList, companyId, sectionKey }) {
  try {
    const [saveFiles] = yield all([
      createList.length
        ? call(
            dataProvider.createList,
            `companies/${companyId}/related-document/${sectionKey}/save`,
            createList.map(item => {
              const { id, ...last } = item;
              return last;
            }),
          )
        : [],
      deleteList.length
        ? call(dataProvider.deleteList, `companies/${companyId}/related-document/${sectionKey}`, deleteList)
        : [],
    ]);
    if (deleteList.length) {
      yield put({ type: file.remove.end, payload: { sectionKey, fileId: deleteList } });
    }
    if (createList.length) {
      yield put({ type: file.add.end, payload: { sectionKey, files: saveFiles.data } });
    }
  } catch (e) {
    yield put({ type: notification.add, payload: { error: e.message } });
    if (deleteList.length) {
      yield put({ type: file.remove.error, payload: { files: deleteList } });
    }
    yield put({ type: file.add.error });
  }
}
