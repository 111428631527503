import React, { useEffect } from 'react';
import { Typography, Grid, Collapse } from '@material-ui/core';

import { createFileValue } from '../../../utils/pageHelpers';
import useStyles from './styles';

const textType = 'Text';
const sectionType = 'Section';
const uploadsType = 'Uploads';

const QuestionStructure = ({ show, values, item, fields, deep = 0, setFieldValue, setFieldTouched }) => {
  const classes = useStyles({ deep, offsetTop: item.type === sectionType });

  // this effect use for clean form value if current field is hide
  // its couldn't replace to field level because it broke FastField performance
  useEffect(() => {
    if (!show && fields[item.key]) {
      setFieldValue(item.key, item.type === uploadsType ? createFileValue(item.answer.list, true) : '', false);
      setFieldTouched(item.key, false, false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [show]);

  return (
    (fields[item.key] && (
      <Collapse in={show} timeout={300}>
        <div key={`render_${item.key}`}>
          <Grid container alignItems="center" className={classes.parentContainer}>
            {item.type === textType || item.type === uploadsType ? (
              <Grid item xs={12} className={classes.deepOffset}>
                {fields[item.key]}
              </Grid>
            ) : (
              <>
                <Grid item xs={item.type === sectionType ? 12 : 8}>
                  <Typography variant={item.type === sectionType ? 'h5' : 'subtitle2'} className={classes.deepOffset}>
                    {item.text}
                  </Typography>
                </Grid>
                <Grid item xs={4}>
                  {fields[item.key]}
                </Grid>
              </>
            )}
          </Grid>
          {!!item.children.length && (
            <>
              {item.children.map(level => (
                <QuestionStructure
                  show={
                    show &&
                    !(level.showOnCondition && level.parent && values[level.parent] !== level.parentAnswerCondition)
                  }
                  values={values}
                  key={level.key}
                  item={level}
                  fields={fields}
                  deep={deep + 1}
                  setFieldValue={setFieldValue}
                  setFieldTouched={setFieldTouched}
                />
              ))}
            </>
          )}
        </div>
      </Collapse>
    )) ||
    null
  );
};

export default React.memo(QuestionStructure);
