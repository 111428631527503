import React from 'react';
import { Grid, Typography, Button } from '@material-ui/core';

import useStyles from './styles';

export const ReviewReject = ({ item, companyId, dismissHandler }) => {
  const classes = useStyles();

  return (
    <>
      <Typography variant="body1" gutterBottom>
        Your PQF was rejected by Safety council. Check your email for more details
      </Typography>
      <Grid container justify="center" alignItems="flex-end" className={classes.buttonsWrapper}>
        <Button variant="contained" size="small" onClick={() => dismissHandler(companyId, item.id)}>
          Dismiss
        </Button>
      </Grid>
    </>
  );
};
