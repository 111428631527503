import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  favoriteIcon: {
    color: theme.palette.warning.main,
  },
  notFavoriteIcon: {
    color: theme.palette.warning.main,
  },
}));

export default useStyles;
