import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  card: ({ warning, success, error }) => ({
    padding: theme.spacing(2),
    backgroundColor:
      (success && theme.palette.success.light) ||
      (error && theme.palette.error.main) ||
      (warning && theme.palette.background.warning) ||
      theme.palette.background.error,
  }),
  wrapper: {
    width: '100%',
    padding: theme.spacing(0, 1),
  },
  container: {
    padding: theme.spacing(1, 0),
  },
  button: {
    margin: theme.spacing(0, 2),
  },
  progress: {
    verticalAlign: 'middle',
  },
}));

export default useStyles;
