import React from 'react';
import get from 'lodash.get';

import SingleFileUploaderController from '../../layouts/SingleFileUploaderController';

const SingleFileUploaderInput = ({
  form,
  field,
  companyId,
  fileNameFieldName,
  label,
  handleDownload,
  uploadUrl,
  disabled,
  editable,
}) => {
  const handleUpdate = file => {
    form.setFieldValue(fileNameFieldName, file?.description);
    form.setFieldTouched(field.name, true);
    form.setFieldValue(field.name, file?.fileId, true);
  };

  const error = get(form, `errors[${field.name}]`, undefined);
  const touched = get(form, `touched[${field.name}]`, undefined);

  return (
    <SingleFileUploaderController
      form={form}
      value={field.value}
      error={touched && error}
      companyId={companyId}
      handleUpdate={handleUpdate}
      fileNameFieldName={fileNameFieldName}
      handleDownload={handleDownload}
      uploaderTitle={label}
      uploadUrl={uploadUrl}
      disabled={disabled}
      editable={editable}
    />
  );
};

export default SingleFileUploaderInput;
