import { all, call, put, takeLatest } from 'redux-saga/effects';

import { currentCompany, notification } from '../../../actionTypes';
import dataProvider from '../../../store/dataProvider';

export function* get({ payload: { companyId } }) {
  try {
    const [settingsObject, users] = yield all([
      call(dataProvider.getOnly, `companies/${companyId}/configuration`),
      call(dataProvider.getList, `Companies/${companyId}/Users`, {}),
    ]);

    yield put({
      type: currentCompany.settings.get.end,
      payload: {
        data: settingsObject.data,
        users: users.data,
      },
    });
  } catch (e) {
    yield put({ type: currentCompany.settings.get.error });
    yield put({ type: notification.add, payload: { error: e.message } });
  }
}

export default function* getSettings() {
  yield takeLatest(currentCompany.settings.get.start, get);
}
