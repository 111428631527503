import { call, put, takeLatest } from 'redux-saga/effects';

import { company, notification } from '../../actionTypes';
import dataProvider from '../../store/dataProvider';

export function* exportXlsx({ payload }) {
  try {
    yield call(dataProvider.getOnly, 'Companies/xlsx', payload);

    yield put({ type: company.export.end, payload: {} });
  } catch (e) {
    yield put({ type: notification.add, payload: { error: e.message } });
    yield put({ type: company.export.error, payload: { error: e.message } });
  }
}

export default function* exportXlsxSaga() {
  yield takeLatest(company.export.start, exportXlsx);
}
