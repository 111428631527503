import { call, put, takeLatest } from 'redux-saga/effects';

import { currentCompany, file, notification } from '../../../actionTypes';
import dataProvider from '../../../store/dataProvider';
import { uploadHelpers } from '../../../utils/pageHelpers';

export function* get({ payload: { companyId, sectionKey, param } }) {
  try {
    const { data } = yield call(dataProvider.getList, `companies/${companyId}/yes-no-questions/${sectionKey}`, param);

    const { uploads, ...questionsData } = data;
    const { uploadValues } = uploadHelpers(uploads);

    const updateYesNoAnswer = questionsData.data.map(item =>
      Object.keys(uploadValues).includes(item.key) ? { ...item, answer: uploadValues[item.key] } : item,
    );

    yield put({
      type: file.get.end,
      payload: Object.keys(uploads).reduce((acc, item) => ({ ...acc, [item]: uploads[item] }), {}),
    });

    yield put({
      type: currentCompany.yesNoQuestions.get.end,
      payload: { sectionKey, stack: updateYesNoAnswer, disabler: questionsData.disabler },
    });
  } catch (e) {
    yield put({
      type: currentCompany.yesNoQuestions.get.end,
      payload: { sectionKey, stack: [] },
    });
    yield put({ type: notification.add, payload: { error: e.message } });
  }
}

export default function* getRespiratoryProtectionSaga() {
  yield takeLatest(currentCompany.yesNoQuestions.get.start, get);
}
