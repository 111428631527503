import React from 'react';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';

const FormCheckbox = ({
  labelPlacement = 'start',
  labelClassName,
  handleChange,
  size = 'small',
  checkboxClassName,
  field: { onChange, ...fieldProps },
  label,
  ...props
}) => {
  return (
    <FormControlLabel
      control={
        <Checkbox
          {...props}
          {...fieldProps}
          variant="outlined"
          size={size}
          type="checkbox"
          className={checkboxClassName}
          checked={fieldProps.value}
          onChange={e => {
            handleChange && handleChange(e, fieldProps);
            onChange(e);
          }}
        />
      }
      label={label}
      labelPlacement={labelPlacement}
      className={labelClassName}
    />
  );
};

export default FormCheckbox;
