import React from 'react';
import { Typography, CircularProgress } from '@material-ui/core';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';

import useStyles from './styles';

const PopupLink = ({ error, editable, loading, title, handleClick, single, disabled }) => {
  const classes = useStyles({ error, editable });
  return (
    <Typography variant="h5" onClick={e => !disabled && handleClick(e)} className={`${classes.text} ${disabled ? classes.disabled : ''}`} display="inline">
      <span>{title}</span>
      {editable && <CloudUploadIcon className={classes.icon} />}
      {loading && <CircularProgress size={24} />}
      {single && <span>{error}</span>}
    </Typography>
  );
};

export default PopupLink;
