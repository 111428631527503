import { call, put, takeLatest } from 'redux-saga/effects';

import { currentCompany, notification } from '../../../actionTypes';
import dataProvider from '../../../store/dataProvider';

export function* update({ payload: { companyId, questionId, contractorLevel, values } }) {
  try {
    const { data } = yield call(
      dataProvider.createOne,
      `companies/${companyId}/grade?contractorLevel=${contractorLevel}`,
      values,
    );

    yield put({
      type: currentCompany.questions.update.end,
      payload: {
        question: data,
        questionId,
      },
    });
  } catch (e) {
    yield put({
      type: currentCompany.questions.update.error,
      payload: { questionId },
    });
    yield put({ type: notification.add, payload: { error: e.message } });
  }
}

export default function* updateQuestionSaga() {
  yield takeLatest(currentCompany.questions.update.start, update);
}
