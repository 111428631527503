import { call, put, takeLatest } from 'redux-saga/effects';

import { archive, notification } from '../../../actionTypes';
import dataProvider from '../../../store/dataProvider';
import { successMessages } from '../../../store/constants';

export function* recoverUser({ payload: { userId, companyId } }) {
  try {
    yield call(dataProvider.updateOnly, `companies/${companyId}/users/${userId}/recover`, [userId]);
    const { data } = yield call(dataProvider.getList, `Users/deleted-users`);

    yield put({
      type: archive.user.recover.end,
      payload: { list: data, total: data.length },
    });
    yield put({ type: notification.add, payload: { success: successMessages.companyUserRestore } });
  } catch (e) {
    yield put({ type: archive.user.recover.error });
    yield put({ type: notification.add, payload: { error: !!e && e.message } });
  }
}

export default function* recoverUserSaga() {
  yield takeLatest(archive.user.recover.start, recoverUser);
}
