import React from 'react';
import MaterialTable from 'material-table';
import { Typography, CircularProgress } from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import CheckIcon from '@material-ui/icons/Check';

import { QuestionType } from '../../../../utils/enums/questions';
import ButtonIcon from './ButtonIcon';
import useStyles from './styles';

const gradeF = 'F';

const getMaxScore = values => {
  const gradeList = values.filter(value => value.grade !== gradeF);
  return Math.max(...gradeList.map(value => value.grade), 0);
};

const Table = ({ list, updateList, handleModal, loading }) => {
  const classes = useStyles();
  const columns = [
    {
      title: 'Question title',
      field: 'questionTitle',
      render: ({ questionTitle }) => <Typography>{questionTitle}</Typography>,
    },
    {
      title: 'Description',
      field: 'description',
      render: ({ description }) => (
        <Typography align="justify" className={classes.description}>
          {description}
        </Typography>
      ),
    },
    {
      title: 'Type',
      field: 'type',
      render: ({ type }) => <Typography>{QuestionType[type]}</Typography>,
    },
    {
      title: 'Section',
      field: 'section',
      render: ({ section }) => <Typography>{section}</Typography>,
    },
    {
      title: 'Page',
      field: 'page',
      render: ({ page }) => <Typography>{page}</Typography>,
    },
    {
      title: 'Max Score',
      field: 'companyRules',
      render: ({ id, companyRules }) => (
        <>
          {updateList.includes(id) ? (
            <CircularProgress size={24} />
          ) : (
            <Typography>{getMaxScore(companyRules)}</Typography>
          )}
        </>
      ),
      cellStyle: {
        textAlign: 'center',
      },
      headerStyle: {
        textAlign: 'center',
      },
      width: 100,
    },
    {
      title: 'Visibility',
      field: 'visibility',
      render: ({ visibility }) => visibility && <CheckIcon className={classes.success} />,
      cellStyle: {
        textAlign: 'center',
      },
      headerStyle: {
        textAlign: 'center',
      },
      width: 100,
    },
    {
      title: 'Actions',
      render: rowData => (
        <ButtonIcon handler={() => handleModal(rowData)}>
          <EditIcon color="primary" />
        </ButtonIcon>
      ),
      cellStyle: {
        textAlign: 'center',
      },
      headerStyle: {
        textAlign: 'center',
      },
      width: 100,
    },
  ];

  return (
    <div className={classes.table}>
      <MaterialTable
        isLoading={loading}
        data={list}
        columns={columns}
        options={{
          sorting: false,
          search: false,
          editable: false,
          draggable: false,
          toolbar: false,
          paging: false,
          tableLayout: 'fixed',
        }}
      />
    </div>
  );
};

export default Table;
