import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import { Switch, Route, useParams } from 'react-router-dom';

import useStyles from './styles';
import { auth, settings } from '../../../actionTypes';
import routes from '../../routes/path';
import Header from '../../containers/Header';
import CompanyList from '../../pages/MarketPlace';
import CreateNewCompany from '../../pages/CreateNewCompany';
import CompanyLayout from '../Company';
import YesNoQuestionsAll from '../../pages/YesNoQuestionsAll';
import Dashboard from '../../pages/Dashboard';
import NotFound from '../../pages/NotFound';
import FileDownload from '../../pages/FileDownload';
import Review from '../../pages/Review';
import { useUserPermissions } from '../../../utils/effects/userPermissions';
import { userTypes } from '../../../utils/constants';
import headerNotification from '../../../actionTypes/headerNotification';
import { useCompanyInfo } from '../../../utils/effects/useCompanyInfo';

const MainLayout = () => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const { type } = useUserPermissions();
  const { id } = useParams();
  const { companyId } = useCompanyInfo(id);

  const userId = useSelector(state => state.auth.check.userId);
  const user = useSelector(state => state.entity.user[userId]);
  const headerNotifications = useSelector(state => state.headerNotifications);

  useEffect(() => {
    dispatch({ type: settings.configuration.get.start, payload: { companyId, fullData: false } });
  }, [companyId, dispatch, type]);

  useEffect(() => {
    dispatch({ type: headerNotification.list.start, payload: { companyId } });
  }, [companyId, dispatch]);

  const handleLogout = () => dispatch({ type: auth.logout.start });
  return (
    <Grid container className={classes.containerPage} direction="row">
      <Grid item className={classes.headerGutter}>
        <Header handleLogout={handleLogout} user={user} headerNotifications={headerNotifications} />
      </Grid>
      <Grid item xs={12} className={classes.contentContainer}>
        <Switch>
          <Route
            path={routes.private.company.list}
            exact
            component={type !== userTypes.Contractor ? CompanyList : NotFound}
          />
          <Route
            path={routes.private.company.new}
            exact
            component={type === userTypes.SafetyCouncil ? CreateNewCompany : NotFound}
          />
          <Route
            path={routes.private.company.id}
            exact
            component={type !== userTypes.Contractor ? Dashboard : NotFound}
          />
          <Route path={routes.private.review} exact component={Review} />
          <Route path={routes.private.fileDownload} exact component={FileDownload} />
          <Route
            path={routes.private.company.id}
            component={type === userTypes.SafetyCouncil ? CompanyLayout : NotFound}
          />
          }
          <Route path={routes.private.settings} component={CompanyLayout} />
          <Route path={routes.private.testYesNoQuestions} exact component={YesNoQuestionsAll} />
          <Route
            path={routes.private.index}
            exact
            component={[userTypes.SafetyCouncil, userTypes.Owner].includes(type) ? CompanyList : Dashboard}
          />
          <Route component={NotFound} />
        </Switch>
      </Grid>
    </Grid>
  );
};

export default MainLayout;
