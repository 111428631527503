import { currentCompany } from '../../actionTypes';

const initialState = {
  healthProfile: {},
  questions: [],
  requestInProgress: false,
  loading: false,
  success: false,
  error: false,
};

export default function healthProf(state = initialState, action) {
  switch (action.type) {
    case currentCompany.healthProf.get.start:
      return initialState;

    case currentCompany.healthProf.get.end:
      return {
        ...state,
        loading: false,
        healthProfile: action.payload.healthProfile,
        questions: action.payload.questions,
      };

    case currentCompany.healthProf.get.error:
      return {
        ...state,
        loading: false,
      };

    case currentCompany.healthProf.update.start:
      return { ...state, requestInProgress: true };

    case currentCompany.healthProf.update.end:
      return {
        ...state,
        requestInProgress: false,
        healthProfile: action.payload.healthProfile,
        questions: action.payload.questions,
        success: true,
        error: false,
      };

    case currentCompany.healthProf.update.error:
      return {
        ...state,
        requestInProgress: false,
        success: false,
        error: true,
      };

    default:
      return state;
  }
}
