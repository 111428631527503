import getMonth from 'date-fns/getMonth';
import getYear from 'date-fns//getYear';

const MARCH_MONTH = 2;
export const LAST_YEARS_COUNT = 5;

export const getCurrentYear = () => {
  const now = new Date();
  const currentYear = getYear(now);
  return currentYear - 1;
};

export const getCurrentRequiredYear = () => {
  const now = new Date();
  const currentYear = getYear(now);
  return getMonth(now) >= MARCH_MONTH ? currentYear - 1 : currentYear - 2;
};

export const getLastYearsList = () => {
  const currentYear = getCurrentYear();

  return Array.from({ length: LAST_YEARS_COUNT }).map((_, index) => currentYear - index);
};

export const getLastRequiredYearsList = () => {
  const currentYear = getCurrentRequiredYear();

  return Array.from({ length: LAST_YEARS_COUNT }).map((_, index) => currentYear - index);
};

export const getCurrentYearsList = () => {
  const currentYear = getYear(new Date());

  return Array.from({ length: LAST_YEARS_COUNT }).map((_, index) => currentYear - index);
};

export const getLastYearsOptions = () => {
  return getLastYearsList().map(year => ({ label: year, value: year }));
};

export const getCurrentYearsOptions = () => {
  return getCurrentYearsList().map(year => ({ label: year, value: year }));
};
