import React from 'react';
import { Grid, Typography } from '@material-ui/core';

import useStyles from './styles';

const TitleType = ({ label, level }) => {
  const classes = useStyles({ level });
  return (
    <Grid container spacing={1} justify="space-between" alignItems="center">
      <Grid item xs={12}>
        <Typography variant="subtitle2" gutterBottom className={classes.innerWrapper}>
          {label}
        </Typography>
      </Grid>
    </Grid>
  );
};

export default TitleType;
