import { company } from '../../actionTypes';

const initialState = {
  state: null,
  loading: false,
  success: false,
  error: false,
};

export default function create(state = initialState, action) {
  switch (action.type) {
    case company.create.start:
      return { ...state, loading: true };

    case company.create.end:
      return {
        ...state,
        loading: false,
        state: action.payload.state,
        success: true,
        error: false,
      };

    case company.create.error:
      return {
        ...state,
        loading: false,
        state: false,
        success: false,
        error: true,
      };

    default:
      return state;
  }
}
