import React from 'react';
import { useSelector } from 'react-redux';
import { List, ListItem, ListItemText, Typography, Grid, Tooltip } from '@material-ui/core';
import TextEllipsis from 'react-text-ellipsis';
import WarningIcon from '@material-ui/icons/Warning';

import { useUserPermissions } from '../../../utils/effects/userPermissions';
import { userTypesSidebarMenu } from '../../../utils/constants/userTypesSidebarMenu';
import { userTypes } from '../../../utils/constants';
import MenuCompany from '../MenuCompany';
import { pqfStatusesEnum } from '../../../utils/constants';
import useStyles from './styles';
import { useCompanyInfo } from '../../../utils/effects/useCompanyInfo';

const CompanyNavigationBar = ({
  companyId,
  companyName,
  validKeys,
  loadingKeys,
  ownerAddendum,
  companyType,
  pqfStatus,
}) => {
  const { type } = useUserPermissions();
  const classes = useStyles();

  const userId = useSelector(state => state.auth.check.userId);
  const activeUser = useSelector(state => state.entity.user);
  const activeUserId = userId && activeUser[userId].companyId;
  const selfCompany = activeUserId === companyId;
  const { isBasicContractor } = useCompanyInfo(companyId);

  const navigationMenu = userTypesSidebarMenu(
    companyId,
    ownerAddendum,
    companyType,
    type,
    selfCompany,
    isBasicContractor,
  );
  return (
    <List component="div" disablePadding>
      {companyName && (
        <Grid container alignItems="center">
          <Grid item>
            <ListItem className={classes.listItem}>
              <ListItemText disableTypography>
                <Typography variant="button" className={classes.titleWrapper}>
                  <TextEllipsis tag={Typography} tagClass={'MuiTypography-button'}>
                    {companyName}
                  </TextEllipsis>
                </Typography>
              </ListItemText>
            </ListItem>
          </Grid>
          {pqfStatus && pqfStatus === pqfStatusesEnum.PendingReview && (
            <Grid item>
              <Tooltip title="This PQF was submitted for review. If changes are made and saved the PQF needs to be resubmitted.">
                <WarningIcon fontSize="small" className={classes.icon} />
              </Tooltip>
            </Grid>
          )}
        </Grid>
      )}
      <MenuCompany
        menu={navigationMenu.children}
        validKeys={validKeys}
        loadingKeys={loadingKeys}
        showValidation={companyType === userTypes.Contractor}
      />
    </List>
  );
};

export default React.memo(CompanyNavigationBar);
