import { format, parseISO, isValid } from 'date-fns';

export const defaultFormat = 'MM/dd/yyyy';

export default function(date) {
  const dateObj = parseISO(date);
  if (isValid(dateObj)) {
    return format(dateObj, defaultFormat);
  }
  return date;
}
