import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  cardWrapper: {
    display: 'flex',
    flex: 1,
    height: '100%',
    position: 'relative',
    alignItems: 'center',
    justifyContent: 'center',
    '&:hover': {
      '& $actions': {
        display: 'flex',
      },
    },
  },
  cardActionAreaWrapper: {
    display: 'block',
    position: 'relative',
    height: '100%',
  },
  labelImageWrapper: {
    position: 'absolute',
    left: theme.spacing(0),
    right: theme.spacing(0),
    bottom: theme.spacing(0),
    top: theme.spacing(0),
    padding: theme.spacing(1),
  },
  cardMediaWrapper: {
    width: '100%',
    height: '100%',
    backgroundPosition: 'center',
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',
  },
  inputInvisible: {
    display: 'block',
    visibility: 'hidden',
    width: theme.spacing(0),
    height: theme.spacing(0),
    outline: 'none',
  },
  errorTitle: {
    marginTop: theme.spacing(1),
    color: theme.palette.error.main,
    textAlign: 'center',
    fontSize: theme.typography.fontSize,
  },
  actions: {
    display: 'none',
    position: 'absolute',
    top: 0,
    left: 0,
    height: '100%',
    width: '100%',
    alignItems: 'center',
    justifyContent: 'space-evenly',
    background: 'rgba(0, 0, 0, 0.5)',
  },
  plus: {
    '& label': {
      cursor: 'pointer',
    }
  },
  actionLabel: {
    cursor: 'pointer',
  },
}));

export default useStyles;
