import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import { FastField } from 'formik';

import FormBody from '../../components/FormBody';
import FormSelect from '../../components/FormSelect';
import LoadingButton from '../../components/LoadingButton';
import YesNoFormConstructor from '../YesNoFormConstructor';
import PageLoader from '../../components/PageLoader';
import QuestionStructure from '../../components/QuestionStructure';
import { recursiveInclude } from '../../../utils/containersHelpers';
import PromptNotification from '../../components/PromptComponent';
import { yesNoOptions } from '../../../utils/constants/formConstats';
import { useCompanyInfo } from '../../../utils/effects/useCompanyInfo';
import useStyles from './styles';

const fixEmptySection = arr =>
  arr.children.reduce((acc, item) => {
    return item.type === 'Section' && item.children.length === 0
      ? acc
      : [
        ...acc,
        {
          ...item,
          children: fixEmptySection(item),
        },
      ];
  }, []);

const YesNoQuestionsTypical = ({ questions, disabler, companyId, loading, success, error, handleSubmit, allowUpdate }) => {
  const classes = useStyles();
  const { isBasicContractor } = useCompanyInfo(companyId);

  if (loading && !questions.length) return <PageLoader />;

  const filterQuestions = isBasicContractor
    ? questions.filter(q => !(!q.requiredForBasicContractor && q.requiredForPsmContractor))
    : questions;

  const setQuestionStructure = { children: fixEmptySection(recursiveInclude(filterQuestions)) };

  const initialValues = {};

  if (disabler) {
    initialValues[disabler.fieldName] = disabler.value;
  }

  return (
    <YesNoFormConstructor fields={filterQuestions} onSubmit={handleSubmit} initialValues={initialValues} disabler={disabler}>
      {({ fields, handleSubmit, values, setFieldTouched, setFieldValue, dirty, isValid }) => {
        return (
          <>
            <PromptNotification formStatus={dirty} />
            <FormBody loading={loading} disabled={!allowUpdate}>
              <Grid item xs={12}>
                {disabler && (
                  <Grid item xs={4}>
                    <Typography variant="h5" gutterBottom>
                      {disabler.title}
                    </Typography>
                    <FastField
                      className={classes.inputRow}
                      name={disabler.fieldName}
                      component={FormSelect}
                      options={yesNoOptions}
                    />
                  </Grid>
                )}
                {setQuestionStructure.children.map(item => (
                  <QuestionStructure
                    show
                    values={values}
                    key={item.key}
                    item={item}
                    fields={fields}
                    setFieldTouched={setFieldTouched}
                    setFieldValue={setFieldValue}
                  />
                ))}
              </Grid>
              {allowUpdate && (
                <LoadingButton
                  fixed
                  disabled={!dirty || !isValid}
                  loading={loading}
                  onClick={handleSubmit}
                  text="save"
                  success={success}
                  error={error}
                />
              )}
            </FormBody>
          </>
        );
      }}
    </YesNoFormConstructor>
  );
};

export default YesNoQuestionsTypical;
