import React from 'react';
import { Chip, TextField } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import get from 'lodash.get';

const AutocompleteDropdown = ({
  size = 'small',
  options,
  grouped,
  selected,
  label,
  field: { onChange, ...fieldProps },
  form: { setFieldValue, errors },
  ...props
}) => {
  const { name, value } = fieldProps;

  const listErrors = get(errors, name, []);

  return (
    <Autocomplete
      {...props}
      freeSolo
      multiple
      disableCloseOnSelect
      options={options}
      value={value}
      groupBy={option => (grouped ? option.groupName : null)}
      getOptionLabel={option => option}
      renderOption={option => <React.Fragment>{option}</React.Fragment>}
      renderInput={params => <TextField {...params} size={size} variant="outlined" fullWidth label={label} />}
      onChange={(_, selectedValues) => {
        setFieldValue(name, selectedValues);
      }}
      renderTags={val =>
        val.map((item, index) => (
          <Chip
            key={item}
            color={listErrors[index] ? 'secondary' : 'default'}
            size="small"
            label={item}
            onDelete={() =>
              setFieldValue(
                name,
                val.filter(selected => selected !== item),
                true,
              )
            }
          />
        ))
      }
    />
  );
};

export default AutocompleteDropdown;
