import React from 'react';

import logoPlaceholder from '../../images/logoPlaceholder.png';
import useStyles from './styles';

const CompanyLogo = ({ src, name = '', href = '' }) => {
  const classes = useStyles();

  return (
    // eslint-disable-next-line react/jsx-no-target-blank
    <a href={`//${href}`} target="_blank" className={classes.container} rel="noreferrer">
      <img src={src || logoPlaceholder} alt={name} className={classes.image} />
    </a>
  );
};

export default CompanyLogo;
