import React, { useEffect } from 'react';
import { Grid, Link as MaterialLink } from '@material-ui/core';
import { FastField, Formik } from 'formik';

import FormDatePicker from '../../../components/FormDataPicker';
import { interStates } from '../../../../utils/constants/usaStates';
import sicCodes from '../../../../utils/constants/sicCodes';
import { changeNullToEmptyString, formatDateForInitialValues } from '../../../../utils/containersHelpers';
import PopoverPopup from '../../../components/PopoverPopup';
import { sicCodesList } from '../../../../utils/constants/sicCodesLink';
import useStyles from './styles';
import FileUploaderInput from '../../../components/FileUploaderInput';
import { EMRSchema } from '../../../../utils/validate/shemas';
import FormAutocomplete from '../../../components/FormAutocomplete';

const WatchEffect = ({ dirty, isValid, values, formUpdate }) => {
  useEffect(() => {
    formUpdate(values, dirty, isValid);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dirty, values, isValid]);
  return null;
};

const contentForPopover = (
  <MaterialLink target="_blank" href={sicCodesList}>
    List of Standard Industrial Classification (SIC) Codes
  </MaterialLink>
);

const EMRateInfoForm = ({ isBasicContractor, formUpdate, handleSubmit, emrInfo, companyId, naicsCodes }) => {
  const classes = useStyles();

  const naicsOptions = naicsCodes.map(key => ({
    label: key.description,
    value: key.code,
  }));

  const initialValues = formatDateForInitialValues(changeNullToEmptyString(emrInfo));
  initialValues.sic = emrInfo.sic ? emrInfo.sic.toString() : '';

  return (
    <Formik initialValues={initialValues} onSubmit={handleSubmit} validationSchema={EMRSchema} enableReinitialize>
      {props => {
        return (
          <>
            <WatchEffect formUpdate={formUpdate} dirty={props.dirty} isValid={props.isValid} values={props.values} />
            <Grid container>
              {!isBasicContractor && (
                <Grid item lg={12}>
                  <Grid container>
                    <Grid item lg={4}>
                      <FastField
                        className={classes.inputRow}
                        label="State Of Origin"
                        name="state"
                        options={interStates}
                        component={FormAutocomplete}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              )}
              <Grid item lg={12}>
                <Grid container>
                  <Grid item lg={4}>
                    <FastField
                      label="EMR Anniversary Date"
                      name="anniversaryDate"
                      component={FormDatePicker}
                      className={classes.inputRow}
                    />
                  </Grid>
                </Grid>
              </Grid>
              {!isBasicContractor && (
                <Grid item lg={12}>
                  <Grid container>
                    <Grid item lg={4}>
                      <FastField
                        className={classes.inputRow}
                        label="SIC"
                        name="sic"
                        options={sicCodes}
                        component={FormAutocomplete}
                      />
                    </Grid>
                    <Grid item lg={2}>
                      <PopoverPopup content={contentForPopover} />
                    </Grid>
                  </Grid>
                </Grid>
              )}
              <Grid item lg={12}>
                <Grid container>
                  <Grid item lg={4}>
                    <FastField
                      className={classes.inputRow}
                      label="NAICS"
                      name="naicsCode"
                      options={naicsOptions}
                      component={FormAutocomplete}
                      withDescription
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item lg={12}>
                <Grid container className={classes.fileContainer}>
                  <Grid item lg={6}>
                    <FastField
                      name="emrdoc"
                      component={FileUploaderInput}
                      label="Upload EMR Documentation (Insurance Carrier) (Last 3 Years)"
                      companyId={companyId}
                      expirationDate
                      defaultExpiration
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </>
        );
      }}
    </Formik>
  );
};

export default EMRateInfoForm;
