import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  buttonNew: {
    marginBottom: theme.spacing(2),
  },
  tableBody: {
    '& .MuiTableRow-root:nth-child(1)': {
      backgroundColor: theme.palette.grey[300],
    },
    '& .MuiTableRow-root:nth-child(3)': {
      backgroundColor: theme.palette.grey[300],
    },
    '& .MuiTableCell-root:nth-child(n+2)': {
      borderLeft: '1px solid #ccc',
      minWidth: theme.spacing(21),
      padding: theme.spacing(1, 2),
    },
  },
}));

export default useStyles;
