export default function(phone) {
  if (phone?.length) {
    return `${phone.substring(0, 3)}-${phone.substring(3, 6)}-${phone.substring(6)}`;
  }
  return phone;
}

export const formatPhoneForParent = phone => {
  if (phone.length) {
    return `${phone.substring(0, 3)}-${phone.substring(3, 6)}-${phone.substring(6, 9)}-${phone.substring(
      9,
    )}__`.substring(0, 15);
  }
  return phone;
};
