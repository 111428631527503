import React from 'react';
import { FastField } from 'formik';
import { Grid, Typography } from '@material-ui/core';

import { environmentalSchema } from '../../../utils/validate/shemas';
import FormInput from '../../components/FormInput';
import PageLoader from '../../components/PageLoader';
import FormBody from '../../components/FormBody';
import LoadingButton from '../../components/LoadingButton';
import { recursiveInclude } from '../../../utils/containersHelpers';
import formatPhone from '../../../utils/textFormaters/phone';
import YesNoFormConstructor from '../YesNoFormConstructor';
import QuestionStructure from '../../components/QuestionStructure';
import { changeNullToEmptyString } from '../../../utils/containersHelpers';
import useStyles from './styles';
import PromptNotification from '../../components/PromptComponent';
import PopoverPopup from '../../components/PopoverPopup';
import { useCompanyInfo } from '../../../utils/effects/useCompanyInfo';

const CreateEnvironmentalForm = ({
  healthProfile,
  loading,
  error,
  success,
  handleSubmit,
  requestInProgress,
  questions,
  companyId,
  allowUpdate,
}) => {
  const classes = useStyles();
  const { isBasicContractor } = useCompanyInfo(companyId);

  if (loading || questions.length === 0) return <PageLoader />;

  const filterQuestions = isBasicContractor
    ? questions.filter(q => !(!q.requiredForBasicContractor && q.requiredForPsmContractor))
    : questions;

  const values = {
    id: 0,
    companyId,
    certifications: '',
    reportsToName: '',
    reportsToTitle: '',
    contactInfo: {
      phoneNumber: '',
      contactPersonFirstName: '',
      contactPersonLastName: '',
      contactPersonTitle: '',
      emailAddress: '',
    },
    ...healthProfile,
  };
  const initialValues = changeNullToEmptyString(values);

  initialValues.contactInfo.phoneNumber = formatPhone(initialValues.contactInfo.phoneNumber);

  const setQuestionStructure = recursiveInclude(filterQuestions);

  return (
    <YesNoFormConstructor
      fields={filterQuestions}
      onSubmit={handleSubmit}
      initialValues={initialValues}
      validateSchema={environmentalSchema}
      enableReinitialize
    >
      {({ fields, values, handleSubmit, setFieldValue, setFieldTouched, dirty, isValid }) => {
        return (
          <>
            <PromptNotification formStatus={dirty} />
            <FormBody loading={loading} disabled={!allowUpdate}>
              <Grid item xs={12}>
                <Typography variant="h5" gutterBottom>
                  Name of the highest ranking safety & health professional in your company:
                </Typography>
                <Grid container>
                  <Grid item xs={12}>
                    <Grid container>
                      <Grid item xs={12}>
                        <Grid container>
                          <Grid item xs={4}>
                            <FastField
                              className={classes.inputRow}
                              textOnly
                              label="First Name"
                              name="contactInfo.contactPersonFirstName"
                              component={FormInput}
                            />
                            <FastField
                              className={classes.inputRow}
                              textOnly
                              label="Last Name"
                              name="contactInfo.contactPersonLastName"
                              component={FormInput}
                            />
                            <FastField
                              className={classes.inputRow}
                              label="Title"
                              name="contactInfo.contactPersonTitle"
                              component={FormInput}
                            />
                            <FastField
                              className={classes.inputRow}
                              label="Email Address"
                              name="contactInfo.emailAddress"
                              component={FormInput}
                            />
                            <FastField
                              className={classes.inputRow}
                              label="Phone Number"
                              name="contactInfo.phoneNumber"
                              type="tel"
                              component={FormInput}
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item xs={12}>
                        <Grid container>
                          <Grid item xs={4}>
                            <FastField
                              className={classes.inputRow}
                              label="Certifications"
                              name="certifications"
                              component={FormInput}
                            />
                          </Grid>
                          <Grid item xs={3}>
                            <PopoverPopup content={'If you do not have certification, please type "None"'} />
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item xs={12}>
                        <Grid container>
                          <Grid item xs={4}>
                            <FastField
                              className={classes.inputRow}
                              label="Reports to Name"
                              name="reportsToName"
                              component={FormInput}
                            />
                            <FastField
                              className={classes.inputRow}
                              label="Reports to Title"
                              name="reportsToTitle"
                              component={FormInput}
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                  {filterQuestions.filter(item => item.type !== 'Section').length > 0 && (
                    <Grid item xs={12}>
                      {setQuestionStructure.children.map(item => (
                        <QuestionStructure
                          show
                          values={values}
                          setFieldValue={setFieldValue}
                          setFieldTouched={setFieldTouched}
                          key={item.key}
                          item={item}
                          fields={fields}
                        />
                      ))}
                    </Grid>
                  )}
                </Grid>
              </Grid>
              {allowUpdate && (
                <LoadingButton
                  fixed
                  disabled={!dirty || !isValid}
                  success={success}
                  error={error}
                  loading={requestInProgress}
                  onClick={handleSubmit}
                  text="save"
                />
              )}
            </FormBody>
          </>
        );
      }}
    </YesNoFormConstructor>
  );
};

export default CreateEnvironmentalForm;
