import React from 'react';
import { FastField } from 'formik';

import FormRadio from '../../../components/FormRadio';

const YesNoFieldType = ({ name, options }) => {
  return <FastField name={name} component={FormRadio} options={options} />;
};

export default YesNoFieldType;
