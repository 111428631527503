import React from 'react';
import { Grid, IconButton } from '@material-ui/core';
import { SortableElement, SortableHandle } from 'react-sortable-hoc';
import DeleteIcon from '@material-ui/icons/Delete';
import DragIndicatorIcon from '@material-ui/icons/DragIndicator';
import { FastField } from 'formik';

import FormInput from '../../../components/FormInput';

const DragHandle = SortableHandle(() => <DragIndicatorIcon />);

const OptionItem = ({ name, handleRemove }) => {
  return (
    <Grid item xs={12}>
      <Grid container justify="flex-start" alignItems="center" spacing={1}>
        <Grid item>
          <DragHandle />
        </Grid>
        <Grid item>
          <FastField component={FormInput} name={name} label="Option name" />
        </Grid>
        <Grid item>
          <IconButton aria-label="Remove option" size="small" onClick={handleRemove}>
            <DeleteIcon color="error" />
          </IconButton>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default SortableElement(OptionItem);
