import React from 'react';
import { Typography } from '@material-ui/core';

import useStyles from './styles';

const ExpDate = ({ expDate, warningTime, isPSM, company }) => {
  const classes = useStyles();
  const subDate = expDate === undefined || expDate === null ? undefined : expDate;
  const todayDate = new Date();
  let fullDate = new Date(subDate);

  if (isPSM && fullDate !== undefined) {
    const day = fullDate.getDate();
    const month = fullDate.getMonth();
    const year = fullDate.getFullYear();
    fullDate = new Date(year + 2, month, day);
  }

  // get the actual date to display, PSM audits validate the PAST 2 years, so this
  const date = subDate !== undefined && isPSM ? new Date(subDate).toLocaleDateString() : fullDate.toLocaleDateString();

  // using new Date here allows the warning color to work properly.
  // fullDate.setMonth broke
  const warningDate = new Date(fullDate).setMonth(fullDate.getMonth() - warningTime);

  // 30 days warning
  // expired error
  const soonToExp = todayDate >= warningDate;
  const expired = todayDate >= fullDate;

  if (subDate === undefined) {
    return (
      <Typography
        className={isPSM ? (company === 'PSM Contractor' ? classes.rejected : classes.disabled) : classes.rejected}
      >
        N/A
      </Typography>
    );
  } else if (expired) {
    return <Typography className={classes.rejected}>{date}</Typography>;
  } else if (soonToExp) {
    return <Typography className={classes.warning}>{date}</Typography>;
  } else {
    return <Typography className={classes.clear}>{date}</Typography>;
  }
};
export default ExpDate;
