import React from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Link as MaterialLink,
} from '@material-ui/core';

import useStyles from './styles';

const ListOwnersModal = ({ open, list, openGradingModal, handleClose }) => {
  const classes = useStyles();

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      classes={{
        paper: classes.dialogModal,
      }}
    >
      <DialogTitle
        disableTypography
        classes={{
          root: classes.titleWrapper,
        }}
      >
        List owners
      </DialogTitle>
      <DialogContent
        classes={{
          root: classes.contentWrapper,
        }}
      >
        <Table className={classes.table}>
          <TableHead>
            <TableRow>
              <TableCell>Owner</TableCell>
              <TableCell>Score</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {list.map(item => (
              <TableRow key={item.ownerId}>
                <TableCell>
                  <MaterialLink
                    className={classes.link}
                    onClick={() => {
                      openGradingModal(item.ownerId);
                    }}
                  >
                    {item.ownerName}
                  </MaterialLink>
                </TableCell>
                <TableCell>{item.grade}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </DialogContent>
      <DialogActions
        classes={{
          root: classes.contentWrapper,
        }}
      >
        <Grid item xs={12}>
          <Grid container justify="flex-end" alignItems="center">
            <Grid item>
              <Button variant="contained" size="small" onClick={handleClose}>
                Close
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  );
};

export default ListOwnersModal;
