import React, { useState } from 'react';
import getTime from 'date-fns/getTime';
import { Link } from 'react-router-dom';
import { Grid, Typography, Link as MaterialLink } from '@material-ui/core';

import path, { routeCreator } from '../../../routes/path';
import CollapseCard from '../../../components/Cards/CollapseCard';
import AddendumModal from '../AddendumModal';
import formatDate from '../../../../utils/textFormaters/date';
import formatPath from '../../../../utils/textFormaters/formatPath';
import { userTypes } from '../../../../utils/constants';
import useStyles from './styles';
import dashboardStyles from '../styles';
import { useUserPermissions } from '../../../../utils/effects/userPermissions';

const Addendum = ({ addendum, companyId }) => {
  const [modalOpen, setModalOpen] = useState(false);
  const { type, userCompanyId } = useUserPermissions();
  const classes = useStyles();
  const dashboardClasses = dashboardStyles();
  const { contractor } = addendum;

  const ownRoutes = routeCreator(path.private.settings);

  const listAddendum = (type === userTypes.Owner
    ? contractor.filter(item => `${item.ownerCompanyId}` === userCompanyId)
    : contractor
  ).sort((itemA, itemB) => {
    const dateA = getTime(new Date(itemA.modDate));
    const dateB = getTime(new Date(itemB.modDate));
    return dateB - dateA;
  });

  return (
    <CollapseCard title="Addendums">
      <Grid item xs={12} className={dashboardClasses.cardWrapper}>
        {listAddendum.length > 0 &&
          listAddendum.map(owner => (
            <Grid
              container
              spacing={2}
              key={owner.ownerCompanyId}
              justify="space-between"
              alignItems="flex-start"
              className={classes.ownerContainer}
            >
              <Grid item>
                {type === userTypes.Owner ? (
                  <MaterialLink className={classes.link} onClick={() => setModalOpen(true)}>
                    {owner.ownerCompanyName}
                  </MaterialLink>
                ) : (
                  <MaterialLink
                    className={classes.link}
                    component={Link}
                    to={formatPath(
                      type === userTypes.Contractor
                        ? ownRoutes.private.company.contractorAddendum
                        : path.private.company.contractorAddendum,
                      {
                        ownerId: owner.ownerCompanyId,
                        id: companyId,
                      },
                    )}
                  >
                    {owner.ownerCompanyName}
                  </MaterialLink>
                )}
              </Grid>
              <Grid item>
                <Grid container spacing={1}>
                  <Typography align="right" variant="subtitle2">
                    {formatDate(owner.modDate)}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          ))}

        {listAddendum.length === 0 && (
          <Typography variant="body1" align="center" gutterBottom>
            There is no addendum yet
          </Typography>
        )}
      </Grid>
      {type === userTypes.Owner && (
        <AddendumModal
          open={modalOpen}
          ownerId={userCompanyId}
          companyId={companyId}
          handleClose={() => setModalOpen(false)}
        />
      )}
    </CollapseCard>
  );
};

export default Addendum;
