import { put, takeLatest, call, all } from 'redux-saga/effects';
import { normalize } from 'normalizr';

import dataProvider from '../../../store/dataProvider';
import { currentCompany, entity, notification } from '../../../actionTypes';
import { company as companySchema } from '../../../schema';
import { userTypes } from '../../../utils/constants';

export function* get({ payload: { companyId } }) {
  try {
    let keys = [];
    let addendum = [];
    let validationFields = {};

    const [company, status] = yield all([
      call(dataProvider.getOnly, `Companies/${companyId}/companyshortinfo`),
      call(dataProvider.getList, `companies/${companyId}/conditions`),
    ]);

    if (company.data.typeName === userTypes.Contractor) {
      const [validation, ownerAddendumList] = yield all([
        call(dataProvider.getOnly, `companies/${companyId}/pqf/validate`, { filter: { fullValidation: true } }),
        call(dataProvider.getList, `companies/owners-with-addendums/${company.data.level}`, {}),
      ]);
      keys = validation && validation.data.sections.map(section => section.name);
      addendum = ownerAddendumList.data;
      validationFields =
        validation &&
        validation.data.sections.reduce(
          (acc, item) => ({ ...acc, [item.name]: item.fields.map(field => field.name) }),
          {},
        );
    }

    const normalizeData = normalize([company.data], [companySchema]);
    yield put({ type: entity.add, payload: normalizeData.entities });

    yield put({
      type: currentCompany.companyInfo.get.end,
      payload: { keys, validationFields, addendum, status: status.data },
    });
  } catch (e) {
    yield put({
      type: currentCompany.companyInfo.get.end,
      payload: { keys: [] },
    });
    yield put({ type: notification.add, payload: { error: e.message } });
  }
}

export default function* getCompanyInfo() {
  yield takeLatest(currentCompany.companyInfo.get.start, get);
}
