import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  formWrapper: {
    marginTop: theme.spacing(6),
    padding: theme.spacing(2, 0),
  },
  inputRow: {
    margin: theme.spacing(1, 0),
    height: 40,
  },
}));

export default useStyles;
