import { currentCompany } from '../../actionTypes';

const initialState = {
    companyAdminInfo: {},
    loading: false,
    updating: false,
    success: false,
    error: false,
};

export default function companyAdminInfoReducer(state = initialState, action) {
    switch (action.type) {
        case currentCompany.companyAdminInfo.get.start:
            return { ...state, loading: true };

        case currentCompany.companyAdminInfo.get.end:
            return {
                ...state,
                loading: false,
                companyAdminInfo: action.payload[0].data,
            };
        case currentCompany.companyAdminInfo.get.error:
            return {
                ...state,
                loading: false,
                data: {},
            };
        case currentCompany.companyAdminInfo.update.start:
            return { ...state, updating: true };

        case currentCompany.companyAdminInfo.update.end:
            return {
                ...state,
                updating: false,
                data: { ...state.data, ...action.payload },
                success: true,
                error: false,
            };
        case currentCompany.companyAdminInfo.update.error:
            return {
                ...state,
                updating: false,
                success: false,
                error: true,
            };

        default:
            return state;
    }
}
