import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import qs from 'query-string';
import { push } from 'connected-react-router';

import { title } from '../../routes/path';
import { formatPageTitle } from '../../../utils/textFormaters';
import { archive } from '../../../actionTypes';
import ArchiveUserContainer from '../../containers/ArchiveUser';
import { useCompanyInfo } from '../../../utils/effects/useCompanyInfo';

const skipFalsyValues = values =>
  Object.keys(values)
    .filter(key => values[key].length > 0)
    .reduce((acc, current) => ({ ...acc, [current]: values[current] }), {});

const ArchiveUser = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const { id } = useParams();

  const { companyId, companyName } = useCompanyInfo(id);

  const { total, items, loading } = useSelector(store => store.archive.user);

  const { itemsPerPage = 25, currentPage = 1, userName = '' } = qs.parse(location.search, { comma: true });

  const filterValues = {
    q: userName,
  };

  useEffect(() => {
    const params = {
      pagination: {
        page: currentPage,
        perPage: itemsPerPage,
      },
      filter: filterValues,
    };
    dispatch({
      type: archive.user.get.start,
      payload: { params, companyId },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage, dispatch, userName, companyId, itemsPerPage]);

  const handleChangePage = offset => {
    const currentPage = offset / itemsPerPage + 1;

    const updatedFilterValues = skipFalsyValues(filterValues);

    dispatch(
      push({
        pathname: location.pathname,
        search: qs.stringify(
          { itemsPerPage: itemsPerPage, currentPage, ...updatedFilterValues },
          { arrayFormat: 'comma', encode: false, skipNull: true },
        ),
      }),
    );
  };

  const handleFilter = function(values) {
    dispatch(
      push({
        pathname: location.pathname,
        search: qs.stringify({ ...skipFalsyValues(values) }, { arrayFormat: 'comma', encode: false, skipNulls: true }),
      }),
    );
  };

  const handleRowAmountChange = value => {
    dispatch(
      push({
        pathname: location.pathname,
        search: qs.stringify(
          { itemsPerPage: value, currentPage: 1, ...skipFalsyValues(filterValues) },
          { arrayFormat: 'comma', encode: false, skipNulls: true },
        ),
      }),
    );
  };

  const handleRestoreUser = (userId, companyId) => {
    const payload = {
      pagination: {
        page: currentPage,
        perPage: itemsPerPage,
      },
      filter: filterValues,
    };
    dispatch({
      type: archive.user.recover.start,
      payload: { payload, userId, companyId },
    });
  };

  return (
    <>
      <Helmet>
        <title> {formatPageTitle(title.private.company.archiveUser, { companyName })} </title>
      </Helmet>
      <ArchiveUserContainer
        filter={{
          handleFilter,
          filterValues,
        }}
        users={{
          users: items,
          loading,
          handleRestoreUser,
        }}
        pagination={{
          total,
          itemsPerPage,
          currentPage,
          handleChangePage,
          handleRowAmountChange,
        }}
      />
    </>
  );
};

export default ArchiveUser;
