import { currentCompany } from '../../actionTypes';

const initialState = {
  loading: true,
  policies: {},
  success: false,
  error: false,
};

export default function insurancePolicy(state = initialState, action) {
  switch (action.type) {
    case currentCompany.insurancePolicy.create.start:
      return {
        ...state,
        loading: true,
      };
    case currentCompany.insurancePolicy.create.end:
      return {
        ...state,
        loading: false,
        policies: action.payload,
        success: true,
        error: false,
      };
    case currentCompany.insurancePolicy.create.error:
      return {
        ...state,
        loading: false,
        success: false,
        error: true,
      };
    case currentCompany.insurancePolicy.get.start:
      return {
        ...initialState,
        loading: true,
      };

    case currentCompany.insurancePolicy.get.end:
      return {
        ...state,
        loading: false,
        policies: action.payload,
      };
    case currentCompany.insurancePolicy.get.error:
      return {
        ...state,
        loading: false,
        policies: {},
      };
    case currentCompany.insurancePolicy.update.start:
      return {
        ...state,
        loading: true,
      };
    case currentCompany.insurancePolicy.update.end:
      return {
        ...state,
        loading: false,
        policies: action.payload,
        success: true,
        error: false,
      };

    case currentCompany.insurancePolicy.update.error:
      return {
        ...state,
        loading: false,
        success: false,
        error: true,
      };
    default:
      return state;
  }
}
