import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet';

import { title, sectionKey } from '../../routes/path';
import { currentCompany } from '../../../actionTypes';
import InsurancePolicyForm from '../../containers/InsurancePolicyForm';
import { formatPageTitle } from '../../../utils/textFormaters';
import { useCompanyInfo } from '../../../utils/effects/useCompanyInfo';
import { useUserPermissions } from '../../../utils/effects/userPermissions';

const InsurancePolicyPage = () => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const { companyId, companyName } = useCompanyInfo(id);
  const { allowUpdate } = useUserPermissions(companyId);

  const { loading, policies, success, error } = useSelector(state => state.currentCompany.insurancePolicy);

  const onSubmit = values => {
    dispatch({
      type: currentCompany.insurancePolicy.update.start,
      payload: { companyId, values, sectionKey: sectionKey.private.company.insurancePolicies },
    });
  };

  useEffect(() => {
    dispatch({ type: currentCompany.insurancePolicy.get.start, payload: { companyId } });
  }, [companyId, dispatch]);

  return (
    <>
      <Helmet>
        <title> {formatPageTitle(title.private.company.insurancePolicies, { companyName })} </title>
      </Helmet>
      <InsurancePolicyForm
        handleSubmit={onSubmit}
        loading={loading}
        policies={policies}
        error={error}
        success={success}
        allowUpdate={allowUpdate}
        companyId={companyId}
      />
    </>
  );
};

export default InsurancePolicyPage;
