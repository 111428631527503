import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  clear: {
    color: theme.palette.success.main,
  },
  warning: {
    color: theme.palette.warning.main,
  },
  rejected: {
    color: theme.palette.error.main,
  },
  disabled: {
    color: '#D3D3D3',
  },
}));

export default useStyles;
