import React from 'react';
import { Dialog, DialogContent, DialogTitle, Typography } from '@material-ui/core';

const AdminDialog = ({ isModalOpen, onCancel, title, note }) => {
  return (
    <Dialog open={isModalOpen} onClose={onCancel}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent dividers>
        <Typography>{note}</Typography>
      </DialogContent>
    </Dialog>
  );
};

export default AdminDialog;
