import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  title: {
    '&.MuiDialogTitle-root': {
      padding: theme.spacing(1, 0),
    },
  },
  container: {
    padding: theme.spacing(1),
  },
  icon: {
    fontSize: theme.spacing(8),
    color: theme.palette.warning.light,
  },
}));

export default useStyles;
