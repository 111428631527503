import React, { useState } from 'react';
import { Formik } from 'formik';
import { Grid } from '@material-ui/core';

import PageLoader from '../../components/PageLoader';
import FormBody from '../../components/FormBody';
import LoadingButton from '../../components/LoadingButton';
import QuestionStructure from './QuestionStructure';
import validationSchema from './validation';
import PromptNotification from '../../components/PromptComponent';
import { enumTypes } from '../../../utils/constants/formBuilder';

const createInitialValues = (q, answersLib) =>
  q.reduce((acc, item) => {
    if (!item.isActive) return acc;
    const child = item.children ? createInitialValues(item.children, answersLib) : {};
    return {
      ...acc,
      ...child,
      [`${item.id}`]: item.type === enumTypes.File ? {
        fileId: answersLib[item.id]?.fileId ?? '',
        fileName: answersLib[item.id]?.fileName ?? '',
      } : answersLib[item.id]?.text ?? '',
    };
  }, {});

const createObjLib = q =>
  q.reduce((acc, item) => {
    if (!item.isActive) return acc;
    const child = item.children ? createObjLib(item.children) : {};
    return {
      ...acc,
      ...child,
      [`${item.id}`]: {
        ...item,
        children: item.children.filter(q => q.isActive).map(item => item.id),
      },
    };
  }, {});

const ContractorAddendum = ({ questions, preview, readOnly, answers, loading, success, error, handleSave, companyId }) => {
  const [skipCheckPQF, setSkipCheckPQF] = useState(true);

  if (loading) return <PageLoader />;

  const answersLib = answers.reduce((acc, item) => ({ ...acc, [item.formBuilderFieldId]: item }), {});
  const objLib = createObjLib(questions);

  const getAnswers = values => Object.keys(values).reduce(
    (acc, item) => objLib[item].type === enumTypes.Title
      ? acc
      : [
        ...acc,
        {
          id: answersLib[item] ? answersLib[item].id : 0,
          formBuilderFieldId: item,
          text: objLib[item].type === enumTypes.File ? values[item].fileId ?? '' : values[item],
          fileId: objLib[item].type === enumTypes.File ? values[item].fileId ?? '' : '',
          fileName: objLib[item].type === enumTypes.File ? values[item].fileName ?? '' : '',
        },
      ],
    []
  );

  const onSubmit = values => {
    localStorage.setItem('skipResetValidationAfterSuccess', skipCheckPQF);

    handleSave(getAnswers(values));
  };

  return (
    <Formik
      initialValues={createInitialValues(questions, answersLib)}
      onSubmit={onSubmit}
      validationSchema={validationSchema(objLib)}
      enableReinitialize
    >
      {props => {
        setTimeout(() => setSkipCheckPQF(getAnswers(props.values).every(x => x.text === (answersLib[x.formBuilderFieldId]?.text ?? '') || objLib[x.formBuilderFieldId].type === enumTypes.File)));

        return (
          <FormBody loading={loading}>
            {preview ? null : <PromptNotification formStatus={props.dirty} />}
            <Grid item xs={12}>
              <QuestionStructure
                values={props.values}
                level={0}
                questions={objLib}
                listKeys={questions.filter(q => q.isActive && q.parentId === null).map(q => q.id)}
                answersLib={answersLib}
                readOnly={readOnly}
                preview={preview}
                companyId={companyId} />
              {preview || readOnly ? null : (
                <LoadingButton
                  disabled={!props.dirty || !props.isValid}
                  text="save"
                  loading={loading}
                  success={success}
                  error={error}
                  onClick={props.handleSubmit}
                  skipCheckPQF={skipCheckPQF} />
              )}
            </Grid>
          </FormBody>
        );
      }}
    </Formik>
  );
};

export default ContractorAddendum;
