import { call, put, takeLatest } from 'redux-saga/effects';
import { normalize } from 'normalizr';

import { pages, notification, entity } from '../../../actionTypes';
import dataProvider from '../../../store/dataProvider';
import { document } from '../../../schema';

export function* get({ payload: { companyId, documentQuery } }) {
  try {
    const documents = yield call(dataProvider.getList, `companies/${companyId}/pqf`, documentQuery);

    // normalize documents
    const normalizeDoc = normalize(documents.data.data, [document]);
    yield put({ type: entity.add, payload: normalizeDoc.entities });

    yield put({
      type: pages.dashboard.documents.end,
      payload: {
        list: normalizeDoc.result,
        page: documentQuery.pagination.page,
        total: documents.total,
      },
    });
  } catch (e) {
    yield put({
      type: pages.dashboard.documents.error,
    });
    yield put({ type: notification.add, payload: { error: e.message } });
  }
}

export default function* getDashboardDocuments() {
  yield takeLatest(pages.dashboard.documents.start, get);
}
