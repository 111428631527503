import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  titleWrapper: {
    wordBreak: 'break-word',
  },
  listItem: {
    '&.MuiListItem-gutters': {
      paddingRight: theme.spacing(0.5),
    },
  },
  icon: {
    color: theme.palette.warning.light,
  },
}));

export default useStyles;
