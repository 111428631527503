import { currentCompany } from '../../actionTypes';

const initialState = {
  data: {},
  users: [],
  loading: false,
  updating: false,
  success: false,
  error: false,
};

export default function settingsReducer(state = initialState, action) {
  switch (action.type) {
    case currentCompany.settings.get.start:
      return { ...state, loading: true };

    case currentCompany.settings.get.end:
      return {
        ...state,
        loading: false,
        data: action.payload.data,
        users: action.payload.users,
      };
    case currentCompany.settings.get.error:
      return {
        ...state,
        loading: false,
        data: {},
      };
    case currentCompany.settings.update.start:
      return { ...state, updating: true };

    case currentCompany.settings.update.end:
      return {
        ...state,
        updating: false,
        data: { ...state.data, ...action.payload },
        success: true,
        error: false,
      };
    case currentCompany.settings.update.error:
      return {
        ...state,
        updating: false,
        success: false,
        error: true,
      };

    default:
      return state;
  }
}
