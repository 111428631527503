import React from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, CircularProgress } from '@material-ui/core';

import FileType from '../../../components/FileType';
import DeleteFile from '../DeleteFile';
import formatDate from '../../../../utils/textFormaters/date';
import useStyles from './styles';

const DocumentList = ({
  files,
  isDocumentList,
  hasPermissions,
  downloadList,
  deleteList,
  handleDownload,
  handleDelete,
}) => {
  const classes = useStyles();
  return (
    <TableContainer className={classes.tableWrapper}>
      <Table>
        <TableHead>
          <TableRow className={classes.tableRow}>
            <TableCell> Name </TableCell>
            <TableCell align="center"> Completed on</TableCell>
            <TableCell align="center"> Completed by</TableCell>
            <TableCell align="center"> Upload day</TableCell>
            <TableCell> Status</TableCell>
            <TableCell />
          </TableRow>
        </TableHead>
        <TableBody className={classes.tableBody}>
          {files.map((item, index) => (
            <TableRow key={index} className={classes.tableRow}>
              <TableCell>
                <FileType
                  name={item.description}
                  loading={downloadList.includes(item.id)}
                  type={item.type}
                  handleClick={() => handleDownload(item.id)}
                  disableIcon
                />
              </TableCell>
              <TableCell align="center">{formatDate(item.completedOn)}</TableCell>
              <TableCell align="center">{item.completedBy}</TableCell>
              <TableCell align="center">{formatDate(item.createDate)}</TableCell>
              <TableCell className={classes.statusText}>Completed</TableCell>
              {hasPermissions && (
                <TableCell align="center">
                  {deleteList.includes(item.id) ? (
                    <CircularProgress size={34} />
                  ) : (
                    <DeleteFile isDocumentList={isDocumentList} handleDelete={() => handleDelete(item.id)} />
                  )}
                </TableCell>
              )}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default DocumentList;
