import { all, call, put, takeLatest } from 'redux-saga/effects';

import { currentCompany, notification } from '../../../actionTypes';
import dataProvider from '../../../store/dataProvider';

export function* get({ payload: { companyId } }) {
  try {
    const [largestJobData, referenceData] = yield all([
      call(dataProvider.getList, `companies/${companyId}/largest-job`),
      call(dataProvider.getList, `companies/${companyId}/references`),
    ]);
    yield put({
      type: currentCompany.reference.get.end,
      payload: { references: referenceData.data, largestJobInfo: largestJobData.data },
    });
  } catch (e) {
    yield put({ type: notification.add, payload: { error: e.message } });
    yield put({ type: currentCompany.reference.get.error });
  }
}

export default function* getReferenceSaga() {
  yield takeLatest(currentCompany.reference.get.start, get);
}
