import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  subFormContainer: {
    padding: theme.spacing(0, 1),
  },
  tableWrapper: {
    margin: theme.spacing(3, 0),
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: theme.palette.grey[300],
  },
  columnTitle: {
    backgroundColor: theme.palette.grey[300],
  },
  tableCell: {
    borderBottom: theme.spacing(0),
  },
  checkboxContainer: {
    margin: theme.spacing(0),
  },
  inputRow: {
    width: theme.spacing(14),
    height: theme.spacing(4),
    '& > div > input': {
      height: theme.spacing(1),
    },
  },
}));

export default useStyles;
