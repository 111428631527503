import React from 'react';
import MaterialTable from 'material-table';

import columns from './columns';
import useStyles from './styles';

const UserTable = ({ users, loading, handleRestoreUser }) => {
  const classes = useStyles();

  return (
    <div className={classes.table}>
      <MaterialTable
        isLoading={loading}
        data={users}
        columns={columns({ handleRestoreUser })}
        options={{
          sorting: false,
          search: false,
          editable: false,
          draggable: false,
          toolbar: false,
          paging: false,
          thirdSortClick: false,
          tableLayout: 'fixed',
        }}
        localization={{
          body: {
            emptyDataSourceMessage: `You don't have any user yet!`,
          },
        }}
      />
    </div>
  );
};

export default UserTable;
