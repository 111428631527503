import React from 'react';
import { Typography } from '@material-ui/core';

const PendingReviewNotification = () => {
  return (
    <Typography variant="h5">
      PQF is under review. If any changes are made and saved the PQF needs to be resubmitted.
    </Typography>
  );
};

export default PendingReviewNotification;
