import { all, call, put, takeLatest } from 'redux-saga/effects';

import { currentCompany, file, notification } from '../../../actionTypes';
import dataProvider from '../../../store/dataProvider';
import { uploadHelpers } from '../../../utils/pageHelpers';

export function* get({ companyId }) {
  try {
    const [finInfoData, finArraysData] = yield all([
      call(dataProvider.getOnly, `companies/${companyId}/fin-info`),
      call(dataProvider.getList, `companies/${companyId}/annual-dollar-volume`, {}),
    ]);

    const { uploads, ...infoData } = finInfoData.data;
    const { uploadKeys, uploadValues } = uploadHelpers(uploads);

    yield put({
      type: file.get.end,
      payload: Object.keys(uploads).reduce((acc, item) => ({ ...acc, [item]: uploads[item] }), {}),
    });

    yield put({
      type: currentCompany.finInfo.get.end,
      payload: {
        financialInfo: { ...infoData, ...uploadValues, uploadKeys },
        financialArrays: finArraysData.data.data,
      },
    });
  } catch (e) {
    yield put({ type: currentCompany.finInfo.get.error });
    yield put({ type: notification.add, payload: { error: e.message } });
  }
}

export default function* getFinInfoSaga() {
  yield takeLatest(currentCompany.finInfo.get.start, get);
}
