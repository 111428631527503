import React from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Grid } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';

import EditForm from '../EditForm';
import ModalDialog from '../../../components/ModalConfirmation';
import useStyles from './styles';

const TableDisplay = ({
  tableData,
  allowUpdate,
  onDeleteConfirm,
  setItemToDelete,
  onEditConfirm,
  loading,
  setItemToEdit,
  itemToEdit,
  itemToDelete,
  label,
  validationSchema,
  amountFieldComponent,
  amountFieldMaskType,
  amountFieldNumber,
  amountFieldName,
}) => {
  const classes = useStyles();

  return (
    <TableContainer component={Paper} className={classes.tableWrapper}>
      <Table className={classes.table}>
        <TableHead>
          <TableRow>
            <TableCell colSpan={2} align="center">
              Year
            </TableCell>
            <TableCell colSpan={3} align="center">
              {label}
            </TableCell>
            <TableCell colSpan={2} align="center">
              Actions
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <>
            {tableData && tableData.length > 0 ? (
              tableData
                .sort((a, b) => b.year - a.year)
                .map((row, index) => {
                  const isLastItem = tableData.length - 1 === index;
                  return itemToEdit && itemToEdit === row.id ? (
                    <EditForm
                      key={index}
                      values={row}
                      index={index}
                      handleSubmit={onEditConfirm}
                      setItemToEdit={setItemToEdit}
                      validationSchema={validationSchema}
                      label={label}
                      amountFieldName={amountFieldName}
                      amountFieldNumber={amountFieldNumber}
                      amountFieldMaskType={amountFieldMaskType}
                      amountFieldComponent={amountFieldComponent}
                    />
                  ) : (
                    <TableRow key={index}>
                      <TableCell colSpan={2} scope="row" align="center">
                        {row.year}
                      </TableCell>
                      <TableCell colSpan={3} align="center">
                        {row.amount}
                      </TableCell>
                      {allowUpdate && (
                        <TableCell colSpan={2} align="center">
                          <Grid container direction="row" justify="center" alignItems="center">
                            <IconButton aria-label="edit" color="primary" onClick={() => setItemToEdit(row.id)}>
                              <EditIcon />
                            </IconButton>
                            <IconButton
                              onClick={() => {
                                if (isLastItem) {
                                  setItemToDelete(row.id);
                                }
                              }}
                            >
                              <DeleteIcon color={isLastItem ? 'error' : 'disabled'} />
                            </IconButton>
                          </Grid>
                        </TableCell>
                      )}
                    </TableRow>
                  );
                })
            ) : (
              <TableRow>
                <TableCell colSpan={7} align="center">
                  No records to display
                </TableCell>
              </TableRow>
            )}
          </>
        </TableBody>
      </Table>
      <ModalDialog
        title="Are you sure you want to delete this item? "
        isModalOpen={!!itemToDelete}
        onCancel={() => setItemToDelete(null)}
        onConfirm={onDeleteConfirm}
      />
    </TableContainer>
  );
};

export default TableDisplay;
