import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  container: {
    alignSelf: 'stretch',
  },
  imageContainer: {
    maxWidth: theme.spacing(25),
    textAlign: 'center',
    pointerEvents: 'none',
    '& img': {
      maxWidth: '100%',
      height: 'auto',
    },
  },
}));

export default useStyles;
