import React from 'react';
import Grid from '@material-ui/core/Grid';

import useStyles from './styles';

const FormBody = ({ loading, children, disabled, spacing = 2 }) => {
  const classes = useStyles({ loading, disabled });
  return (
    <Grid container className={classes.containerForm} spacing={spacing} alignContent="space-between">
      {children}
    </Grid>
  );
};

export default FormBody;
