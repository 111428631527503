import React from 'react';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import { Link, useLocation } from 'react-router-dom';
import { CircularProgress, Grid, Tooltip, Typography } from '@material-ui/core';
import { Done, Error } from '@material-ui/icons';

import useStyles from './styles';
import MenuTooltip from '../MenuTooltip';

const LinkWrapper = ({ to, children, handleClick, classes }) =>
  to && to !== '' ? (
    <Link to={to} onClick={handleClick} className={classes.link}>
      <Grid item xs={12}>
        {children}
      </Grid>
    </Link>
  ) : (
    <Grid item xs={12} onClick={handleClick}>
      {children}
    </Grid>
  );

const hasCurrentChild = (item, pathname) =>
  item.children &&
  Object.keys(item.children).reduce((isCurrent, key) => isCurrent || item.children[key].to === pathname, false);

const MenuItem = ({ item, current, active, validKeys, loadingKeys, showValidation, handleClick }) => {
  const { pathname } = useLocation();
  const Icon = item.icon;
  const classes = useStyles({ current, hasCurrentChild: hasCurrentChild(item, pathname) });

  const isValidCurrent = item.validationKey && validKeys.includes(item.validationKey);
  const isHasValidation =
    item.validationKey ||
    (item.children && Object.keys(item.children).filter(child => item.children[child].validationKey).length);

  const ValidationStatus = () => {
    if (isHasValidation) {
      if (loadingKeys.includes(item.validationKey)) {
        return <CircularProgress size={18} />;
      }
      if (
        isValidCurrent ||
        (item.children &&
          Object.keys(item.children).filter(child => validKeys.includes(item.children[child].validationKey)).length)
      ) {
        return (
          <Tooltip
            title={
              <MenuTooltip
                validationKey={item.validationKey}
                childrenPage={
                  item.children &&
                  Object.keys(item.children)
                    .filter(child => validKeys.includes(item.children[child].validationKey))
                    .map(child => item.children[child].title)
                }
              />
            }
            placement="bottom-start"
          >
            <Error fontSize="small" className={classes.error} />
          </Tooltip>
        );
      } else {
        return <Done fontSize="small" className={classes.success} />;
      }
    } else {
      return null;
    }
  };

  return (
    <LinkWrapper to={item.to} handleClick={handleClick} classes={classes}>
      <Grid container justify="space-between" alignItems="center" className={classes.wrapper}>
        {Icon ? (
          <>
            <Tooltip title={active ? item.title : ''}>
              <Grid item className={classes.menuIcon}>
                <Grid container alignItems="center" justify="center">
                  <Icon />
                </Grid>
              </Grid>
            </Tooltip>
            <Grid item className={classes.menuItemWidth}>
              <Grid container alignItems="center">
                <Grid item xs={10}>
                  <Typography variant="subtitle2"> {item.title} </Typography>
                </Grid>
                <Grid item xs={2}>
                  <Grid container justify="space-between" alignItems="center" className={classes.iconContainer}>
                    <Grid item xs={6}>
                      {showValidation && <ValidationStatus />}
                    </Grid>
                    <Grid item xs={6}>
                      {!!item.children && <KeyboardArrowRightIcon color="action" />}
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </>
        ) : (
          <Grid item xs={12} className={classes.childContainer}>
            <Grid container alignItems="center">
              {isHasValidation && showValidation && (
                <Grid item xs={1}>
                  <ValidationStatus />
                </Grid>
              )}
              <Grid item xs={isHasValidation && showValidation ? 11 : 12}>
                <Typography variant="subtitle2"> {item.title} </Typography>
              </Grid>
            </Grid>
          </Grid>
        )}
      </Grid>
    </LinkWrapper>
  );
};

export default MenuItem;
