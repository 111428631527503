import { call, put, takeLatest } from 'redux-saga/effects';

import { currentCompany, notification } from '../../../actionTypes';
import dataProvider from '../../../store/dataProvider';
import { sectionKey } from '../../../ui/routes/path';

export function* create({ payload: { companyId, ownerId, values } }) {
  try {
    const { data } = yield call(
      dataProvider.createOne,
      `companies/${companyId}/contractor-addendum/${ownerId}`,
      values,
    );

    yield put({
      type: currentCompany.contractorAddendum.create.end,
      payload: { answers: data },
    });

    // update PQF document validation
    yield put({
      type: currentCompany.companyInfo.validation.start,
      payload: { companyId: companyId, validationKey: sectionKey.private.company.settingsInfo },
    });
  } catch (e) {
    yield put({ type: notification.add, payload: { error: e.message } });
    yield put({ type: currentCompany.contractorAddendum.create.error });
  }
}

export default function* createAddendumSaga() {
  yield takeLatest(currentCompany.contractorAddendum.create.start, create);
}
