import React from 'react';
import { Typography, CircularProgress } from '@material-ui/core';

import useStyles from './styles';

const CellName = ({ fileName, handleLoad, companyId, id, documentKey, fileDocument }) => {
  const classes = useStyles();

  if (fileDocument.includes(id)) {
    return <CircularProgress size={24} />;
  }

  return (
    <Typography
      display="inline"
      onClick={() => handleLoad(companyId, id, documentKey)}
      className={classes.load}
      variant="body1"
    >
      {fileName}
    </Typography>
  );
};

export default CellName;
