import React, { useEffect, useRef }  from 'react';
import { KeyboardDatePicker } from '@material-ui/pickers';
import get from 'lodash.get';
import { formatISO, isValid } from 'date-fns';

import { compensateLocalTimeShifting } from './helper';

const FormDatePicker = ({
    field,
    form: { setFieldValue, setFieldTouched, errors, touched },
    disableFuture = false,
    format = 'MM/dd/yyyy',
    disablePast = false,
    defaultDate,
    useDefaultDate,
    ...props
}) => {
    const { name, value } = field;
    const itemError = get(errors, field.name);
    const itemTouched = get(touched, field.name);

    let refUseDefaultDate = useRef(useDefaultDate);

    useEffect(() => {
        setFieldTouched(name, true);
        if (refUseDefaultDate.current) {
            setFieldValue(name, (isValid(defaultDate) && formatISO(defaultDate, { representation: 'date' })) || null, !!defaultDate);
        }
        refUseDefaultDate.current = false;
    }, [defaultDate, refUseDefaultDate, name, setFieldValue, setFieldTouched]);

    //console.log(name);
    //console.log(useDefaultDate);

    return (
        <KeyboardDatePicker
            autoOk
            fullWidth
            disableToolbar
            variant="inline"
            size="small"
            format={format}
            inputVariant="outlined"
            value={(isValid(new Date(value)) && value && compensateLocalTimeShifting(value)) || null}
            name={name}
            onChange={(date, stringVal) => {
                setFieldTouched(name, true);
                setFieldValue(name, (isValid(date) && formatISO(date, { representation: 'date' })) || stringVal, !!date);
            }}
            disableFuture={disableFuture}
            maxDate={disableFuture ? Date.now() : new Date('9999-12-31')}
            minDate={disablePast ? Date.now() : new Date('0001-01-01')}
            KeyboardButtonProps={{
                size: 'small',
            }}
            {...props}
            error={!!itemError && !!itemTouched}
            helperText={!!itemError && itemTouched && itemError}
        />
    );
};

export default FormDatePicker;
