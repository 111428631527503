import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { title, sectionKey } from '../../routes/path';
import { currentCompany } from '../../../actionTypes';
import CreateEnvironmentalForm from '../../containers/CreateEnvironmentalForm';
import { formatPageTitle } from '../../../utils/textFormaters';
import { useCompanyInfo } from '../../../utils/effects/useCompanyInfo';
import { useUserPermissions } from '../../../utils/effects/userPermissions';

const sectionKeyPart = 'envmgmt';

const CreateEnvironmentalManagement = () => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const { companyId, companyName } = useCompanyInfo(id);
  const { allowUpdate } = useUserPermissions(companyId);

  const { healthProfile, questions, loading, requestInProgress, success, error } = useSelector(
    state => state.currentCompany.healthProf,
  );

  useEffect(() => {
    dispatch({
      type: currentCompany.healthProf.get.start,
      payload: { sectionKey: sectionKeyPart, companyId, param: {} },
    });
  }, [companyId, dispatch]);

  const handleSubmit = submitValues => {
    const questionList = Object.keys(submitValues)
      .filter(key => key.includes(sectionKeyPart))
      .reduce((acc, key) => {
        return {
          ...acc,
          [key]: submitValues[key],
        };
      }, {});

    const updateProfile = Object.keys(submitValues)
      .filter(key => !key.includes(sectionKeyPart))
      .reduce((acc, key) => {
        return {
          ...acc,
          [key]: submitValues[key],
        };
      }, {});

    dispatch({
      type: currentCompany.healthProf.update.start,
      payload: {
        companyId,
        questions: Object.keys(questionList).map(key => questionList[key]),
        updateQuestions: Object.keys(questionList).map(key => questionList[key]),
        updateProfile,
        sectionKey: sectionKey.private.company.environmentalManagement,
      },
    });
  };

  return (
    <>
      <Helmet>
        <title> {formatPageTitle(title.private.company.environmentalManagement, { companyName })} </title>
      </Helmet>
      <CreateEnvironmentalForm
        questions={questions}
        healthProfile={healthProfile}
        loading={loading}
        requestInProgress={requestInProgress}
        handleSubmit={handleSubmit}
        companyId={companyId}
        success={success}
        error={error}
        allowUpdate={allowUpdate}
      />
    </>
  );
};

export default CreateEnvironmentalManagement;
