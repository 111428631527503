import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import { FastField, Formik } from 'formik';

import { largestJobForm } from '../../../utils/validate/shemas';
import FormInput from '../../components/FormInput';
import FormNumberInput from '../../components/FormNumberInput';
import LoadingButton from '../../components/LoadingButton';
import FormBody from '../../components/FormBody';
import {
  changeEmptyStringToNull,
  changeNullToEmptyString,
  changeZeroToEmptyString,
} from '../../../utils/containersHelpers';
import useStyles from './styles';
import { setAmountOnSubmit } from '../../../utils/textFormaters/setAmountOnSubmit';
import PromptNotification from '../../components/PromptComponent';

const LargestJobForm = ({ handleSubmit, data, loading, success, error, allowUpdate }) => {
  const classes = useStyles();

  const onSubmit = values => {
    const newValues = changeEmptyStringToNull(values);
    const formattedValues = {
      ...newValues,
      amount: setAmountOnSubmit(newValues.amount),
    };
    handleSubmit(formattedValues);
  };

  const initialValues = changeZeroToEmptyString(changeNullToEmptyString(data), ['amount']);

  return (
    <Formik initialValues={initialValues} validationSchema={largestJobForm} onSubmit={onSubmit} enableReinitialize>
      {props => (
        <>
          <PromptNotification formStatus={props.dirty} />
          <FormBody loading={loading} disabled={!allowUpdate}>
            <Grid item xs={12}>
              <Grid container direction="column">
                <Grid item xs={5}>
                  <Typography variant="h5" gutterBottom>
                    Largest Job during the last 3 years
                  </Typography>
                  <Grid container direction="row" justify="space-between">
                    <Grid item xs={6}>
                      <FastField
                        className={classes.inputRow}
                        label="Customer Name"
                        name="customerName"
                        component={FormInput}
                      />
                    </Grid>
                    <Grid item xs={5}>
                      <FastField
                        className={classes.inputRow}
                        label="Amount"
                        name="amount"
                        maskType="dollarNumber"
                        component={FormNumberInput}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={5}>
                  <FastField
                    className={classes.textArea}
                    name="description"
                    rows={5}
                    multiline
                    placeholder="Description"
                    component={FormInput}
                  />
                </Grid>
              </Grid>
            </Grid>
            {allowUpdate && (
              <LoadingButton
                disabled={!props.dirty || !props.isValid}
                text="save"
                loading={loading}
                success={success}
                error={error}
                onClick={props.handleSubmit}
              />
            )}
          </FormBody>
        </>
      )}
    </Formik>
  );
};

export default LargestJobForm;
