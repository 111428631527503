import React, { forwardRef, useState } from 'react';
import MaterialTable from 'material-table';
import ArrowDownward from '@material-ui/icons/ArrowDownward';

import ConfigureModal from './ConfigureModal';
import { useUserPermissions } from '../../../../utils/effects/userPermissions';
import { getColumns } from './columns';
import { sortDirection } from '../../../../utils/constants/sorting';
import useStyles from './styles';

const CompanyListTable = ({
  loading,
  companies,
  handleDelete,
  loadingListAddToFavorite,
  handleAddToFavorite,
  noFiltersSelected,
  handelSort,
  order,
  visibleColumns,
  setVisibleColumns,
}) => {
  const classes = useStyles();
  const { type } = useUserPermissions();

  const [openModal, setOpenModal] = useState(false);

  const handleSaveColumns = values => {
    localStorage.setItem('marketplaceColumns', JSON.stringify(values));
    setVisibleColumns(values);
  };

  const columns = getColumns(
    handleDelete,
    handleAddToFavorite,
    loadingListAddToFavorite,
    type,
    order,
    visibleColumns,
    setOpenModal,
    classes,
  );

  return (
    <div className={classes.table}>
      <MaterialTable
        isLoading={loading}
        data={companies}
        columns={columns}
        options={{
          sorting: true,
          search: false,
          editable: false,
          draggable: false,
          toolbar: false,
          paging: false,
          thirdSortClick: false,
          tableLayout: 'auto',
        }}
        onOrderChange={(orderBy, orderDirection) => {
          const opositDirection =
            orderDirection === sortDirection.Ascending ? sortDirection.Descending : sortDirection.Ascending;
          const direction = columns[orderBy].sortField === 'filling' ? opositDirection : orderDirection;

          return handelSort(columns[orderBy].sortField, direction);
        }}
        icons={{
          SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
        }}
        localization={{
          body: {
            emptyDataSourceMessage: noFiltersSelected ? `You don't have any companies yet!` : 'No Results Found',
          },
        }}
      />
      <ConfigureModal
        open={openModal}
        setOpenModal={setOpenModal}
        type={type}
        visibleColumns={visibleColumns}
        setVisibleColumns={handleSaveColumns}
      />
    </div>
  );
};

export default CompanyListTable;
