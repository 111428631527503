import { currentCompany } from '../../actionTypes';

const initialState = {
  requestInProgress: false,
  loading: false,
  officers: {},
  success: false,
  error: false,
};

export default function officer(state = initialState, action) {
  switch (action.type) {
    case currentCompany.officer.get.start:
      return {
        ...initialState,
        loading: true,
      };

    case currentCompany.officer.get.end:
      return {
        ...state,
        loading: false,
        officers: action.payload.officers,
      };

    case currentCompany.officer.get.error:
      return {
        ...state,
        loading: false,
      };

    case currentCompany.officer.update.start:
      return { ...state, requestInProgress: true };

    case currentCompany.officer.update.end:
      return {
        ...state,
        requestInProgress: false,
        officers: action.payload.officers,
        success: true,
        error: false,
      };

    case currentCompany.officer.update.error:
      return {
        ...state,
        loading: false,
        success: false,
        error: true,
      };

    case currentCompany.officer.create.start:
      return { ...state, requestInProgress: true };

    case currentCompany.officer.create.end:
      return {
        ...state,
        requestInProgress: false,
        officers: action.payload.officers,
        success: true,
        error: false,
      };

    case currentCompany.officer.create.error:
      return {
        ...state,
        loading: false,
        success: false,
        error: true,
      };

    default:
      return state;
  }
}
