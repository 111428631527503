import { auth } from '../../actionTypes';

const initialState = {
  state: null,
  loading: true,
  error: null,
  userId: null,
};

export default function check(state = initialState, action) {
  switch (action.type) {
    case auth.check.start:
      return { ...state, loading: true };

    case auth.check.end:
      return {
        ...state,
        loading: false,
        state: action.payload.state,
        userId: action.payload.userId,
      };

    case auth.check.error:
      return {
        ...state,
        loading: false,
        state: action.payload.state,
        error: action.payload.error,
      };

    default:
      return state;
  }
}
