import { call, put, takeEvery } from 'redux-saga/effects';

import { filePsmAudit, notification } from '../../actionTypes';
import dataProvider from '../../store/dataProvider';

export function* get({ payload: { companyId } }) {
  try {
    const { data } = yield call(dataProvider.getList, `companies/${companyId}/psm-audit`, {});

    yield put({
      type: filePsmAudit.get.end,
      payload: data.data,
    });
  } catch (e) {
    yield put({
      type: filePsmAudit.get.error,
      payload: [],
    });
    yield put({ type: notification.add, payload: { error: e.message } });
  }
}

export default function* getFileSaga() {
  yield takeEvery(filePsmAudit.get.start, get);
}
