import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { auth } from '../../actionTypes';

export function useCheckAuth() {
  const authState = useSelector(state => state.auth.check.state);
  const {
    location: { pathname },
  } = useHistory();

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch({ type: auth.check.start });
  }, [pathname, dispatch]);

  return authState;
}
