import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  inputRow: {
    margin: theme.spacing(1, 0),
  },
  tableFormWrapper: {
    marginBottom: theme.spacing(2),
  },
  fieldItem: {
    paddingTop: theme.spacing(1),
  },
  buttonContainer: {
    marginTop: theme.spacing(1),
  },
}));

export default useStyles;
