import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Button, Grid, CircularProgress, AppBar } from '@material-ui/core';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import CloudDoneIcon from '@material-ui/icons/CloudDone';

import useStyles from './styles';
import WarningDocumentUpdate from '../../containers/WarningDocumentUpdate';
import WarningDocumentCreate from '../../containers/WarningDocumentCreate';
import { pqfStatusesEnum } from '../../../utils/constants';

const Wrapper = ({ fixed, className, children }) =>
  fixed ? (
    <AppBar position="fixed" className={className} component="div" color="transparent">
      {children}
    </AppBar>
  ) : (
    children
  );

const LoadingButton = ({
  fixed,
  skipCheckPQF,
  disabled,
  loading,
  text,
  error,
  success,
  onClick,
  position = 'flex-end',
}) => {
  const [openConfirmModal, setOpenConfirmModal] = useState(false);
  const [openCreateNewPQFModal, setOpenCreateNewPQFModal] = useState(false);
  const classes = useStyles({ loading, fixed });
  const currentPqfDocumentStatus = useSelector(
    store => store.currentCompany.companyInfo.pqfStatus.currentPqfDocumentStatus,
  );

  const handleConfirm = () => {
    setOpenConfirmModal(false);
    onClick();
  };

  const handleConfirmCreate = () => {
    setOpenCreateNewPQFModal(false);
    onClick();
  };

  const handleClose = () => setOpenConfirmModal(false);
  const handleCloseCreate = () => setOpenCreateNewPQFModal(false);

  const handleClick = () => {
    localStorage.setItem('skipResetValidationAfterSuccess', false);

    if (!skipCheckPQF && currentPqfDocumentStatus === pqfStatusesEnum.PendingReview) {
      setOpenConfirmModal(true);
    } else if (!skipCheckPQF && currentPqfDocumentStatus === pqfStatusesEnum.Completed) {
      setOpenCreateNewPQFModal(true);
    } else {
      onClick();
    }
  };

  return (
    <>
      <Grid item xs={12} className={classes.placeholder}>
        <Grid container>
          <Grid item xs={12}>
            <Wrapper fixed={fixed} className={classes.submitAppBar}>
              <Grid container justify={position} alignItems="center" className={classes.fixed}>
                {loading && <CircularProgress size={30} className={classes.loading} />}
                {!loading && error && <HighlightOffIcon className={classes.error} />}
                {!loading && success && !error && <CloudDoneIcon className={classes.success} />}
                <Button
                  disabled={disabled}
                  onClick={() => handleClick()}
                  size="small"
                  variant="contained"
                  color="primary"
                  className={classes.buttonStyles}
                >
                  {text}
                </Button>
              </Grid>
            </Wrapper>
          </Grid>
        </Grid>
      </Grid>
      <WarningDocumentUpdate open={openConfirmModal} handleClose={handleClose} handleConfirm={handleConfirm} />
      <WarningDocumentCreate
        open={openCreateNewPQFModal}
        handleClose={handleCloseCreate}
        handleConfirm={handleConfirmCreate}
      />
    </>
  );
};

export default LoadingButton;
