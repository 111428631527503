import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import MaterialLink from '@material-ui/core/Link';

import DocType from '../../images/icons/doc.png';
import DocxType from '../../images/icons/docx.png';
import JpgType from '../../images/icons/jpg.png';
import PdfType from '../../images/icons/pdf.png';
import PngType from '../../images/icons/png.png';
import TiffType from '../../images/icons/tiff.png';
import DefaultType from '../../images/icons/undefined.png';
import useStyles from './styles';

const types = {
  doc: DocType,
  docx: DocxType,
  jpg: JpgType,
  pdf: PdfType,
  png: PngType,
  tiff: TiffType,
  default: DefaultType,
};
const altTitle = 'file type';

const FileType = ({ type, name, loading, handleClick, disableIcon }) => {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      {!disableIcon && (
        <div className={classes.imageContainer}>
          <img src={types[type] || types.default} alt={altTitle} onClick={() => !loading && handleClick()} />
        </div>
      )}
      <div className={classes.textContainer}>
        {loading ? (
          <CircularProgress size={24} />
        ) : (
          <MaterialLink className={classes.text} onClick={handleClick}>
            {name}
          </MaterialLink>
        )}
      </div>
    </div>
  );
};

export default FileType;
