import React, { useState } from 'react';
import { Grid, IconButton } from '@material-ui/core';

import AdminDialog from '../AdminPopUpConfirmation';
import TooltipWrapper from '../TooltipWrapper';

const AdminPopUp = ({ handler, title, children, iconClassName, tooltipTitle, disabled, note }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleClick = () => {
    if (!disabled) {
      setIsModalOpen(true);
    }
  };
  return (
    <Grid item>
      <TooltipWrapper tooltip title={tooltipTitle}>
        <IconButton className={iconClassName} size="small" onClick={handleClick}>
          {children}
        </IconButton>
      </TooltipWrapper>
      <AdminDialog note={note} title={title} isModalOpen={isModalOpen} onCancel={() => setIsModalOpen(false)} />
    </Grid>
  );
};

export default AdminPopUp;
