import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  rate: {
    alignSelf: 'center',
  },
  typographyRate: {
    wordWrap: 'break-word',
  },
  wrapper: {
    height: '100%',
  },
}));

export default useStyles;
