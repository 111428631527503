import React, { useEffect } from 'react';
import { useFormikContext } from 'formik';

import TitleType from './TitleType';
import TextType from './TextType';
import YesNoType from './YesNoType';
import NumberType from './NumberType';
import ParagraphType from './ParagraphType';
import DropdownType from './DropdownType';
import DateType from './DateType';
import LinkType from './LinkType';
import FileType from './FileType';
import { enumTypes } from '../../../../utils/constants/formBuilder';

const SingleQuestion = ({ type, readOnly, preview, name, label, options, level, show, companyId, ownerCompanyId, formBuilderFieldId, formBuilderFieldAnswerId }) => {
  const { setFieldValue, setFieldTouched } = useFormikContext();

  useEffect(() => {
    if (!show) {
      setFieldValue(name, type === enumTypes.Date ? null : '', true);
      setFieldTouched(name, false, false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [show]);

  const types = {
    Title: <TitleType label={label} readOnly={readOnly} level={level} />,
    Paragraph: <ParagraphType name={name} readOnly={readOnly} label={label} level={level} />,
    Text: <TextType name={name} readOnly={readOnly} label={label} level={level} />,
    YesNo: <YesNoType name={name} readOnly={readOnly} label={label} level={level} />,
    Number: <NumberType name={name} readOnly={readOnly} label={label} level={level} />,
    Dropdown: <DropdownType options={options} readOnly={readOnly} name={name} label={label} level={level} />,
    Date: <DateType name={name} label={label} readOnly={readOnly} level={level} />,
    Link: <LinkType label={label} readOnly={readOnly || preview} level={level} ownerCompanyId={ownerCompanyId} formBuilderFieldId={formBuilderFieldId} />,
    File: <FileType name={name} label={label} readOnly={readOnly || preview} companyId={companyId} formBuilderFieldAnswerId={formBuilderFieldAnswerId} />,
  };
  return types[type] || null;
};

export default SingleQuestion;
