import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  tableContainer: {
    padding: theme.spacing(0, 1),
  },
  tableWrapper: {
    paddingLeft: theme.spacing(1),
    marginBottom: theme.spacing(2),
  },
  nameInput: {
    paddingTop: theme.spacing(1),
  },
  buttonSection: {
    padding: theme.spacing(1, 0, 0, 1),
  },
}));

export default useStyles;
