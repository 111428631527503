import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  dropBody: ({ dropped }) => ({
    backgroundColor: theme.palette.grey['200'],
    minHeight: dropped ? theme.spacing(10) : '40vh',
    marginBottom: dropped ? theme.spacing(2) : 0,
    border: `2px dashed ${theme.palette.grey['500']}`,
    outline: 'none !important',
    transition: 'all 0.3s',
    display: 'flex',
    alignItems: 'center',
    alignCenter: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: theme.palette.grey['300'],
      borderColor: theme.palette.grey['700'],
    },
  }),
}));

export default useStyles;
