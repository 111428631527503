import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  typography: {
    position: 'absolute',
    top: theme.spacing(3.7),
  },
}));

export default useStyles;
